
/*------------------------------------*\
    #DARK LAYOUT
\*------------------------------------*/

body,
.wrapper,
.header-fixed .affix,
.testimonial-boxed .center .testimonial-panel,
.skills,
.sidebar,
.entry-prev-next,
.entry-widget,
.alerts,
.pricing-separated .pricing-panel .pricing--heading h4,
.pricing-separated .pricing-panel .pricing--heading-bg,
.pricing-panel .pricing--heading,
table thead,
.table-striped>tbody>tr:nth-of-type(odd),
.tabs-2.tabs .nav-tabs > li.active >a,
.tabs-vertical.tabs .nav-tabs > li > a:focus,
.tabs-vertical.tabs .nav-tabs > li > a:hover,
.tabs-vertical.tabs .nav-tabs > li.active > a,
.tabs-vertical.tabs .nav-tabs > li.active > a:focus,
.tabs-vertical.tabs .nav-tabs > li.active > a:hover,
.portfolio-single .portfolio--list,
.portfolio-single .portfolio--share,
.portfolio--prev-next,
.cart-shiping,
.cart-total-amount,
.product-tabs,
.product-tabs .nav-tabs > li.active > a,
.product-tabs .nav-tabs > li.active > a:focus,
.product-tabs .nav-tabs > li.active > a:hover,
.product-review li,
.widget-search .form-search,
.pricing .panel-footer,
.testimonial-1 .testimonial-panel,
.testimonial-2 .testimonial-panel {
	background-color: #1d1d1d ! important
}

.widget-tags a,
.accordion,
.accordion .panel,
.blockquote-3,
.pricing-panel,
table,
.table-striped>tbody>tr:nth-of-type(even),
.team-boxed .member,
.timeline--item .timeline--item-content h3:before,
.header-fixed-side,
.contact-panel,
.bg-gray,
.product-tabs .nav-tabs,
.preloader,
.menu-grid .dish-panel,
.footer,
.bg-white .btn--secondary {
	background-color: #222 !important;
}

body,
.wrapper,
.widget-categories ul li a:after,
.blog-entry .entry--more a i,
.team-boxed .member .member-info i,
.header-fixed-side .header--panel .header--panel-social a,
.service-panel .link-more,
.banner-5 .banner--desc,
.banner-6 .panel--heading,
.team-4 .member .member-hover a,
.tabs .tab--heading,
.testimonial-panel .testimonial--meta h4 {
	color: #a5a5a5;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.heading .heading--title,
h1,
h2,
h3,
h4,
h5,
h6,
.menu-4 .dish-panel .dish--title,
.menu-5 .dish-panel .dish--title,
ul.comments-list .comment-body .comment h6,
.accordion .panel .panel--heading a,
.banner-6 .panel--details li::before,
.tabs .tab--details li::before,
.menu-1 .dish-panel .dish--title,
.menu-2 .dish-panel .dish--title,
.menu-3 .dish-panel .dish--title,
.menu-7 .dish-panel .dish--title,
.menu-8 .dish-panel .dish--title,
.cart-product-name h6,
.cart-shiping h6,
.cart-total-amount h6,
.shop-filter li a,
.menu-grid .dish-panel .dish--title,
.btn--link.btn--secondary,
.menu-board .dish-panel .dish--title,
.menu .dish-panel .dish--title,
.menu-simple .dish-panel .dish--title {
	color: #ffffff;
}

.header-fixed .navbar.affix .module .module-icon i,
.header-fixed .navbar.affix .navbar-nav > li > a,
.text-white,
.color-white,
.blog-entry .entry--title h4 a,
.feature-panel .feature--content h3,
.skills .progress-title .title,
.widget-recent-posts .entry .entry-title a,
.accordion .panel .panel--heading a.collapsed,
.count-box .counting,
ul.icon-list li i,
.portfolio-standard .portfolio-item .portfolio--title h4 a,
.table>thead:first-child>tr:first-child>th,
.timeline--item .timeline--item-content h3:before,
.widget-recent-products .product .product-title a,
.header-fixed-side .navbar-nav a,
.portfolio-parallax .portfolio-item .portfolio--title h4 a,
.countdown-dark .countdown-amount,
.portfolio--prev-next .portfolio--bio a,
.product-tabs .nav-tabs > li > a,
.widget .widget--title h5,
.shop-product .product-details h5,
.shop-product .product-share h5,
.shop-product .product-related-title h5,
.shop-product .product-title h3,
.member .member-info h5 {
	color: #ffffff !important;
}

.testimonial-panel .testimonial--body p {
	color: #f9f9f9
}

.text-black {
	color: #282828;
}

.feature-panel .feature--icon i {
	color: #c59d5f
}

.portfolio-filter li a,
.tabs .nav-tabs > li > a {
	color: #f9f9f9
}

.widget-categories ul li a,
.menu .dish-panel .dish--desc {
	color: #9b9b9b
}

.widget .widget--title {
	border-bottom-color: rgba(255,255,255,.3);
}

.accordion,
.widget-search .form-search {
	border: 1px solid rgba(49, 49, 49, 0.8);
}

.accordion .panel {
	border-bottom-color: rgba(49, 49, 49, 0.8);
}

.accordion .panel--heading .accordion-toggle.collapsed:after {
	color: #eee;
	border: 1px solid #eee;
}

#accordion3 .accordion .panel,
.blog-entry {
	background: transparent !important
}

.pagination > li > a,
.pagination > li > span {
	background-color: #000;
	border-color: #000;
}

.entry-widget .entry-widget-title,
ul.comments-list li,
ul.comments-list ul.comment-children,
.form-control,
.contact-form .form-control,
.table>thead>tr>th,
table,
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th,
.table-bordered,
.tabs-vertical.tabs .nav-tabs,
.widget-recent-products .product .product-desc,
.header-fixed-side .header--panel .navbar-nav > li,
.cart-table .table,
.cart-shiping,
.cart-total-amount,
.product-tabs,
.nav-tabs,
.product-tabs .nav-tabs > li > a,
.product-tabs .nav-tabs > li.active > a,
.product-tabs .nav-tabs > li.active > a:focus,
.product-tabs .nav-tabs > li.active > a:hover,
.cart-shiping .form-control,
.cart-table .cart-product-action .form-control,
.pricing-bordered .pricing-panel .pricing--body {
	border-color: rgba(255,255,255,.3);
}

.btn--link.btn-more:hover,
.quote-author,
.entry-prev-next a,
.entry-related .entry--title a,
.portfolio-single .portfolio--list ul li span,
.portfolio-single .portfolio--share span,
.shop-product .product-quantity .qua,
.shop-product .product-quantity input,
.entry--share .share--title {
	color: #ffffff !important;
}

.entry-prev-next a:hover,
.entry-related .entry--title a:hover,
.product--content .product--title a:hover {
	color: #c59d5f!important;
}

.btn--link.btn-more:hover span:after,
.btn--link.btn-more:hover span:before,
.widget .widget--title:after {
	background-color: #fff !important;
}

.btn--bordered.btn--secondary {
	color: #ffffff;
	border-color: #ffffff;
	background-color: transparent
}

.btn--bordered.btn--secondary:hover {
	color: #222222;
	border-color: #ffffff;
	background-color: #ffffff;
}

.widget-categories ul li {
	border-bottom-color: rgba(255,255,255,.2)
}

.widget-tags a {
	border-color: rgba(255,255,255,.2)
}

.widget-filter .ui-slider {
	background-color: rgba(255,255,255,.5)
}

.widget-filter .ui-slider-handle,
.entry-widget .entry-widget-title:after,
.entry-widget .entry-widget-title:after {
	background-color: #ffffff;
}

.testimonial-panel .testimonial--icon {
	background: url("/images/testimonial/icon5.png") no-repeat center;
}

.menu-5 .dish-panel {
	background-color: #222222;
}

.carousel-dots .owl-controls .owl-dots .owl-dot span {
	border: 3px solid #fff;
}

.carousel-dots .owl-controls .owl-dots .owl-dot.active span {
	background-color: #ffffff;
	border-color: #ffffff;
}

.divider--shape-14up,
.divider--shape-1down {
	background: url(/images/background/shapes/shape-10up.png) center no-repeat;
}

.divider--shape-14down,
.divider--shape-1up {
	background: url(/images/background/shapes/shape-10down.png) center no-repeat;
}

.blockquote-1 {
	border-left-color: #ffffff;
}

.btn--link.btn--secondary {
	background-color: transparent;
	border-color: transparent
}

.bg-white .heading .heading--title,
.specials .dishes-wrapper .heading .heading--title,
.specials .dishes-wrapper .dish-panel .dish--title {
	color: #222
}

.footer--copyright {
	background-color: #1b1b1b;
}

.slider .tparrows.tp-leftarrow:before {
	background-image: url(/images/sliders/arrows/right-arrow-bg-dark.png);
}

.slider .tparrows.tp-rightarrow:before {
	background-image: url(/images/sliders/arrows/left-arrow-bg-dark.png);
}

.divider--slider-right {
	background: url(/images/sliders/arrows/right-arrow-dark.png) center no-repeat;
}

.divider--slider-left {
	background: url(/images/sliders/arrows/left-arrow-dark.png) center no-repeat;
}

.entry-widget,
.entry--share,
.blog-single .blog-entry .entry--bio,
.widget-recent-posts .entry {
	border-bottom-color: rgba(255,255,255,.3)
}

.reservation-1 .form-select select{
	    border: 1px solid #eee;
}

.reservation-form .heading .heading--title{
	color: #222222;
}


/*-----------------------------------------------------------------------------------

	Theme Name: Steakin
	Theme URI: http://themeforest.net/user/7roof
	Description: Steakin is a restaurant Html5 Template suitable for any business operating in the Restaurant industry!! Restaurants, Cafes, Bistros, Bakery, Cafeteria, Coffee Shop, Pizzerias or any other food-related business. Based on Boostrap & designed with great attention to details, flexibility and performance. It is ultra professional, smooth and sleek, with a clean modern layout.
	Author: 7roof
	Author URI: http://themeforest.net/user/7roof
	Version: 1.0

-----------------------------------------------------------------------------------*/
/*!
// Contents
// ------------------------------------------------>

 1.  Global Styles
 2.  Typography
 3.	 Color
 4.	 Align
 5.	 Grid
 6.	 Blockquotes
 7.	 Background
 8.	 Breadcrumbs
 9.	 Buttons
 10. Forms
 11. Icons
 12. List
 13. Media
 14. Tabs
 15. Accordions
 16. Page Title
 17. Pagination
 18. Alerts
 19. Backtop
 20. Range
 21. Icon Box
 22. Misc
 23. Modals
 24. Animation
 25. Tables
 26. Heading
 27. Loading
 28. Header
 29. Menu
 30. Hero
 31. Team
 32. Testimonial
 33. Portfolio
 34. Feature
 35. Call To Action
 36. Counter
 37. Pricing Tables
 38. Blog
 39. Shop
 40. Contact
 41. Sidebar
 42. Footer
 43. 404
 44. Soon
 45. Maintenance
 46. Clients
 47. Process
 48. Skills
 49. Video
 50. Newsletter
 51. Timeline
 52. Carousel
 53. Divider
 54. Banner
 55. Number
 56. Pie Charts
 57. Side Nav
 58. Landing
 59. Layout Boxed
 60. Layout Bordered
 61. Layout Split

/*------------------------------------*\
    #GLOBAL STYLES
\*------------------------------------*/
body,
html {
	overflow-x: hidden;
}

html {
	font-size: 13px;
}

body {
	background-color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 400;
	color: #a5a5a5;
	line-height: 1.5;
	margin: 0;
}

.wrapper {
	background-color: #ffffff;
	padding: 20px;
}

* {
	outline: none;
}
::-moz-selection {
 text-shadow: none;
}

::selection {
	background-color: #e7b238;
	color: #ffffff;
	text-shadow: none;
}

a {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	color: #e7b238;
}

a:hover {
	color: #9e7940;
	text-decoration: none;
}

a:focus {
	color: #9e7940;
	outline: none;
	text-decoration: none;
}

a:active {
	color: #9e7940;
	outline: none;
	text-decoration: none;
}

textarea {
	resize: none;
}

button.btn {
	margin-bottom: 0;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
	outline: none;
}

.modal-backdrop {
	z-index: 1020;
	background-color: rgba(34, 34, 34, 0.95);
}

.fullscreen {
	height: 100vh !important;
}

/* Custom, iPhone Retina */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {

.wrapper {
	padding: 6px;
}

.fullscreen {
	height: auto !important;
}
}

/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #222222;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	margin: 0 0 28px;
	line-height: 1;
}

.h1,
h1 {
	font-size: 52px;
}

.h2,
h2 {
	font-size: 42px;
}

.h3,
h3 {
	font-size: 38px;
}

.h4,
h4 {
	font-size: 32px;
}

.h5,
h5 {
	font-size: 24px;
}

.h6,
h6 {
	font-size: 18px;
}

p {
	color: #a5a5a5;
	font-size: 13px;
	font-weight: 400;
	line-height: 22px;
}

.lead {
	font-size: 16px;
	line-height: 1.8;
}

/* Aligning Text */
.text--left {
	text-align: left !important;
}

.text--right {
	text-align: right !important;
}

.text--center {
	text-align: center !important;
}

.text--just {
	text-align: justify !important;
}

.align--top {
	vertical-align: top;
}

.align--bottom {
	vertical-align: bottom;
}

.align--middle {
	vertical-align: middle;
}

.align--baseline {
	vertical-align: baseline;
}

/* Weight Text */
.bold {
	font-weight: bold;
}

.regular {
	font-weight: normal;
}

.italic {
	font-style: italic;
}

.break-word {
	word-wrap: break-word;
}

.no-wrap {
	white-space: nowrap;
}

/* Text Color */
.text-white {
	color: #ffffff !important;
}

.text-gray {
	color: #f9f9f9 !important;
}

.text-black {
	color: #222222;
}

.text-theme {
	color: #e7b238 !important;
}

.text--capitalize {
	text-transform: capitalize !important;
}

.text--uppercase {
	text-transform: uppercase !important;
}

.font-heading {
	font-family: 'Montserrat', sans-serif !important;
}

.font-body {
	font-family: 'Montserrat', sans-serif;
}

.font-18 {
	font-size: 24px;
}

.font-16 {
	font-size: 16px;
}

.font-20 {
	font-size: 20px;
}

.font-40 {
	font-size: 40px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

text-center-xs {
	text-align: center !important;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

text-center-sm {
	text-align: center !important;
}
}

.higlighted-style1 {
	background-color: #e7b238;
	color: #fff;
	padding: 5px 0;
}

.higlighted-style2 {
	background-color: #222222;
	color: #fff;
	padding: 5px 0;
}

.higlighted-style3 {
	background-color: #f9f9f9;
	color: #e7b238;
	padding: 5px 0;
}

/*------------------------------------*\
    #COLORS
\*------------------------------------*/
.color-heading {
	color: #222222 !important;
}

.color-theme {
	color: #e7b238 !important;
}

.color-white {
	color: #ffffff !important;
}

.color-gray {
	color: #f9f9f9 !important;
}

/*------------------------------------*\
    #Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
	margin-bottom: 20px;
}

section {
	padding-top: 110px;
	padding-bottom: 110px;
	overflow: hidden;
}

/* All margin */
.m-0 {
	margin: 0 !important;
}

.m-xs {
	margin: 10px;
}

.m-sm {
	margin: 20px;
}

.m-md {
	margin: 40px;
}

.m-lg {
	margin: 80px;
}

/* top margin */
.mt-0 {
	margin-top: 0;
}

.mt-xs {
	margin-top: 10px;
}

.mt-10 {
	margin-top: 10px !important;
}

.mt-20 {
	margin-top: 20px !important;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-50 {
	margin-top: 50px !important;
}

.mt-60 {
	margin-top: 60px;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-80 {
	margin-top: 80px !important;
}

.mt-90 {
	margin-top: 90px !important;
}

.mt-100 {
	margin-top: 100px !important;
}

.mt-150 {
	margin-top: 150px;
}

/* bottom margin */
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-60 {
	margin-bottom: 60px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.mb-80 {
	margin-bottom: 80px !important;
}

.mb-90 {
	margin-bottom: 90px !important;
}

.mb-100 {
	margin-bottom: 100px !important;
}

.mb-150 {
	margin-bottom: 150px !important;
}

/* right margin */
.mr-0 {
	margin-right: 0;
}

.mr-30 {
	margin-right: 30px !important;
}

.mr-50 {
	margin-right: 50px;
}

.mr-60 {
	margin-right: 60px;
}

.mr-150 {
	margin-right: 150px;
}

/* left margin */
.ml-0 {
	margin-left: 0;
}

.ml-xs {
	margin-left: 10px;
}

.ml-sm {
	margin-left: 20px;
}

.ml-md {
	margin-left: 40px;
}

.ml-lg {
	margin-left: 80px;
}

.ml-30 {
	margin-left: 30px !important;
}

.ml-50 {
	margin-left: 50px;
}

.ml-60 {
	margin-left: 60px;
}

.ml-150 {
	margin-left: 150px;
}

/* All padding */
.p-0 {
	padding: 0 !important;
}

.p-xs {
	padding: 10px;
}

.p-sm {
	padding: 20px;
}

.p-md {
	padding: 40px;
}

.p-lg {
	padding: 80px;
}

/* top padding */
.pt-0 {
	padding-top: 0 !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pt-40 {
	padding-top: 40px !important;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

.pt-70 {
	padding-top: 70px !important;
}

.pt-80 {
	padding-top: 80px;
}

.pt-90 {
	padding-top: 90px;
}

.pt-100 {
	padding-top: 100px !important;
}

.pt-150 {
	padding-top: 150px !important;
}

/* bottom padding */
.pb-0 {
	padding-bottom: 0 !important;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

.pb-70 {
	padding-bottom: 70px !important;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-90 {
	padding-bottom: 90px;
}

.pb-100 {
	padding-bottom: 100px !important;
}

/* right padding */
.pr-0 {
	padding-right: 0;
}

.pr-xs {
	padding-right: 10px;
}

.pr-sm {
	padding-right: 20px;
}

.pr-md {
	padding-right: 40px;
}

.pr-lg {
	padding-right: 80px;
}

.pr-15 {
	padding-right: 15px !important;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-50 {
	padding-right: 50px;
}

.pr-60 {
	padding-right: 60px;
}

.pr-100 {
	padding-right: 100px !important;
}

.pr-150 {
	padding-right: 150px;
}

/* left padding */
.pl-0 {
	padding-left: 0;
}

.pl-30 {
	padding-left: 30px;
}

.pl-50 {
	padding-left: 50px;
}

.pl-60 {
	padding-left: 60px;
}

.pl-100 {
	padding-left: 100px !important;
}

.pl-150 {
	padding-left: 150px;
}

/* Postions */
.fixed {
	position: fixed;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.static {
	position: static;
}

/* Zindex*/
.zindex-1 {
	z-index: 1;
}

.zindex-2 {
	z-index: 2;
}

.zindex-3 {
	z-index: 3;
}

/* Borders */
.border-all {
	border: 1px solid #e7b238;
}

.border-top {
	border-top: 1px solid #e7b238;
}

.border-bottom {
	border-bottom: 1px solid #e7b238;
}

.border-right {
	border-right: 1px solid #e7b238;
}

.border-left {
	border-left: 1px solid #e7b238;
}

/* Display */
.inline {
	display: inline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.hide {
	display: none;
}

.flex {
	display: flex;
}

@media only screen and (max-width: 767px) {

section {
	padding-top: 60px;
	padding-bottom: 60px;
}

.text-center-xs {
	text-align: center !important;
}

.pull-none-xs {
	float: none !important;
	text-align: center !important;
}

.mb-15-xs {
	margin-bottom: 15px;
}

.mb-30-xs {
	margin-bottom: 30px !important;
}

.mb-50-xs {
	margin-bottom: 50px;
}

.mb-60-xs {
	margin-bottom: 60px !important;
}

.p-none-xs {
	padding-right: 0;
	padding-left: 0;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

.text-center-sm {
	text-align: center !important;
}

.mb-0-sm {
	margin-bottom: 0;
}

.mb-15-sm {
	margin-bottom: 15px;
}

.mb-30-sm {
	margin-bottom: 30px !important;
}

.mb-50-sm {
	margin-bottom: 50px;
}

.mb-60-sm {
	margin-bottom: 60px;
}

.pb-15-sm {
	padding-bottom: 15px;
}

.pb-30-sm {
	padding-bottom: 30px;
}

.pb-50-sm {
	padding-bottom: 50px;
}

.pb-60-sm {
	padding-bottom: 60px;
}

.p-none-sm {
	padding-right: 0;
	padding-left: 0;
}

.pull-none-sm {
	float: none !important;
	text-align: center !important;
}
}

.border-b {
	border-bottom: 1px solid #f9f9f9;
}

.mt--80 {
	margin-top: -80px;
}

/*------------------------------------*\
    #Grid
\*------------------------------------*/
.row-no-padding [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {

.col-sm-5ths {
	width: 20%;
	float: left;
}
}

@media (min-width: 992px) {

.col-md-5ths {
	width: 20%;
	float: left;
}
}

@media (min-width: 1200px) {

.col-lg-5ths {
	width: 20%;
	float: left;
}
}

.col-content {
	padding: 120px 100px 110px 120px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.col-content {
	padding: 40px !important;
}
}

/* Small Devices, Tablets */
/* Postion Helpers */
.pos-fixed {
	position: fixed;
}

.pos-relative {
	position: relative;
}

.pos-absolute {
	position: absolute;
}

.pos-static {
	position: static;
}

.pos-top {
	top: 0;
}

.pos-bottom {
	bottom: 0;
}

.pos-right {
	right: 0;
}

.pos-left {
	left: 0;
}

.pos-vertical-center {
	position: relative;
	top: 50%;
	-webkit-transform: perspective(1px) translateY(-50%);
	-moz-transform: perspective(1px) translateY(-50%);
	-o-transform: perspective(1px) translateY(-50%);
	transform: perspective(1px) translateY(-50%);
}

.height-700 {
	height: 700px !important;
}

.height-500 {
	height: 500px !important;
}

.height-800 {
	height: 800px !important;
}

@media only screen and (min-width: 992px) {

.hidden-lg,
 .hidden-md {
	display: none;
}
}

/*------------------------------------*\
    #Blockquotes
\*------------------------------------*/
.blockquote,
blockquot {
	font-size: 20px;
	font-family: 'Montserrat', sans-serif;
	color: #a5a5a5;
	font-style: italic;
	line-height: 28px;
	font-weight: 400;
	padding: 30px 0 30px 30px;
	position: relative;
}

.quote-author {
	font-family: 'Montserrat', sans-serif;
	display: block;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 1;
	color: #333333;
	margin-top: 18px;
}

.blockquote-1 {
	border-left: 6px solid #222222;
}

.blockquote-3,
.blockquote-4 {
	padding-right: 30px;
}

.blockquote-2,
.blockquote-3,
.blockquote-4 {
	border-left: none;
}

.blockquote-2:after,
.blockquote-2:before {
	font-family: fontawesome;
	font-size: 16px;
	position: absolute;
	color: #222222;
	line-height: 1.8;
}

.blockquote-2:before {
	content: "\f10d";
	top: 0;
	left: 0;
}

.blockquote-2:after {
	content: "\f10e";
	right: 0;
	bottom: 0;
}

.blockquote-3 {
	color: #e7b238;
	background-color: #f4f4f4;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.blockquote-4 {
	color: #ffffff;
	background-color: #222222;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

.blockquote-4 .quote-author {
	color: #ffffff;
}

/*------------------------------------*\
    #BACKGROUNDS
\*------------------------------------*/
.bg-theme {
	background-color: #e7b238 !important;
}

.bg-gray {
	background-color: #f9f9f9 !important;
}

.bg-white {
	background-color: #ffffff !important;
}

.bg-dark {
	background-color: #222222 !important;
}

.bg-dark2 {
	background-color: #222222 !important;
}

.bg-dark3 {
	background-color: #3d3c3c !important;
}

/* Background Image */
.bg-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	width: 100%;
	height: 100%;
}

.bg-overlay:before {
	content: "";
	display: inline-block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

.bg-overlay-light:before {
	background-color: #ffffff;
	opacity: 0.8;
}

.bg-overlay-dark:before {
	background-color: rgba(27, 26, 26, 0.75);
}

.bg-overlay-dark2:before {
	background-color: rgba(27, 26, 26, 0.95);
}

.bg-overlay-dark3:before {
	background-color: rgba(27, 26, 26, 0.85);
}

.bg-overlay-dark4:before {
	background-color: rgba(27, 26, 26, 0.65);
}

.bg-parallax {
	background-attachment: fixed;
}

.bg-overlay-gradient:before {
	background-color: rgba(27, 26, 26, 0.75);
	background-image: -webkit-linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
	background-image: -moz-linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
	background-image: -ms-linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
	background-image: -o-linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
	background-image: linear-gradient(180deg, rgba(27, 26, 26, 0.75) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%, rgba(27, 26, 26, 0) 100%);
}

/* Background Video */
.bg-video {
	position: relative;
}

.bg-video .bg-player {
	bottom: 0;
	left: 0;
	right: 0;
	position: absolute;
	top: 0;
	width: 100%;
	/*background: url(/images/polina.jpg) no-repeat;*/
	min-height: 500px;
}

.bg-video .vidbg {
	min-width: 100%;
	min-height: 100%;
}

.bg-vyoutube {
	z-index: 550;
	text-align: center;
	height: 100%;
	min-height: 100%;
	position: relative;
	overflow: hidden;
}

/*------------------------------------*\
    #BREADCRUMB
\*------------------------------------*/
/* Breadcrumb Style 1 */
.breadcrumb {
	background-color: transparent;
	margin-bottom: 0;
	padding: 0;
	border-radius: 0;
	color: #e5e4e4;
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	text-transform: capitalize;
}

.breadcrumb > li + li:before,
.breadcrumb > li a {
	color: #e5e4e4;
}

.breadcrumb > .active {
	color: #f9f9f9;
}

.breadcrumb-2 > li + li:before {
	content: "\f101";
	font-family: fontawesome;
}

.breadcrumb-3 > li + li:before {
	content: "\f105";
	font-family: fontawesome;
}

.breadcrumb > li a:hover {
	color: #e7b238;
}

/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/
.btn {
	font-family: 'Montserrat', sans-serif;
	position: relative;
	z-index: 2;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	border-radius: 0;
	padding: 0;
	line-height: 50px;
	/*width: 170px;
	height: 51px;*/
	letter-spacing: 0.28px;
}

.btn.active,
.btn:active {
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* Button Primary */
.btn--primary {
	background-color: #e7b238;
	color: #262a2d;
	border: 2px solid transparent;
	transition: all 0.2s ease-out;
}

.btn--primary:focus,
.btn--primary:hover {
	background-color: #b4832c;
	border: 2px solid #b4832c;
	color: #262a2d;
}

.btn--primary:active {
	background-color: #7d5f1b;
	border: 2px solid #7d5f1b;
	color: #262a2d;
}

/* Button Primary Hover */
.btn--primary.btn--inverse:active,
.btn--primary.btn--inverse:focus,
.btn--primary.btn--inverse:hover {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #e7b238;
}

/* Button Secondary*/
.btn--secondary {
	background-color: #222222;
	color: #ffffff;
	border: 2px solid #222222;
}

.btn--secondary:active,
.btn--secondary:focus,
.btn--secondary:hover {
	background-color: #e7b238;
	color: #ffffff;
	border-color: #e7b238;
}

/* Button White */
.btn--white {
	background-color: #ffffff;
	color: #222222;
	border: 2px solid #ffffff;
}

.btn--white:active,
.btn--white:focus,
.btn--white:hover {
	background-color: #e7b238;
	color: #ffffff;
	border-color: #e7b238;
}

.bg-overlay-theme2 .btn--white:active,
.bg-overlay-theme2 .btn--white:focus,
.bg-overlay-theme2 .btn--white:hover {
	background-color: #222222;
	color: #ffffff;
	border-color: #222222;
}

/* Button Block */
.btn--block {
	width: 100%;
}

.btn--auto {
	width: auto;
}

/* Button Rounded */
.btn--rounded {
	border-radius: 50px;
}

/* Button Link */
.btn--link {
	background-color: transparent;
	border-color: transparent;
	cursor: pointer;
}

.btn--link.btn--primary {
	color: #e7b238;
}

.btn--link.btn--primary:active,
.btn--link.btn--primary:focus,
.btn--link.btn--primary:hover {
	color: #9c7922;
	background-color: transparent;
	border-color: transparent;
}

.btn--link.btn--secondary {
	color: #222222;
}

.btn--link.btn--secondary:active,
.btn--link.btn--secondary:focus,
.btn--link.btn--secondary:hover {
	color: #e7b238;
	background-color: transparent;
	border-color: transparent;
}

/* Button Bordered */
.btn--bordered {
	background-color: transparent;
}

.btn--bordered.btn--primary {
	color: #e7b238;
	border-color: #e7b238;
}

.btn--bordered.btn--primary:active,
.btn--bordered.btn--primary:focus,
.btn--bordered.btn--primary:hover {
	color: #ffffff;
	background-color: #e7b238;
	border-color: #e7b238;
}

.btn--bordered.btn--secondary {
	color: #222222;
	border-color: #222222;
}

.btn--bordered.btn--secondary:active,
.btn--bordered.btn--secondary:focus,
.btn--bordered.btn--secondary:hover {
	color: #ffffff;
	background-color: #222222;
	border-color: #222222;
}

.btn--bordered.btn--white {
	color: #ffffff;
	border-color: #ffffff;
}

.btn--bordered.btn--white:active,
.btn--bordered.btn--white:focus,
.btn--bordered.btn--white:hover {
	color: #e7b238;
	background-color: #ffffff;
	border-color: #ffffff;
}

.btn--white.btn--secondary {
	color: #222222;
	border-color: #ffffff;
	background-color: #ffffff;
}

.btn--white.btn--secondary:active,
.btn--white.btn--secondary:focus,
.btn--white.btn--secondary:hover {
	color: #ffffff;
	background-color: #e7b238;
	border-color: #e7b238;
}

.btn--slide:last-of-type {
	margin-left: -6px;
}

.btn--video {
	color: #ffffff;
	font-size: 13px;
	font-weight: 600;
	line-height: 51px;
	text-transform: uppercase;
	padding-left: 93px;
}

.btn--video i {
	position: absolute;
	left: 30px;
	top: 0;
	color: #f9f9f9;
	font-size: 48px;
}

.btn--video:hover,
.btn--video:hover i {
	color: #e7b238;
}

/* Buttom Video With Bordered */
.btn--video-bordered {
	width: 68px;
	height: 68px;
	line-height: 65px;
	border-radius: 50%;
	border-radius: 33px;
	background-color: rgba(255, 255, 255, 0.2);
	-webkit-transition: all .4s;
	-moz-transition: all .4s;
	-ms-transition: all .4s;
	-o-transition: all .4s;
	transition: all .4s;
}

.btn--video-bordered .btn--video {
	padding-left: 0;
	width: 48px;
	height: 48px;
	color: #f9f9f9;
}

.btn--video-bordered .btn--video i {
	left: 9px;
}

.btn--video-bordered:hover {
	background-color: #ffffff;
}

.btn--video-bordered:hover .btn--video,
.btn--video-bordered:hover .btn--video i {
	color: #e7b238;
}

/* Buttom More */
.btn-more span {
	position: relative;
	padding-right: 7px;
}

.btn-more span:before {
	position: absolute;
	content: "";
	left: 0;
	top: 50%;
	width: 8px;
	margin-left: -7px;
	height: 2px;
	background-color: #222222;
}

.btn-more span:after {
	position: absolute;
	content: "";
	left: -4px;
	top: 50%;
	margin-top: -3px;
	width: 2px;
	height: 8px;
	background-color: #222222;
}

.btn-more:hover span:after,
.btn-more:hover span:before {
	background-color: #ffffff;
}

/* Buttom Link */
.btn--link.btn-more {
	color: #e7b238;
	height: 15px;
	line-height: 15px;
}

.btn--link.btn-more:hover {
	color: #222222;
}

.btn--link.btn-more span:after,
.btn--link.btn-more span:before {
	background-color: #e7b238;
}

.btn--link.btn-more:hover span:after,
.btn--link.btn-more:hover span:before {
	background-color: #222222;
}

/* Button Action */
.btn--action {
	line-height: 130px;
	height: 130px;
}

.btn--action span:after,
.btn--action span:before {
	background-color: #ffffff;
}

/* Button Icon */
.btn--icon {
	width: 70px;
	height: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 2px;
	background-color: rgba(27, 26, 26, 0.9);
	font-size: 40px;
	display: inline-block;
	-webkit-transition: all .4s linear;
	-moz-transition: all .4s linear;
	transition: all .4s linear;
}

.btn--icon-gradient i {
	color: #e7b238;
	background: -webkit-linear-gradient(150deg, #1da4cb 0%, #1da4cb 2%, #1dcb84 27%, #7bcb1d 58%, #efd631 100%);
	background: linear-gradient(150deg, #1da4cb 0%, #1da4cb 2%, #1dcb84 27%, #7bcb1d 58%, #efd631 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.btn--icon:hover {
	background-color: #ffffff;
	-webkit-transform: translateY(-4px);
	-moz-transform: translateY(-4px);
	transform: translateY(-4px);
}

.btn--bg-gradient {
	background-color: rgba(27, 26, 26, 0.9);
	background: -webkit-linear-gradient(150deg, #1da4cb 0%, #1da4cb 2%, #1dcb84 27%, #7bcb1d 58%, #efd631 100%);
	background: linear-gradient(150deg, #1da4cb 0%, #1da4cb 2%, #1dcb84 27%, #7bcb1d 58%, #efd631 100%);
}

.btn--bg-gradient i {
	color: #ffffff;
	background: none;
	-webkit-background-clip: inherit;
	-webkit-text-fill-color: inherit;
}

@media only screen and (min-width: 1200px) {

.btn--lg {
	width: 270px;
}
}

@media only screen and (max-width: 1200px) {

.btn--lg {
	width: 220px;
}
}

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
.form-control {
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 49px;
	border: 1px solid #eee;
	background: transparent;
	padding-left: 18px;
	margin-bottom: 30px;
	position: relative;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 49px;
	color: #fff;
}

.form-control:focus {
	border-color: #e7b238;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
/* Placeholder */
.form-control::-webkit-input-placeholder {
 color: #a5a5a5;
}
.form-control::-moz-placeholder {
 color: #a5a5a5;
}
.form-control::-ms-input-placeholder {
 color: #a5a5a5;
}

.reservation-form {
	background-color: #ffffff;
	padding: 10px;
}

.reservation-1 .reservation-form {
	padding: 10px;
}

.reservation-bordered {
	padding: 70px 90px;
	border: 1px dashed #e7b238;
}

.reservation--dec {
	font-size: 14px;
	font-weight: 400;
	padding: 0 55px;
	margin-bottom: 50px;
}

.form-select {
	position: relative;
}

.form-select i {
	position: absolute;
	font-size: 20px;
	font-weight: 400;
	line-height: 49px;
	right: 20px;
	top: 0;
	z-index: 1;
	pointer-events: none;
	cursor: pointer;
	color: #b8b8b8;
}

.form-select select {
	line-height: 1;
	color: #a5a5a5;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.reservation-form {
	padding: 60px 0;
}

.reservation-1 .reservation-form,
 .reservation-bordered,
 .reservation--dec {
	padding: 10px;
}

.reservation-1 .reservation-form {
	margin-bottom: 30px;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #ICONS
\*------------------------------------*/
#icons section {
	padding-top: 60px;
	padding-bottom: 60px;
}

#icons h3 {
	margin-bottom: 0;
}

#icons .fa-hover {
	margin-bottom: 15px;
	font-size: 15px;
}

#icons i {
	margin-right: 10px;
}

/*------------------------------------*\
    #Lists
\*------------------------------------*/
ul.list {
	padding: 0;
	font-size: 13px;
	list-style-type: none;
	color: #7f7f7f;
}

ul.list li {
	font-size: 13px;
	font-weight: 400;
	line-height: 30px;
	color: #7f7f7f;
}

ul.icon-list li i {
	font-size: 15px;
	font-weight: 400;
	line-height: 30px;
	margin-right: 10px;
	color: #222222;
}

ul.number-list li {
	line-height: 33px;
}

ul.number-list li span {
	font-size: 13px;
	font-weight: 700;
	line-height: 30px;
	margin-right: 15px;
	color: #222222;
}

ul.dot-list li i {
	font-size: 7px;
	font-weight: 400;
	line-height: 30px;
	margin-right: 19px;
	color: #222222;
}

/*------------------------------------*\
    #MEDIA
\*------------------------------------*/
.screen-img {
	position: relative;
	display: block;
	overflow: hidden;
}

.screen-img img {
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.screen-img .screen-hover {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	padding: 30px;
	opacity: 0;
}

.screen-img .screen-hover a {
	top: 50%;
	left: 50%;
	margin-top: -22px;
	margin-left: -90px;
	webkit-transition: all 0.4s 0.4s ease-in-out;
	-moz-transition: all 0.4s 0.4s ease-in-out;
	-o-transition: all 0.4s 0.4s ease-in-out;
	transition: all 0.4s 0.4s ease-in-out;
	opacity: 0;
}

/* screen Hover Effect */
.screen-img:hover .screen-hover {
	opacity: 1;
}

.screen-img:hover .screen-hover a {
	opacity: 1;
}

/*------------------------------------*\
    #Tabs
\*------------------------------------*/
.tabs .nav-tabs {
	border: none;
	text-align: center;
}

.tabs .nav-tabs > li {
	float: none;
	display: inline-block;
	margin: 0 11px;
}

.tabs .nav-tabs > li > a {
	font-family: 'Montserrat', sans-serif;
	color: #333333;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
	border-radius: 0;
	border: none;
}

.tabs .nav > li > a {
	padding: 0 0 7px 0;
}

.tabs .nav-tabs > li > a:focus,
.tabs .nav-tabs > li > a:hover,
.tabs .nav-tabs > li.active > a,
.tabs .nav-tabs > li.active > a:focus,
.tabs .nav-tabs > li.active > a:hover {
	color: #e7b238;
	background-color: transparent;
	border: none;
}

.tabs .nav-tabs > li > a:after {
	position: absolute;
	bottom: 0;
	left: 0;
	content: "";
	background-color: #e7b238;
	width: 0;
	height: 2px;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.tabs .nav-tabs > li > a:focus:after,
.tabs .nav-tabs > li > a:hover:after,
.tabs .nav-tabs > li.active > a:after,
.tabs .nav-tabs > li.active > a:focus:after,
.tabs .nav-tabs > li.active > a:hover:after {
	width: 100%;
}

.tabs .tab-content > .tab-pane {
	margin-top: 37px;
}

.tabs .tab--logos li {
	padding-right: 15px;
	padding-left: 15px;
}

.tabs .tab--heading {
	font-size: 40px;
	font-family: 'Great Vibes', cursive;
	color: #222222;
	font-weight: 400;
	line-height: 40px;
	margin-bottom: 39px;
}

.tabs ul.tab--details {
	padding: 0;
	margin-bottom: 0;
}

.tabs .tab--details li {
	font-size: 14px;
	line-height: 30px;
	list-style: none;
	position: relative;
	padding-left: 20px;
}

.tabs .tab--details li::before {
	content: "•";
	color: #222222;
	font-size: 30px;
	position: absolute;
	left: 0;
}

@media (max-width: 768px) {

.tabs .nav-tabs > li {
	margin: 0 8px;
}

.tabs .nav-tabs > li > a {
	font-size: 14px;
	margin-bottom: 15px;
}

.tabs .tab--logos li {
	margin-bottom: 10px;
}

.tabs .tab--heading {
	font-size: 25px;
	line-height: 40px;
	margin-bottom: 45px;
}

.tabs .tab--details li {
	font-size: 13px;
}

.tabs .tab--logos li {
	padding: 0;
}
}

/*------------------------------------*\
    #Accordion
\*------------------------------------*/
/* Accordion Base */
.accordion {
	border-radius: 2px;
	border: 1px solid #eeeeee;
	background-color: #ffffff;
}

.accordion .panel {
	border-bottom-color: rgba(49, 49, 49, 0.1);
	padding: 10px;
	margin-bottom: 0;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	-moz-border-radius: none;
	-webkit-border-radius: none;
	border-radius: none;
	background-color: transparent;
}

.accordion .panel:last-of-type {
	border-bottom: none;
}

.accordion .panel .panel--heading {
	padding: 19px;
	border: 1px dashed #e7b238;
}

.accordion .panel .panel--heading a {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	color: #282828;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	display: block;
	line-height: 27px;
}

.accordion .panel .panel--heading a.collapsed {
	color: #222222;
}

.accordion .panel .panel--body {
	padding: 20px;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	border: 1px dashed #e7b238;
	margin-top: 10px;
}

/* Accordion Status */
.accordion .panel--heading .accordion-toggle:after {
	position: absolute;
	right: 0;
	top: 0;
	content: "\f068";
	/* symbol for "opening" panels */
	font-family: fontawesome;
	font-size: 11px;
	color: #ffffff;
	background-color: #e7b238;
	border: 1px solid #e7b238;
	text-align: center;
	width: 20px;
	height: 20px;
	line-height: 20px;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.accordion .panel--heading .accordion-toggle.collapsed:after {
	content: "\f067";/* symbol for "collapsed" panels */
}

/* Accordion Style #1 */
.accordion.accordion-1 {
	background-color: transparent;
	border-radius: 0;
	border: none;
}

.accordion.accordion-1 .panel {
	box-shadow: 0 1px 5px rgba(40, 40, 40, 0.1);
	border-radius: 1px;
	background-color: #ffffff;
	margin-bottom: 30px;
}

/* Accordion Style #2 */
.accordion.accordion-2 .panel {
	border-bottom: none;
	padding-bottom: 0;
}

.accordion.accordion-2 .panel:last-of-type {
	padding-bottom: 10px;
}

.accordion.accordion-2 .panel .panel--body {
	margin-top: 0;
	border-top: none;
}

/* Accordion Style #3 */
.accordion.accordion-3 {
	background-color: transparent;
	border: none;
}

.accordion.accordion-3 .panel {
	box-shadow: 0 1px 5px rgba(40, 40, 40, 0.1);
	border-radius: 1px;
	background-color: #ffffff;
	margin-bottom: 30px;
	padding: 29px;
}

.accordion.accordion-3 .panel .panel--heading {
	padding: 0;
	border: none;
}

.accordion.accordion-3 .panel .panel--body {
	border: none;
	border-top: 1px dashed #e7b238;
	padding: 19px 0 0 0;
	margin-top: 22px;
}

/*------------------------------------*\
    #PAGINATION
\*------------------------------------*/
.pagination {
	margin: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.pagination > li {
	margin-right: 10px;
	display: inline-block;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
	border-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
	background-color: #333333;
	border-radius: 2px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	border-color: #333333;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	padding: 0;
	width: 51px;
	height: 51px;
	line-height: 51px;
	text-align: center;
}

.pagination > .active > a,
.pagination > li:hover > a {
	background-color: #e7b238 !important;
	border-radius: 1px;
	color: #ffffff;
	border-color: #e7b238;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 0 !important;
}

/*------------------------------------*\
    #ALERT MESSAGE
\*------------------------------------*/
.alerts {
	background-color: #f9f9f9;
	border-radius: 0;
	padding: 20px;
	margin-bottom: 40px;
}

.alerts .alert-icon {
	width: 46px;
	height: 46px;
	line-height: 46px;
	background-color: #e7b238;
	-moz-border-radius: 2px 1px 1px 2px;
	-webkit-border-radius: 2px 1px 1px 2px;
	border-radius: 2px 1px 1px 2px;
	text-align: center;
	position: absolute;
}

.alerts .alert-icon i {
	color: #ffffff;
	font-size: 15px;
	font-weight: 400;
	line-height: 28px;
}

.alerts .alert-content {
	padding-left: 68px;
}

.alerts .alert-content h4 {
	font-family: 'Montserrat', sans-serif;
	color: #e7b238;
	font-size: 15px;
	font-weight: 700;
	line-height: 32px;
	text-transform: capitalize;
	margin-bottom: 0;
	margin-top: -6px;
}

.alerts .alert-content p {
	color: #9b9b9b;
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 0;
}

.alerts.alert-warning h4 {
	color: #8a6d3b;
}

.alerts.alert-warning .alert-icon {
	background-color: #fcf8e3;
}

.alerts.alert-warning .alert-icon i {
	color: #8a6d3b;
}

.alerts.alert-danger h4 {
	color: #a94442;
}

.alerts.alert-danger .alert-icon {
	background-color: #f2dede;
}

.alerts.alert-danger .alert-icon i {
	color: #a94442;
}

/* Subscribe Alert */
.subscribe-alert .alert {
	margin: 10px auto;
	font-size: 12px;
}

/* Contact Alert */
.contact-result .alert,
.reservation-result .alert {
	margin: 10px auto;
	font-size: 12px;
}

label.error {
	color: #df2a10;
	position: absolute;
	top: -20px;
	left: 15px;
}

/*------------------------------------*\
    #BACK TO TOP
\*------------------------------------*/
#back-to-top {
	z-index: 9999;
	position: fixed;
	right: 30px;
	bottom: 30px;
	opacity: 0;
}

.backtop {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
	border-radius: 2px;
	transition: opacity 0.2s ease-out;
	background-color: rgba(0, 0, 0, 0.4);
	font-size: 20px;
}

#back-to-top.show {
	opacity: 1;
}

#back-to-top:hover {
	background-color: #e7b238;
}

/* Style 2 */
.backtop.style-2 {
	border-radius: 0;
}

/* Style 4*/
.backtop.style-3 {
	border-radius: 5px;
}

/* Style 4*/
.backtop.style-4 {
	border-radius: 50%;
}

.back-top-styles .backtop {
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}

@media only screen and (max-width: 992px) {

#back-to-top.show {
	opacity: 0;
}
}

/*------------------------------------*\
    #Range
\*------------------------------------*/
.range-slider .ui-slider {
	height: 8px;
	background-color: #d7d7d7;
	border-radius: 2px;
	margin-bottom: 20px;
	margin-top: 5px;
}

.range-slider .ui-slider-handle {
	/* Style for "Line" */
	width: 15px;
	height: 15px;
	margin-left: 0;
	background-color: #272930;
	border-radius: 1px;
}

.range-slider .ui-slider-range {
	height: 8px;
	background-color: #e7b238;
	margin-bottom: 20px;
}

.range-slider p {
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
}

.range-slider label {
	color: #f9f9f9;
}

.range-slider input {
	color: #e7b238;
	border: none;
	background-color: transparent;
}

/*------------------------------------*\
    #FEATRUE BOX
\*------------------------------------*/
/* Feature #8 */
.feature-box {
	text-align: center;
	margin-bottom: 0;
}

.feature-box .feature-heading h3 {
	color: #f9f9f9;
	font-size: 17px;
	font-weight: 500;
	line-height: 1;
}

.feature-box .feature-icon {
	position: relative;
	width: 60px;
	height: 60px;
	line-height: 60px;
	background-color: #f9f9f9;
	border-radius: 1px;
	color: #ffffff;
	font-size: 28px;
	font-weight: 400;
	text-align: center;
	margin: 0 auto 20px;
	overflow: hidden;
}

.feature-box .feature-icon i {
	position: relative;
	display: block;
	line-height: 60px;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.feature-box:hover .feature-icon i:first-child {
	margin-top: -60px;
}

.feature-box .feature-body p {
	margin-bottom: 0;
}

/* Feature Box #2 */
.fbox-2 .feature-icon {
	float: left;
}

.fbox-2 .feature-body,
.fbox-2 .feature-heading {
	padding-left: 80px;
	text-align: left;
}

/* Feature Box #3 */
.fbox-3 .feature-icon {
	float: right;
}

.fbox-3 .feature-body,
.fbox-3 .feature-heading {
	padding-right: 80px;
	text-align: right;
}

/* Feature Box borderd */
.fbox-bordered .feature-icon {
	background-color: transparent;
	border: 1px solid #f9f9f9;
	color: #f9f9f9;
}

/* Feature Box Circle */
.fbox-circle .feature-icon {
	border-radius: 50%;
}

/* Feature Box Circle  bordered */
.fbox-circle.fb-bordered .feature-icon {
	border-radius: 50%;
	background-color: transparent !important;
	border: 2px solid #f9f9f9;
	color: #f9f9f9;
}

/*------------------------------------*\
    #MISC
\*------------------------------------*/
/* Utility classes table  */
.bs-table th small,
.responsive-utilities th small {
	display: block;
	font-weight: normal;
	color: #999;
}

.responsive-utilities tbody th {
	font-weight: normal;
}

.responsive-utilities td {
	text-align: center;
}

.responsive-utilities td.is-visible {
	color: #468847;
	background-color: #dff0d8 !important;
}

.responsive-utilities td.is-hidden {
	color: #ccc;
	background-color: #f9f9f9 !important;
}

.pallete-item {
	border: 1px solid #eee;
	margin-bottom: 24px;
}

.pallete-color {
	padding: 45px 15px;
	text-align: center;
	text-transform: uppercase;
}

.pallete-color p {
	margin-bottom: 0;
	font-weight: bold;
	color: #ffffff;
}

/*------------------------------------*\
    #MODALS
\*------------------------------------*/
body.modal-open {
	padding-right: 0 !important;
}

body.modal-open .affix {
	/*display: none;*/
}

.modal {
	z-index: 1080;
	background: rgba(34, 34, 34, 0.95);
}
.modal::-webkit-scrollbar {
 display: none;
 width: 0px;
  /* remove scrollbar space */
  background: transparent;
/* optional: just make scrollbar invisible */
}

.modal-backdrop {
	z-index: 1020;
	background-color: #222222;
	display: none;
}

.modal-content {
	border: none;
	border-radius: 0;
}

.modal-dialog {
	margin-top: 80px;
}

.modal button.close {
	position: absolute;
	top: 30px;
	right: 30px;
	color: #ffffff;
	z-index: 1040;
	font-size: 14px;
	font-weight: 400;
	opacity: 1;
}

.modal.reservation-popup button.close {
	color: #222222;
}

.modal button.close:focus,
.modal button.close:hover {
	color: #e7b238;
}

.reservation-popup .modal-content {
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.reservation-popup .modal-body {
	padding: 0;
}

.reservation-popup .reservation-form {
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.26);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.26);
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.26);
}
/*------------------------------------*\
    #Animation
\*------------------------------------*/
@keyframes pulse {
 0% {
 transform: scale(0.95);
 opacity: 0.7;
}
 50% {
 transform: scale(1);
 opacity: 1;
}
 100% {
 transform: scale(0.95);
 opacity: 0.7;
}
}
@-webkit-keyframes pulse {
 0% {
 -webkit-transform: scale(0.95);
 transform: scale(0.95);
 opacity: 0.7;
}
 50% {
 -webkit-transform: scale(1);
 transform: scale(1);
 opacity: 1;
}
 100% {
 -webkit-transform: scale(0.95);
 transform: scale(0.95);
 opacity: 0.7;
}
}

/*------------------------------------*\
    #Tables
\*------------------------------------*/
table {
	background-color: #ffffff;
	border: 1px solid #eee;
	border-radius: 0;
	margin-bottom: 0;
}

table thead {
	background-color: #f9f9f9;
}

table thead tr th {
	font-size: 14px;
	font-weight: 600;
	text-transform: capitalize;
	color: #282828;
	line-height: 30px !important;
	border-bottom-width: 0 !important;
	border-color: #eee;
	padding: 0;
}

table tbody tr td {
	padding: 20px;
	border-color: #eee;
}

table tbody tr td,
table tbody tr td {
	font-size: 13px;
	font-weight: 600;
	line-height: 26px;
	color: #9b9b9b;
}

/*------------------------------------*\
    #PAGE-TTILE
\*------------------------------------*/
.page-title {
	padding: 0;
}

.page-title .title {
	padding: 162px 0 50px;
	position: relative;
}

.page-title .title-sm {
	padding: 80px 0 50px;
	position: relative;
}

.title .title--heading h1 {
	color: #ffffff;
	font-size: 45px;
	font-weight: 700;
	line-height: 50px;
	margin-bottom: 0;
	font-family: 'Montserrat', sans-serif;
}

.page-title .title--subtitle {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 45px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 0;
}

.page-title .title--desc {
	color: #f9f9f9;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0;
}

.page-title .breadcrumb {
	position: absolute;
	bottom: 47px;
	margin: 0 auto;
	text-align: center;
	display: block;
	width: 100%;
}

.page-title .breadcrumb,
.page-title .breadcrumb a {
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	background: transparent;
	color: #f9f9f9;
}

.page-title .breadcrumb .active {
	color: #e7b238;
}

.page-title .breadcrumb a:hover {
	color: #e7b238;
}

.page-title .breadcrumb > li + li:before {
	color: #f9f9f9;
}

/* Title #1 */
.title-1 .title--content {
	margin-bottom: 95px;
}

/* Title #2 */
.title-2 .title--content {
	margin-bottom: 17px;
}

.title-2 .breadcrumb {
	position: relative;
	bottom: auto;
	margin-bottom: 100px;
}

/* Title #3 */
.title-3 .title--content {
	margin-bottom: 125px;
}

/* Title #5 */
.title-5 .breadcrumb {
	position: relative;
	bottom: auto;
	margin: 0 auto;
}

.title-5 .title--content {
	margin-bottom: 80px;
}

.title-5 .title--content {
	margin-bottom: 78px;
}

/* Title #6 */
.title-6 .title--heading h1 {
	line-height: 1;
	padding-top: 15px;
}

/* Title #7 */
.title-7 .title--content {
	margin-bottom: 108px;
}

/*------------------------------------*\
    #Heading
\*------------------------------------*/
.heading .heading--subtitle {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 45px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 5px;
}

.heading .heading--title {
	font-family: 'Montserrat', sans-serif;
	color: #222222;
	font-size: 50px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 25px;
}

.heading .heading--desc {
	font-family: 'Montserrat', sans-serif;
	color: #a5a5a5;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0;
	padding: 0 40px;
}

.heading-3 .heading--img {
	margin-bottom: 0;
}

.heading-4 .heading--title {
	margin-bottom: 20px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.heading .heading--subtitle {
	font-size: 35px;
}

.heading .heading--title {
	font-size: 39px;
}

.heading .heading--desc {
	padding: 20px;
}

.heading .heading--desc + .btn {
	margin-top: 30px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.heading .heading--title {
	font-size: 40px;
}
}

/*------------------------------------*\
    #LOADING-SECTION
\*------------------------------------*/
.preloader {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 99999;
	background-color: #ffffff;
	overflow: hidden;
	transition: opacity 200ms ease-in-out !important;
}

.sk-cube-grid {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -40px 0 0 -40px;
}

.spinner {
	margin: -40px 0 0 -40px;
	width: 70px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #e7b238;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
 0%,  80%,  100% {
 -webkit-transform: scale(0);
 transform: scale(0);
}
 40% {
 -webkit-transform: scale(1);
 transform: scale(0);
}
}
@keyframes sk-bouncedelay {
 0%,  80%,  100% {
 -webkit-transform: scale(0);
 transform: scale(0);
}
 40% {
 -webkit-transform: scale(1);
 transform: scale(1);
}
}

/*------------------------------------*\
    #HEADER
\*------------------------------------*/
.header {
	height: 100px;
}

.header-transparent {
	background-color: transparent;
}

.header-transparent + .mtop-100,
.header-transparent + .page-title,
.header-transparent + .slider {
	position: relative;
	margin-top: -100px;
}

.header-transparent.header-transparent-topbar + .mtop-100,
.header-transparent.header-transparent-topbar + .page-title,
.header-transparent.header-transparent-topbar + .slider {
	position: relative;
	margin-top: -155px;
}

.header-dark {
	background-color: #212121;
}

.header-dark + .mtop-100,
.header-dark + .page-title,
.header-dark + .slider {
	position: relative;
	margin-top: -100px;
}

.header-dark.header-dark-topbar + .mtop-100,
.header-dark.header-dark-topbar + .page-title,
.header-dark.header-dark-topbar + .slider {
	position: relative;
	margin-top: -155px;
}

/* Top Bar */
.top-bar {
	height: 50px;
	background-color: #222222;
	overflow: hidden;
}

.top-bar .top--contact li {
	margin-right: 21px;
}

.top-bar .top--contact li:last-child {
	margin-right: 0;
}

.top-bar .top--contact {
	line-height: 50px;
	color: #cecece;
	font-size: 12px;
	font-weight: 400;
}

.top-bar .top--contact i {
	margin-right: 6px;
	color: #e7b238;
}

.top-bar .top--social {
	color: #cecece;
	font-size: 12px;
	font-weight: 400;
	line-height: 50px;
}

.top-bar .top--social a {
	color: #cecece;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	margin-left: 19px;
}

.top-bar.topbar-light {
	background-color: #ffffff;
}

.top-bar.topbar-light .top--contact,
.top-bar.topbar-light .top--social {
	color: #a5a5a5;
}

.top-bar.topbar-light .top--social a {
	color: #333;
}

.top-bar .top--social a:hover,
.top-bar.topbar-light .top--social a:hover {
	color: #e7b238;
}

.top-bar.topbar-transparent {
	background-color: transparent;
}

.header-transparent-topbar .top-bar {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
	top: 20px;
}

.top-bar .container-bordered {
	height: 50px;
}

/* Transparent Header */
.static-affix {
	top: 0;
	width: 100%;
	z-index: 10;
}

/* Custom, iPhone Retina */
/* Small Devices, Tablets */
/* Large Devices, Wide Screens */
/*------------------------------------*\
    #MODULE
\*------------------------------------*/
.module {
	display: inline-block;
	position: relative;
}

.module span.title {
	display: none;
}

.module .module-icon {
	margin-right: 25px;
	cursor: pointer;
	line-height: 100px;
}

.module:last-of-type .module-icon {
	margin-right: 0;
}

.module .module-icon i {
	font-size: 14px;
	line-height: 24px;
	color: #ffffff;
	font-weight: 400;
}

.module .menu-icon-double i {
	margin-left: 30px;
}

.menu-icon-button {
	width: 58px;
	height: 29px;
	background-color: #333333;
	color: #ffffff;
	font-size: 13px;
	font-weight: 700;
	line-height: 29px;
	text-transform: uppercase;
	/* Text style for "Menu" */
	letter-spacing: 0.26px;
	text-align: center;
	margin-top: 25px;
	border-radius: 4px;
}

.menu-icon-button:hover {
	background-color: #e7b238;
}

.module .module-active .module-icon i {
	color: #e7b238;
}

.module-content {
	width: 0;
	height: 0;
	opacity: 0;
	display: none;
	transition: all 0.35s;
}

.module-fullscreen {
	left: 0;
	top: 0;
	z-index: 99;
	display: block;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	transition: all 0.2s linear;
	background-color: rgba(34, 34, 34, 0.9);
	position: fixed;
}

.module-fullscreen .container {
	display: none;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
}

.module-active .module-fullscreen {
	opacity: 1;
	visibility: visible;
	height: 100%;
	width: 100%;
}

.module-active .module-fullscreen .container {
	display: block;
}

.module-cancel {
	top: 50px;
	position: absolute;
	right: 50px;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	color: #ffffff;
	line-height: 28px;
	text-align: center;
}

.module-cancel:hover {
	opacity: 1;
	color: #e7b238;
}

.module .module-box {
	position: absolute;
	display: block;
	background-color: #1b1a1a;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
	right: -50px;
	width: 370px;
	border-radius: 2px;
	padding: 32px;
	z-index: 99;
	-webkit-transform: translateY(10%);
	-moz-transform: translateY(10%);
	transform: translateY(10%);
	height: 0;
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
}

.module-active .module-box {
	opacity: 1;
	visibility: visible;
	display: block;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	transform: translateY(0px);
	height: inherit;
}

.module .module-hamburger {
	height: 100%;
	width: 370px;
	position: fixed;
	z-index: 1;
	top: 0;
	right: 0;
	opacity: 1;
	visibility: visible;
	display: block;
	overflow-x: hidden;
	-webkit-transition: all 0.35s linear;
	-moz-transition: all 0.35s linear;
	-o-transition: all 0.35s linear;
	transition: all 0.35s linear;
	-webkit-transform: translateX(370px);
	-moz-transform: translateX(370px);
	transform: translateX(370px);
}

.module-active .module-hamburger {
	-webkit-transform: translateX(0);
	-moz-transform: translateX(0);
	transform: translateX(0);
}
.module-active .module-hamburger::-webkit-scrollbar {
 display: none;
}

.module-active .module-hamburger .module-cancel {
	top: 30px;
	position: absolute;
	right: 30px;
	left: auto;
	font-size: 12px;
	color: #ffffff;
	opacity: 1;
	z-index: 3;
}

/* Module Search */
.module--search-box .form-search {
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
}

.module--search-box .form-control {
	color: #f9f9f9;
	font-family: 'Montserrat', sans-serif;
	font-size: 25px;
	font-weight: 600;
	line-height: 70px;
	height: 70px;
	border: none;
	background-color: transparent;
	width: 95%;
	float: left;
	padding: 0;
	margin-bottom: 0;
	text-transform: capitalize;
}

.module--search-box .btn {
	background-color: transparent;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	width: 35px;
	line-height: 57px;
	color: #e7b238;
	font-size: 28px;
	font-weight: 400;
	text-align: right;
	margin-left: 0;
	padding: 0;
}
.module--search-box .form-control::-webkit-input-placeholder {
 color: #f9f9f9;
}
.module--search-box .form-control:-moz-placeholder {
 color: #f9f9f9;
}
.module--search-box .form-control::-moz-placeholder {
 color: #9e9e9e;
}
.module--search-box .form-control:-ms-input-placeholder {
 color: #f9f9f9;
}

.module .module-label {
	width: 16px;
	height: 16px;
	line-height: 16px;
	border-radius: 50%;
	background-color: #e7b238;
	color: #ffffff;
	font-size: 10px;
	font-weight: 400;
	text-align: center;
	position: absolute;
	top: 34px;
	left: 8px;
}

/* Module Cart */
.cart-box .cart-overview li {
	border-bottom: 1px solid rgba(127, 127, 127, 0.15);
	margin-bottom: 20px;
	position: relative;
}

.cart-box .cart-overview li:last-child {
	margin-bottom: 0;
}

.cart-box .cart-overview li .cart-cancel {
	font-size: 0;
}

.cart-box .cart-overview li .cart-cancel:after {
	font-family: fontawesome;
	content: "\f00d";
	font-size: 12px;
	position: absolute;
	text-align: center;
	background-color: #e7b238;
	right: 0;
	top: 0;
	width: 19px;
	height: 19px;
	line-height: 19px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	color: #222222;
}

.cart-box .cart-overview li .cart-cancel:hover:after {
	background-color: #ffffff;
	color: #e7b238;
}

.cart-box .cart-overview img {
	width: 49px;
	height: 49px;
	margin-right: 15px;
	position: absolute;
}

.cart-box .cart-overview .product-meta {
	padding-left: 60px;
}

.cart-box .cart-overview h5 {
	color: #ffffff;
	font-size: 19px;
	text-transform: capitalize;
	margin-bottom: 0;
	line-height: 28px;
	font-weight: 400;
}

.cart-box .cart-overview p {
	font-family: 'Montserrat', sans-serif;
	color: #e7b238;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	margin-bottom: 0;
}

.cart-total {
	border-bottom: 2px solid rgba(127, 127, 127, 0.15);
	margin-bottom: 20px;
	min-height: 40px;
}

.cart-total .total-desc {
	float: left;
	text-align: left;
}

.cart-total .total-des,
.cart-total .total-price {
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 20px;
}

.cart-total .total-price {
	float: right;
	text-align: right;
	color: #e7b238;
}

.cart-box .cart--control .btn {
	height: 42px;
	line-height: 40px;
}

.cart-box .cart--control .btn--primary:hover {
	background-color: #ffffff;
	color: #e7b238;
	border-color: #ffffff;
}

/* Module Menu */
.module-menu .logo-container {
	text-align: left;
	margin-top: 30px;
	margin-left: 50px;
	margin-bottom: 180px;
}

.module-menu {
	text-align: center;
}

.module-menu .menu-list li {
	margin-bottom: 30px;
}

.module-menu .menu-list li:last-child {
	margin-bottom: 0;
}

.module-menu .menu-list a {
	color: #ffffff;
	font-size: 30px;
	font-weight: 700;
	line-height: 1;
	margin-right: 50px;
}

.module-menu .menu-list li:last-child a {
	margin-right: 0;
}

.module-menu .menu-list a:hover {
	color: #e7b238;
}

.module-menu .menu-copyrights {
	position: absolute;
	bottom: 80px;
	left: 0;
	width: 100%;
	text-align: center;
}

.module-menu .menu-copyrights {
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	margin-right: 23px;
	bottom: 30px;
}

/* Module Widgets */
.module-widgets {
	text-align: left;
	padding: 80px 50px;
}

.module-active .module-hamburger .module-widgets .module-cancel {
	line-height: 29px;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	border-radius: 4px;
}

.module-active .module-hamburger .module-widgets .module-cancel:hover {
	color: #e7b238;
}

.module-widgets .widget .widget--title h5 {
	color: #ffffff;
}

.module-widgets .widget .widget--title:after {
	background-color: #ffffff;
}

.module-widgets .widget-recent-posts .entry {
	border-bottom-color: #535353;
}

.module-widgets .widget-recent-posts .entry .entry-title a {
	color: #fefefe;
}

.module-widgets .widget-tags a {
	border-color: rgba(236, 236, 236, 0.1);
	color: #9b9b9b;
}

/* Module Reservation */
.module-reservation {
	position: relative;
	margin-right: 20px;
}


.module-reservation .btn-popup {
	height: 25px;
	border: 2px solid #ffffff;
	background-color: transparent;
	margin-top: 37px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 700;
	line-height: 22px;
	text-transform: uppercase;
	letter-spacing: 0.26px;
	padding: 0 8px;
	color: #ffffff;
	display: block;
	margin-right: 25px;
	cursor: pointer;
	position: relative;
}

.module-reservation .btn-popup:hover,
.module-reservation .btn-popup-theme,
.affix .module-reservation .btn-popup {
	background-color: #e7b238;
	color: #262a2d;
	border-color: #e7b238;
}

.module-reservation .btn-popup-theme:hover {
	background-color: #ffffff;
	border-color: #ffffff;
}

.header-transparent .navbar:not(.affix) .module-reservation .btn-popup-theme:hover {
	background-color: transparent;
	color: #e7b238;
	border-color: #e7b238;
}

/* Module Social */
.module-social a {
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
	line-height: 100px;
	margin-left: 18px;
}

.module-social a:hover {
	color: #e7b238;
}

/* Transparent Header */
.transparent-header .module .module-icon > i {
	color: #ffffff;
}

.transparent-header .search-box {
	top: 100px !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {

.module .module-box {
	position: relative;
	right: 0;
	width: 100%;
	border: none;
}

.module .module-box .btn--bordered.btn--secondary {
	background-color: #ffffff;
	color: #222222;
}

.module .module-label {
	top: -3px;
}

.module {
	display: block;
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.module .module-icon {
	padding-top: 8px;
	padding-left: 10px;
}

.module.active i {
	color: #ffffff;
}

.module span.title {
	display: inline-block;
	color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
}

.module .cart-box,
 .search-box {
	display: none;
	padding-right: 10px;
	padding-left: 10px;
}

.module .module-content {
	margin-bottom: 10px;
}

.cart-box .cart-overview h5 {
	color: #ffffff;
}

.module--search-box .form-search {
	margin: 25px;
}

.module--search-box .form-control {
	font-size: 16px;
	width: 90%;
	line-height: 30px;
}

.module--search-box .btn {
	font-size: 14px;
	line-height: 30px;
}

.module-cart.active .cart-box {
	opacity: 1;
	visibility: visible;
	display: block;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
	background-color: #272930;
}

.module-cancel {
	top: 30px;
	right: 30px;
	text-align: right;
}

.module .module-icon {
	line-height: 36px !important;
}

.module-search .search-icon i {
	border-left: none;
	padding-left: 0;
	padding-right: 5px;
}

.module.module-active .cart-box,
 .module.module-active .search-box {
	display: block !important;
}

.module-search.module-active .search-box .search-form {
	margin-bottom: 10px;
}

.module-search.module-active .search-box .search-form input {
	background-color: #ffffff;
	margin-bottom: 0;
	height: 35px;
	line-height: 35px;
	padding-left: 10px;
}

.module-cart.module-active .cart-box {
	background-color: #222222;
	padding: 30px 20px;
	margin-bottom: 10px;
}

.cart-box .cart--control .btn {
	margin-top: 10px;
}

.module-cart.module-active .cart-box .cart-control {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.module-cart.module-active .cart-box .cart-control .pull-right {
	float: none !important;
}

.module .module-label {
	position: relative;
}

.module-menu.pull-left {
	float: none !important;
}

.module-menu .logo-container {
	margin-top: 40px;
	margin-bottom: 50px;
}

.module-menu .menu-social {
	bottom: 20px;
}

.module-menu .menu-list li {
	display: block;
	margin-bottom: 20px;
}

.module-menu .menu-list a {
	margin-right: 0;
}

.navbar.navbar--dark .module .module-icon {
	color: #ffffff !important;
}

.module-reservation .btn-popup {
	height: 40px;
	line-height: 40px;
	margin: 15px auto;
	width: 80%;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.module {
	width: 100%;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	padding: 5px 15px;
}

.module .module-icon {
	padding-top: 0;
}

.module .module-box {
	position: relative;
	right: 0;
	width: 100%;
	border: none;
}

.module .module-box .btn--bordered.btn--secondary {
	background-color: #ffffff;
	color: #222222;
}

.module .module-label {
	top: -3px;
	position: relative;
}

.module span.title {
	display: inline-block;
	color: #ffffff;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
}

.module .cart-box,
 .search-box {
	display: none;
	padding-right: 10px;
	padding-left: 10px;
}

.module .module-content {
	margin-bottom: 10px;
}

.cart-box .cart-overview h5 {
	color: #ffffff;
}

.module-cart.active .cart-box {
	opacity: 1;
	visibility: visible;
	display: block;
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

.module .module-icon {
	line-height: 36px !important;
}

.module-search .search-icon i {
	border-left: none;
	padding-left: 0;
	padding-right: 5px;
}

.module.module-active .cart-box,
 .module.module-active .search-box {
	display: block !important;
}

.module-search.module-active .search-box .search-form {
	margin-bottom: 10px;
}

.module-search.module-active .search-box .search-form input {
	background-color: #ffffff;
	margin-bottom: 0;
	height: 35px;
	line-height: 35px;
	padding-left: 10px;
}

.module-cart.module-active .cart-box {
	background-color: #222222;
	border: none;
	padding: 30px 20px;
	margin-bottom: 10px;
}

.module-cart.module-active .cart-box .cart-control {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.module-cart.module-active .cart-box .cart-control .pull-right {
	float: none !important;
}

.header-fixed .navbar.affix .module .module-icon i,
 .header-fixed .navbar.affix .navbar-nav > li > a,
 .module .module-icon i {
	color: #ffffff !important;
}

.menu-popup .logo {
	float: none !important;
}

.module-menu .logo-container {
	margin-top: 100px;
	margin-bottom: 130px;
}

.navbar.navbar--dark .navbar-nav > li > .menu-item:hover::before,
 .navbar.navbar--dark .navbar-nav > li.active > .menu-item:before {
	background-color: #ffffff !important;
}

.module-reservation .btn-popup {
	height: 40px;
	line-height: 40px;
	margin: 15px 0;
	width: 170px;
}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.module-search .search-icon i {
	padding-left: 0;
	padding-right: 0;
}
}

/*------------------------------------*\
    #Menu
\*------------------------------------*/
.header .navbar-collapse {
	padding-right: 0;
	padding-left: 0;
}

.header .navbar {
	border-radius: 0;
	min-height: 100px;
	border: none;
	margin: 0 0px;
}

.header .navbar.navbar-fixed-top {
	top: 0px;
	background: #212121;
}

.header .navbar.navbar-fixed-top.affix {
	top: 0;
}

.header-bordered .navbar {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	-webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}

.header-bordered .navbar.navbar--dark {
	border-bottom: 1px solid rgba(34, 34, 34, 0.1);
}

.header .navbar .logo {
	height: 100px;
	line-height: 100px;
}
.header .navbar .logo img {
	height: 28px;
	width: 180px;
	line-height: 100px;
}

.navbar-nav:not(.nav-pos-right) {
/*margin-right: 200px;*/
}

.nav-bordered-right {
	margin-right: 30px;
}

.nav-bordered-right + .module:first-of-type:before {
	position: relative;
	margin-left: 12px;
}

.nav-bordered-right + .module:first-of-type:before {
	position: absolute;
	top: 50%;
	margin-top: -13px;
	left: -46px;
	content: "";
	width: 2px;
	height: 26px;
	background-color: #c9c9c9;
}

.affix .nav-bordered-right + .module:first-of-type:before {
	background-color: #c9c9c9;
}

.navbar-nav > li {
	margin-right: 32px;
}

.navbar-nav > li > a {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 100px;
	color: #ffffff;
	padding: 0;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: transparent;
	border: none;
}

.navbar-nav > li.active > ul {
	margin-top: 0;
}

.navbar-nav > li > a:focus,
.navbar-nav > li > a:hover,
.navbar-nav > li.active > a {
	background-color: transparent;
	color: #e7b238;
}

/* Menu Level #1 */
.navbar-nav > li > .menu-item:before {
	position: absolute;
	bottom: 35px;
	left: 50%;
	left: 0;
	width: 0;
	height: 2px;
	background: transparent;
	content: '';
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.navbar-nav > li > .menu-item:hover::before,
.navbar-nav > li.active > .menu-item:before {
	background-color: #e7b238;
	width: 100%;
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:before,
ul.dropdown-menu > li.has-dropdown > a:before {
	font-family: fontawesome;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 25px;
}

/* Menu Levels */
.dropdown-menu {
	background-color: transparent;
	position: relative;
	width: 100%;
	border: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

/**
Header Customaztion
**/
.transparent-header .navbar {
	background-color: transparent;
	height: 101px;
}

.transparent-header .navbar-nav > li.has-dropdown > a {
	color: #ffffff;
}

.transparent-header .navbar-nav li.has-dropdown > a:hover,
.transparent-header .navbar-nav li.has-dropdown.active > a {
	color: #e7b238;
}

/* Header Light */
.header-light {
	background-color: #ffffff;
}

.header-light .navbar .logo-dark {
	display: inline-block;
}

.header-light .navbar .logo-light {
	display: none;
}

.header-light .navbar .module .module-icon i,
.header-light .navbar .navbar-nav > li > a {
	color: #222222;
}

.header-light .navbar .navbar-nav > li > .menu-item:hover::before,
.header-light .navbar .navbar-nav > li.active > .menu-item:before {
	background-color: #222222;
}

/* Header Fixed */
.header-fixed .affix {
	background-color: #ffffff;
	-webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
	-moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
	-ms-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.header-fixed:not(.header-light) .navbar .logo-dark {
	display: none;
}

.header-fixed .navbar.affix .logo-dark {
	display: inline-block;
}

.header-fixed .navbar.affix .logo-light {
	display: none;
}

.header-fixed .navbar.affix .module .module-icon i,
.header-fixed .navbar.affix .navbar-nav > li > a {
	color: #222222;
}

.header-fixed .navbar.affix .navbar-nav > li > .menu-item:hover::before,
.header-fixed .navbar.affix .navbar-nav > li.active > .menu-item:before {
	background-color: #222222;
}

/* Header Centered */
.header-centered {
	height: 255px;
}

.header-centered .navbar-collapse {
	padding-right: 260px;
	padding-left: 260px;
}

.header-centered .navbar {
	top: 155px;
}

.header-centered .navbar.affix {
	top: 0;
}

.header-centered .logo-centered {
	padding-top: 50px;
	padding-bottom: 25px;
}

/* Header With Top Bar */
.header-topbar {
	height: 155px;
}

.header-topbar .navbar.navbar-fixed-top {
	top: 75px;
}

.header-topbar .navbar.navbar-fixed-top.affix {
	top: 0;
}

/* Header #2 */
.header-2 .navbar-nav > li > .menu-item:before {
	bottom: 35px;
}

/* Header #4 */
.header-4 .navbar {
	background-color: #ffffff;
	top: 50px;
}

/* Header #6 */
.header-6 .navbar-collapse.pull-left {
	padding-left: 100px;
}

.header-6 .navbar-collapse.pull-right {
	padding-right: 100px;
}

/* Header #8 */
.header-8 {
	height: 150px;
}

.header-8 .navbar {
	padding-top: 32px;
}

.header-8 + .slider {
	position: relative;
	margin-top: -150px;
}

.header-8 .logo {
	margin-top: -19px;
}

/* Header #10 */
.header-centered.header-10 {
	height: 165px;
}

.header-centered.header-10 .navbar-collapse {
	padding-right: 0;
	padding-left: 175px;
}

.header-centered.header-10 .navbar {
	top: 115px;
}

.header-centered.header-10 .navbar.affix {
	top: 0;
}

.header-centered.header-10 + .slider,
.header-centered.header-10 + .mtop-100,
.header-centered.header-10 + .page-title,
.header-centered.header-10 + .slider {
	margin-top: -170px;
}

.header-10.header-centered .logo-centered {
	padding-top: 80px;
	padding-bottom: 40px;
	z-index: 1040;
	position: fixed;
	right: 0;
	left: 0;
}

/* Header #13 */
.header-3 .navbar-header > .logo {
	position: absolute;
	left: 50%;
	margin-left: -59px;
}

/* Header #16 */
.header-16 .navbar {
	top: 30px;
}

.header-16 .navbar.affix {
	top: 0;
}

/* Header Half */
.header-half .navbar-nav > li > .menu-item:before {
	bottom: 36px;
}

/* Container Bordered */
.container-bordered {
	border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	height: 100px;
}

/* Navbar Dark */
.header-transparent.header-light .navbar.affix {
	background-color: transparent;
}

.navbar.navbar--dark .logo-dark {
	display: inline-block !important;
}

.navbar.navbar--dark .logo-light {
	display: none;
}

.navbar.navbar--dark .module .module-icon,
.navbar.navbar--dark .module .module-icon i,
.navbar.navbar--dark .navbar-nav > li > a {
	color: #222222;
}

.navbar.navbar--dark .navbar-nav > li > .menu-item:hover::before,
.navbar.navbar--dark .navbar-nav > li.active > .menu-item:before {
	background-color: #222222;
}

/* Header Split */
.header-split .logo-dark {
	display: none;
}

.header-split .logo-light {
	display: inline-block;
}

.header-split .module .module-icon i,
.header-split .navbar-nav > li > a {
	color: #222222;
}

.header-split .navbar-nav > li > .menu-item:hover::before,
.header-split .navbar-nav > li.active > .menu-item:before {
	background-color: #222222;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.header .navbar {
	margin: 0 6px;
}

.header-centered .logo-centered {
	display: none !important;
}

.header-centered.header-10 {
	height: 120px;
}

.header-centered.header-10 .navbar {
	top: 0;
}

.header-centered .navbar-collapse {
	padding: 0;
	background-color: #222222;
}

.header-centered.header-10 .navbar-collapse {
	padding-left: 0 !important;
}

.header-3 .navbar-header > .logo {
	position: relative;
	left: 0;
	margin-left: 0;
}

.header-3 #navbar-collapse-2 {
	display: none;
}

.header-16 .navbar {
	top: 0;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.header-3 .navbar-header > .logo {
	position: relative;
	left: 0;
	margin-left: 0;
}

.header-centered .logo-centered {
	display: none !important;
}

.header-centered.header-10 {
	height: 100px;
}

.header-centered.header-10 .navbar {
	top: 0;
}

.header-centered .navbar-collapse {
	padding-right: 0;
	padding-left: 0;
}

.header-centered.header-10 .module {
	display: inline-block;
}

.header-16 .navbar {
	top: 0;
	background-color: #ffffff;
}
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 479px) {

.mega-dropdown-menu ul {
	list-style: none;
	margin-left: -10px;
}

.mega-dropdown-menu ul li a {
	line-height: 28px;
	padding: 0;
}

.mega-dropdown-menu ul li a i {
	margin-right: 8px;
}
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.header .container {
	padding-right: 0;
	padding-left: 0;
}

.navbar-nav {
	margin: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-toggle {
	border-color: #ffffff;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 35px;
}

.affix .navbar-toggle,
 .header-light .navbar-toggle {
	border-color: #222222;
}

.navbar-toggle .icon-bar {
	background-color: #ffffff;
}

.affix .navbar-toggle .icon-bar,
 .header-light .navbar-toggle .icon-bar {
	background-color: #222222;
}

.container > .navbar-header {
	margin-left: 0;
	padding-right: 10px;
	padding-left: 10px;
}

.container .navbar-collapse,
 .container-bordered .navbar-collapse,
 .container-fluid .navbar-collapse {
	width: 100%;
	background-color: #222222;
	margin-right: 0;
	margin-left: 0;
}

.navbar-nav > li {
	height: auto !important;
	margin-right: 0;
}

.navbar-nav > li.pull-left {
	float: none !important;
}

.navbar-nav li a {
	line-height: 36px;
	color: #ffffff;
	padding-right: 0;
	padding-left: 10px;
	padding-top: 0;
}

.navbar-nav > li > .menu-item:before {
	display: none;
}

ul ul {
	list-style: none;
}

ul.dropdown-menu li a,
 ul.mega-menu,
 ul.mega-menu li ul li a {
	background-color: transparent;
	position: relative;
	padding: 0;
}

.navbar-nav > li.has-dropdown > a:after,
 li.dropdown-submenu > a:after {
	font-family: fontawesome;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 10px;
}

.navbar-nav > li.has-dropdown.open > a,
 .navbar-nav > li.has-dropdown.open > a:focus,
 .navbar-nav > li.has-dropdown.open > a:hover {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

li.dropdown-submenu.open > ul > li > a {
	padding-left: 50px !important;
}

.navbar-nav > li.has-dropdown.open > a:after,
 .navbar-nav > li.has-dropdown.open > a:focus:after,
 .navbar-nav > li.has-dropdown.open > a:hover:after,
 li.dropdown-submenu.open > a:after,
 li.dropdown-submenu.open > a:focus:after,
 li.dropdown-submenu.open > a:hover:after {
	font-family: fontawesome;
	content: "\f107";
	font-size: 13px;
	position: absolute;
	right: 10px;
}

li.dropdown-submenu > a:after,
 li.dropdown-submenu.open > a:focus:after,
 li.dropdown-submenu.open > a:hover:after {
	right: 10px;
}

.dropdown-menu > li > a:focus,
 .dropdown-menu > li > a:hover,
 .mega-menu li a:focus,
 .mega-menu li a:hover,
 .nav > li > a:focus,
 .nav > li > a:hover {
	background-color: transparent;
}

.dropdown-menu > li > a,
 .mega-menu > li > a {
	color: #ffffff;
}

.mega-menu ul {
	list-style: none;
	padding-left: 25px;
}

.mega-menu ul li {
	padding-top: 5px;
}

.dropdown-menu > li > a:focus,
 .dropdown-menu > li > a:hover,
 .mega-menu li a:focus,
 .mega-menu li a:hover {
	color: #ffffff;
}

ul.mega-menu {
	list-style: none;
	display: none;
}

.dropdown-submenu.open > .mega-menu,
 .has-dropdown.open > .mega-menu {
	display: block;
}

.dropdown-submenu.open > .mega-menu ul {
	padding-left: 50px;
}

.header-fixed .navbar.affix .module .module-icon i,
 .header-fixed .navbar.affix .navbar-nav > li > a {
	color: #ffffff;
}

.mega-dropdown-menu .container a[href='#'] {
	color: #e7b238;
	cursor: default;
	margin-left: 10px;
	padding: 0;
	font-weight: 700;
	text-transform: capitalize;
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:before,
 ul.dropdown-menu > li.has-dropdown > a:before {
	display: none;
}

.navbar.navbar--dark .module .module-icon i,
 .navbar.navbar--dark .navbar-nav > li > a {
	color: #ffffff;
}

.navbar-nav:not(.nav-pos-right) {
	margin-right: 0;
}

.mega-dropdown-menu li ul li a i {
	margin-right: 8px;
}

.header-light .navbar .module .module-icon i,
 .header-light .navbar .navbar-nav > li > a {
	color: #ffffff;
}

.container-fluid > .navbar-collapse,
 .container-fluid > .navbar-header,
 .container > .navbar-collapse,
 .container > .navbar-header {
	margin-right: 0;
	margin-left: 0;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.header .container {
	padding-right: 0;
	padding-left: 0;
}

.navbar-header {
	float: none !important;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.header .navbar .logo {
	float: left;
}

.navbar-toggle {
	border-color: #ffffff;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin-top: 35px;
	display: block;
}

.affix .navbar-toggle,
 .header-light .navbar-toggle {
	border-color: #222222;
}

.navbar-toggle .icon-bar {
	background-color: #ffffff;
}

.affix .navbar-toggle .icon-bar,
 .header-light .navbar-toggle .icon-bar {
	background-color: #222222;
}

.navbar-fixed-top .navbar-collapse {
	overflow: scroll !important;
}

.navbar-collapse.collapse {
	display: none!important;
}

.collapse.in {
	display: block !important;
}

.navbar-nav {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.navbar-nav > li {
	float: none;
}

.container > .navbar-collapse {
	width: 100%;
	background-color: #222222;
	margin-right: 0;
}

.navbar-nav > li {
	height: auto !important;
	margin-right: 0;
}

.navbar-nav > li.pull-left {
	float: none !important;
}

.navbar-nav > li > a {
	line-height: 36px !important;
	color: #ffffff !important;
	padding-right: 0;
	padding-left: 0;
	padding-top: 0;
}

.dropdown-menu > li > a,
 .mega-menu a {
	padding-top: 0;
}

.navbar-nav > li:after {
	display: none;
}

.navbar-nav > li.active {
	border-bottom: none;
}

.navbar-nav > li.has-dropdown > a:after,
 .navbar-nav > li.has-dropdown > a:after,
 li.dropdown-submenu > a:after {
	font-family: fontawesome;
	content: "\f105";
	font-size: 13px;
	position: absolute;
	right: 0;
}

.navbar-nav > li.has-dropdown.open > a,
 .navbar-nav > li.has-dropdown.open > a:focus,
 .navbar-nav > li.has-dropdown.open > a:hover {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mega-menu > li > a,
 li a:hover,
 li.dropdown-submenu.open > a,
 li.open > a:focus {
	color: #ffffff;
}

li.dropdown-submenu.open > ul > li > a {
	padding-left: 50px !important;
}

.navbar-nav > li.has-dropdown.open > a:after,
 .navbar-nav > li.has-dropdown.open > a:focus:after,
 .navbar-nav > li.has-dropdown.open > a:hover:after,
 li.dropdown-submenu.open > a:after,
 li.dropdown-submenu.open > a:focus:after,
 li.dropdown-submenu.open > a:hover:after {
	font-family: fontawesome;
	content: "\f107";
	font-size: 13px;
	position: absolute;
	right: 0;
}

li.dropdown-submenu > a:after,
 li.dropdown-submenu.open > a:focus:after,
 li.dropdown-submenu.open > a:hover:after {
	right: 10px;
}

.dropdown-menu > li > a:focus,
 .dropdown-menu > li > a:hover,
 .nav > li > a:focus,
 .nav > li > a:hover {
	background-color: transparent;
}

.dropdown-menu > li > a,
 .mega-dropdown-menu .container a,
 .mega-menu a {
	color: #ffffff;
}

.dropdown-menu > li > a:focus,
 .dropdown-menu > li > a:hover {
	color: #ffffff;
}

.mega-menu ul {
	list-style: none;
	padding-left: 0;
}

.mega-menu ul li {
	padding-top: 5px;
}

.mega-menu ul ul {
	padding-left: 0;
}

.dropdown-menu > li > a:focus,
 .dropdown-menu > li > a:hover,
 .mega-menu li a:focus,
 .mega-menu li a:hover {
	color: #ffffff;
}

ul.mega-menu {
	list-style: none;
	display: none;
}

.mega-dropdown-menu .container ul {
	list-style: none;
	padding-left: 5px;
}

.mega-dropdown-menu .container ul li {
	line-height: 25px;
}

.mega-dropdown-menu .container ul i {
	margin-right: 5px;
}

.dropdown-submenu.open > .mega-menu,
 .has-dropdown.open > .mega-menu {
	display: block;
	padding-left: 25px;
}

.dropdown-submenu.open > .mega-menu ul {
	padding-left: 25px;
}

.navbar-nav > li > .menu-item:before,
 li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > a:before,
 ul.dropdown-menu > li.has-dropdown > a:before {
	display: none;
}

.mega-dropdown-menu .container a[href='#'] {
	color: #e7b238;
	cursor: default;
	margin-left: 10px;
	padding: 0;
	font-weight: 700;
	text-transform: capitalize;
}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {

.navbar.navbar--dark .module .module-icon i,
 .navbar.navbar--dark .navbar-nav > li > a {
	color: #ffffff;
}

.container .navbar-collapse,
 .container-bordered .navbar-collapse,
 .container-fluid .navbar-collapse {
	width: 100%;
	background-color: #222222;
	margin-right: 0;
	margin-left: 0;
}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.navbar-nav > li {
	margin-right: 13px;
}

.module-reservation {
	margin-right: 10px;
}
}

@media only screen and (min-width: 992px) {

/* Menu with Dropdown */
.navbar-nav > li.has-dropdown > ul.dropdown-menu,
 .navbar-nav > li.has-dropdown > ul.mega-menu,
 li.dropdown-submenu > ul.mega-menu {
	background-color: #1b1a1a;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	-webkit-transform: scaleY(0);
	-moz-transform: scaleY(0);
	-o-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 0 0 0;
	-ms-transform-origin: 0 0 0;
	-o-transform-origin: 0 0 0;
	transform-origin: 0 0 0;
	opacity: 0;
	position: absolute;
	left: 0;
	right: auto;
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.06);
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	display: block;
}

.navbar-nav > li.has-dropdown > ul.dropdown-menu {
	width: 190px;
	min-width: 190px;
	padding: 13px 0;
}

.navbar-nav > li.has-dropdown > ul.mega-menu,
 li.dropdown-submenu > ul.mega-menu {
	width: 385px!important;
}

li.dropdown-submenu:hover > ul.mega-menu,
 li.has-dropdown:hover > ul.dropdown-menu,
 li.has-dropdown:hover > ul.mega-menu {
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	-o-transform: scaleY(1);
	-ms-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	z-index: 100;
	display: block;
}

ul.dropdown-menu > li,
 ul.mega-menu > li {
	padding-right: 0;
	height: 40px;
	line-height: 40px;
	position: relative;
	vertical-align: top;
}

ul.mega-menu > li {
	border-right: 1px solid #1e1e1e;
}

ul.mega-menu > li:last-child {
	border-right: none;
}

ul.mega-menu > li {
	width: 190px !important;
	height: auto;
	overflow: hidden;
	display: inline-block;
}

ul.mega-menu > li > ul {
	width: 190px;
	list-style: none;
	padding-left: 0;
	position: relative !important;
	left: auto !important;
}

ul.mega-menu li ul li {
	height: 40px;
	line-height: 40px;
	padding: 0;
}

ul.dropdown-menu li a,
 ul.mega-menu li ul li a {
	line-height: 40px;
	color: #7f7f7f;
	padding: 0;
	border-bottom: 1px solid #1e1e1e;
	display: block;
	font-size: 13px;
	font-weight: 400;
	-o-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
	text-transform: capitalize;
	margin: 0 25px;
}

ul.mega-menu li ul li span {
	color: #e7b238;
	padding: 0;
	display: block;
	font-size: 13px;
	line-height: 39px;
	text-transform: uppercase;
	font-weight: 700;
}

ul.dropdown-menu li:last-child > a {
	border-bottom: none;
}

ul.dropdown-menu li > a:active,
 ul.dropdown-menu li > a:focus,
 ul.dropdown-menu li > a:hover,
 ul.mega-menu li a:active,
 ul.mega-menu li a:focus,
 ul.mega-menu li a:hover {
	background-color: transparent;
	color: #e7b238;
	padding: 0 0 0 20px;
}

ul.dropdown-menu.left {
	left: -185px !important;
	margin-left: 0 !important;
}

ul.mega-menu.left {
	left: -410px !important;
}

/* Menu Level #2 */
li.dropdown-submenu > ul.mega-menu,
 li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu > ul.dropdown-menu {
	background-color: #1b1a1a;
	padding: 25px 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
	-webkit-transform: scaleY(0);
	-moz-transform: scaleY(0);
	-o-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 0 0 0;
	-ms-transform-origin: 0 0 0;
	-o-transform-origin: 0 0 0;
	transform-origin: 0 0 0;
	opacity: 0;
	display: block;
	position: absolute;
	left: 100%;
	right: auto;
	top: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	margin: -1px 0 0;
	z-index: 1001;
}

li.has-dropdown > ul.dropdown-menu > li.dropdown-submenu:hover > ul.dropdown-menu {
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	z-index: 1001;
	margin-top: -1px;
	margin-left: 0;
	padding: 13px 0;
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.1);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.1);
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.1);
}

li.dropdown-submenu.pull-left > ul.dropdown-menu {
	left: auto;
	right: 0;
	margin-right: 0;
}

/* Menu Level #3 */
li.dropdown-submenu > ul.dropdown-menu > li.has-dropdown > ul.dropdown-menu {
	background-color: #1b1a1a;
	float: none;
	position: absolute;
	border-radius: 0;
	left: 100%;
	right: auto;
	top: 0;
	margin: -1px 0 0;
	z-index: 1;
	padding: 13px 0;
}

li.dropdown-submenu > ul.dropdown-menu > li.has-dropdown > ul.dropdown-menu.left {
	left: -185px !important;
}

/* Mega Menu Content */
.mega-dropdown {
	position: static !important;
}

.mega-dropdown-menu {
	padding: 20px 0 !important;
	width: 100% !important;
	border: none !important;
	margin-top: -23px;
}

.mega-dropdown-menu .container div[class*='col-'] {
	padding-left: 0;
	padding-right: 0;
}

.mega-dropdown-menu li ul {
	padding: 0;
	margin: 0;
}

.mega-dropdown-menu li ul li {
	list-style: none;
}

.mega-dropdown-menu li ul li a {
	padding: 0;
	border-bottom: none;
}

.mega-dropdown-menu li ul li a:hover {
	padding: 0 0 0 12px;
}

.mega-dropdown-menu li ul li a i {
	margin-right: 8px;
}

.mega-dropdown-menu .container div[class*='col-'] > a {
	border-bottom: none;
	padding-left: 0;
	font-size: 14px;
}

.mega-dropdown-menu .container a[href='#'] {
	color: #e7b238;
	cursor: default;
	font-weight: 600;
}
}

/*------------------------------------*\
    #Hamburger
\*------------------------------------*/
/* Hamburger Panel */
.hamburger-panel {
	background-color: #222222;
}

.hamburger-panel .list-links {
	margin-bottom: 0;
	padding: 45px 30px;
}

.hamburger-panel .list-links a {
	font-size: 14px;
	font-weight: 700;
	line-height: 44px;
	text-transform: uppercase;
	color: #ffffff;
	margin-bottom: 0;
	position: relative;
	display: block;
}

.hamburger-panel .list-links a:hover {
	color: #e7b238;
}

.hamburger-panel .social-share {
	padding: 0 30px;
	margin-bottom: 49px;
}

.hamburger-panel .social-share a {
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 1px;
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
	display: inline-block;
	background-color: transparent;
	margin-right: 5px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.hamburger-panel .social-share a:hover {
	color: #ffffff !important;
}

.hamburger-panel .social-share a.share-facebook:hover {
	background-color: #3B5998;
}

.hamburger-panel .social-share a.share-twitter:hover {
	background-color: #00ACEE;
}

.hamburger-panel .social-share a.share-pinterest:hover {
	background-color: #C8232C;
}

.hamburger-panel .social-share a.share-google-plus:hover {
	background-color: #DD4B39;
}

.hamburger-panel .social-share a.share-dribbble:hover {
	background-color: #EA4C89;
}

.hamburger-panel .copywright {
	text-align: center;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	color: #a5a5a5;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.hamburger-panel .list-links {
	padding: 25px;
}

.hamburger-panel {
	padding: 30px;
}

.hamburger-panel .list-links a {
	line-height: 35px;
}

.hamburger-panel .copywright span {
	display: block;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.hamburger-panel .logo {
	float: none !important;
	height: 50px !important;
	line-height: 50px !important;
}

.hamburger-panel .list-links {
	padding: 25px;
}
}

/*------------------------------------*\
    #Hero
\*------------------------------------*/
.header-transparent + .slider {
	margin-top: -100px;
}

.slider {
	height: 700px;
	padding-top: 0;
	padding-bottom: 0;
	overflow: hidden;
	position: relative;
}

.slider.slim {
	height: 350px;
}

.slider .rev_slider {
	overflow: visible !important;
}

.slider .slide--item {
	height: 700px;
}

.slider .slide--headline.extend {
	line-height: 1.3;
}

.slider .slide--subheadline {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 55px;
	font-weight: 400;
	line-height: 35px;
	margin-bottom: 0;
}

.slider .slide--headline {
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 55px;
	font-weight: 700;
	line-height: 100px;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.slider .slide--bio {
	color: #f9f9f9;
	font-family: 'Montserrat', sans-serif;
	font-size: 20px;
	font-weight: 400;
	line-height: 26px;
	margin-bottom: 40px;
}

.slider .tp-bullet {
	height: 10px;
	width: 10px;
}

/* Revoultion slide Arrows */
.slider .tparrows {
	width: 25px;
	height: 108px;
	background-color: transparent;
}

.slider .tparrows:hover {
	background-color: transparent;
}

.slider .tparrows:before {
	display: block;
	text-align: center;
}

.divider--slider-left {
	width: 16px;
	height: 10px;
	background: url(/images/sliders/arrows/left-arrow.png) center no-repeat;
	position: absolute;
	top: 50%;
	left: -4px;
	margin-left: 0;
	margin-top: -5px;
	z-index: 999;
}

.divider--slider-right {
	width: 16px;
	height: 10px;
	background: url(/images/sliders/arrows/right-arrow.png) center no-repeat;
	position: absolute;
	top: 50%;
	right: -4px;
	margin-right: 0;
	margin-top: -5px;
	z-index: 999;
}

.slider .tparrows.tp-leftarrow:before {
	content: "";
	background-image: url(/images/sliders/arrows/left-arrow-bg.png);
	width: 28px;
	height: 108px;
	position: relative;
	left: 2px;
}

.slider .tparrows.tp-rightarrow:before {
	content: "";
	background-image: url(/images/sliders/arrows/right-arrow-bg.png);
	width: 28px;
	height: 108px;
	position: relative;
	right: 5px;
}

/* bullets */
.tp-bullet {
	width: 10px;
	height: 10px;
}

/* Revoultion Slide Overlay */
.slide-overlay-dark .slotholder:after {
	width: 100%;
	height: 100%;
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	background-color: #1b1a1a;
	background-image: -webkit- linear-gradient(to bottom, rgba(27, 26, 26, 0.55) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%);
	background-image: -moz- linear-gradient(to bottom, rgba(27, 26, 26, 0.55) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%);
	background-image: linear-gradient(to bottom, rgba(27, 26, 26, 0.55) 0%, rgba(27, 26, 26, 0) 34%, rgba(27, 26, 26, 0) 82%);
	opacity: .8;
}

.slide-action .btn--lg {
	width: 270px;
}

.uranus .tp-bullet-inner {
	background-color: #ffffff !important;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.slider .slide--headline {
	font-size: 25px;
	line-height: 1.2;
}
.slider .slide--subheadline {
	font-size: 35px;
}

.slider .slide--bio {
	font-size: 14px;
	line-height: 1.5;
	padding: 0 10px;
}

.slider,
 .slider .slide--item {
	height: 600px;
}

.rs-arrows.tparrows.ares {
	display: none;
}

.slide-action .btn {
	width: 120px;
	font-size: 12px;
}

.slide-action .btn.btn--lg {
	width: 190px;
	font-size: 12px;
}

.slider-navs .owl-controls .owl-nav [class*=owl-] {
	width: 30px !important;
}

.slide-action .btn--slide-wide {
	width: 220px;
}

.slider-portfolio .slide--container {
	padding: 30px 20px;
}

.slider-portfolio .slide--headline {
	font-size: 25px !important;
}

.slide-action .btn.btn--icon {
	height: 60px;
	font-size: 30px;
	line-height: 60px;
}

.hesperiden.tparrows,
 .slider .tparrows:before,
 .divider--slider-right,
 .divider--slider-left {
	display: none;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.slider,
 .slider .slide--item {
	height: 600px;
}

.slider-navs .owl-controls .owl-nav [class*=owl-] {
	width: 30px !important;
}

.slider .slide--headline {
	font-size: 35px;
}

.slider-portfolio .slide--headline {
	font-size: 25px !important;
}

.slider-rs8 .slide--headline {
	font-size: 40px;
	line-height: 55px;
}

.slider-rs8 .slide--bio {
	line-height: 22px;
	font-size: 12px;
}

.rs-arrows.tparrows.ares {
	display: none;
}

.divider--slider-left,
 .divider--slider-right {
	top: 52%;
}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

.slider,
 .slider .slide--item {
	height: 600px;
}
}

/*------------------------------------*\
    #Team
\*------------------------------------*/
.team {
	padding-bottom: 80px;
}

/* Team shadow */
.team-shadow .member {
	-webkit-box-shadow: 0 0 18px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 0 18px rgba(40, 40, 40, 0.12);
	-ms-box-shadow: 0 0 18px rgba(40, 40, 40, 0.12);
	box-shadow: 0 0 18px rgba(40, 40, 40, 0.12);
	border-radius: 2px;
}

.member {
	text-align: center;
	overflow: hidden;
}

.member:hover .member-img img {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

.member .member-img {
	position: relative;
	margin-bottom: 0;
	overflow: hidden;
}

.member .member-img img {
	max-width: 100%;
	height: auto;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.member .member-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.member .member-overlay .member-hover {
	border-radius: 2px;
	background-color: rgba(34, 34, 34, 0.9);
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	opacity: 0;
}

.member:hover .member-overlay .member-hover {
	opacity: 1;
}

.member .member-hover a {
	height: 42px;
	width: 42px;
	line-height: 42px;
	background-color: #e7b238;
	color: #ffffff;
	font-size: 14px;
	font-weight: 400;
	margin-right: 10px;
	display: inline-block;
}

.member .member-hover a:last-child {
	margin-right: 0;
}

.member .member-hover a:hover {
	color: #e7b238;
	background-color: #ffffff;
}

.member .member-desc {
	padding: 15px;
	font-size: 15px;
	color: #9b9b9b;
	margin-bottom: 0;
}

.member .member-desc p {
	font-size: 14px;
	line-height: 23px;
}

.member .member-info {
	padding-top: 30px;
}

.member .member-info h5 {
	color: #282828;
	font-size: 17px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 14px;
}

.member .member-info h6 {
	color: #a5a5a5;
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 0;
}

/*  team 3 */
.team-3 .member {
	text-align: center;
}

.team-3 .member .member-social {
	position: relative;
	-webkit-transform: translateY(80px);
	-moz-transform: translateY(80px);
	-o-transform: translateY(80px);
	transform: translateY(80px);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.team-3 .member .member-info {
	position: relative;
	-webkit-transform: translateY(140px);
	-moz-transform: translateY(140px);
	-o-transform: translateY(140px);
	transform: translateY(140px);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.team-3 .member .member-info h5 {
	color: #ffffff;
}

.team-3 .member:hover .member-overlay .member-hover .member-social {
	transform: translateY(30px);
}

.team-3 .member:hover .member-overlay .member-hover .member-info {
	transform: translateY(170px);
}

/*  team 4 */
.team-4 .member .member-desc {
	padding: 0;
}

.team-4 .member .member-desc p {
	padding: 0 18px;
}

.team-4 .member .member-hover {
	margin-top: 25px;
}

.team-4 .member .member-hover a {
	color: #333333;
}

.team-4 .member .member-hover a:hover {
	color: #e7b238;
}

.team-4 .divider--line {
	margin-top: 18px;
	margin-bottom: 15px;
	width: 100%;
	height: 16px;
	background: url(../images/background/shapes/shape-4.png) center no-repeat;
}

.team-4 .divider--line:after {
	display: none;
}

.team-4 .member .member-hover a {
	height: auto;
	width: auto;
	line-height: 1;
	background-color: transparent;
	color: #333333;
	font-size: 14px;
	font-weight: 400;
	margin-right: 10px;
	display: inline-block;
}

.team-4 .member .member-hover a:hover {
	color: #e7b238;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 991px) {

.member {
	margin-bottom: 30px;
}

.member .member-img {
	max-width: 270px;
	margin-right: auto;
	margin-left: auto;
}
}

/*------------------------------------*\
    #Testimonial
\*------------------------------------*/
.testimonial-1 .testimonial-panel {
	background-color: #ffffff;
	padding: 10px;
	position: relative;
	text-align: center;
}

.testimonial-bordered {
	border: 1px dashed #c69c6d;
	padding: 28px;
}

.testimonial-container {
	padding-bottom: 11px;
	position: relative;
}

.testimonial-container .divider--shape-1up {
	bottom: 0;
	top: auto;
}

.testimonial .testimonial-panel p {
	font-size: 14px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: #a5a5a5;
	line-height: 25px;
	margin-bottom: 23px;
}

.testimonial--rating {
	margin-bottom: 20px;
}

.testimonial--rating i {
	color: #ffc80a;
	font-size: 16px;
	letter-spacing: 4px;
}

.testimonial-panel .testimonial--img {
	margin-bottom: 22px;
}

.testimonial-panel .testimonial--img img {
	width: 54px;
	height: 54px;
	line-height: 54px;
	border-radius: 50%;
	margin: 0 auto;
	display: block;
}

.testimonial--author h4 {
	font-size: 13px;
	line-height: 1.3;
	color: #e7b238;
	font-weight: 400;
	font-style: italic;
	margin-bottom: 0;
}

/* Testimonial #2 */
.testimonial-2 .testimonial-panel {
	background-color: #ffffff;
	padding: 40px 33px 19px 33px;
	position: relative;
	text-align: center;
}

.testimonial-2 .testimonial-container {
	padding-top: 11px;
}

.testimonial-2 .testimonial-container .divider--shape-1down {
	top: 0;
	bottom: auto;
}

.testimonial-2 .testimonial-container .divider--shape-1up {
	bottom: 46px;
	top: auto;
}

.testimonial-2 .testimonial--author {
	margin-top: 30px;
}

.testimonial-2 .testimonial--author h4 {
	color: #f9f9f9;
}

.testimonial-2 .testimonial-panel .testimonial--img {
	margin-bottom: 0;
}

/* Testimonial #3 */
.testimonial-3 .testimonial--author h4 {
	color: #a5a5a5;
}

.testimonial-3 .testimonial-panel p {
	font-size: 47px;
	font-family: 'Great Vibes', cursive;
	color: #333333;
	line-height: 50px;
}

/* Testimonial #4 */
.testimonial-4 .testimonial--author h4 {
	color: #f9f9f9;
}

.testimonial-4 .testimonial-panel p {
	font-size: 47px;
	font-family: 'Great Vibes', cursive;
	line-height: 50px;
}

/* Testimonial #5 */
.testimonial-5 {
	padding-top: 150px;
	padding-bottom: 150px;
}

.testimonial-5 .testimonial-panel .testimonial--img {
	margin-bottom: 40px;
}

.testimonial-5 .testimonial-panel .testimonial--img img {
	width: inherit !important;
	border-radius: 0;
	height: inherit;
}

.testimonial-5 .testimonial--author h4 {
	color: #e7b238;
}

.testimonial-5 .testimonial-panel p {
	font-size: 44px;
	font-family: 'Great Vibes', cursive;
	font-weight: 400;
	color: #ffffff;
	line-height: 39px;
	margin-bottom: 23px;
}

.testimonial-5 .testimonial--quote img {
	width: 40px;
	height: 26px;
	margin: 0 auto;
	display: block;
	margin-bottom: 50px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.testimonial-5 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.testimonial-5 .testimonial-panel p {
	font-size: 30px;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #Portfolio
\*------------------------------------*/
/* Portfolio Filter */
.portfolio-filter {
	text-align: center;
	margin-bottom: 36px;
}

.portfolio-filter li a {
	color: #333333;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	margin-right: 25px;
	text-transform: capitalize;
	letter-spacing: 0.44px;
	padding-bottom: 10px;
	position: relative;
}

.portfolio-filter li a:after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	height: 2px;
	background-color: #e7b238;
	width: 0;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.portfolio-filter li:last-child a {
	margin-right: 0;
}

.portfolio-filter li a.active-filter,
.portfolio-filter li a:hover {
	color: #e7b238;
}

.portfolio-filter li a.active-filter::after,
.portfolio-filter li a:hover::after {
	width: 100%;
}

/* Portfolio Item */
.portfolio-item .portfolio--img {
	position: relative;
	padding: 5px;
	text-align: center;
}

.portfolio-item .portfolio--img img {
	max-width: 100%;
	height: auto;
}

.portfolio-item .portfolio--hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 25px;
}

.portfolio-item .portfolio--hover .portfolio--action {
	width: 100%;
	height: 100%;
	background-color: rgba(34, 34, 34, 0.9);
	text-align: center;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}

.portfolio-item:hover .portfolio--hover .portfolio--action {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.portfolio--content {
	text-align: center;
}

.portfolio--zoom {
	margin-bottom: 30px;
}

.portfolio--zoom a {
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
}

.portfolio--zoom a:before {
	position: absolute;
	content: "";
	left: 0;
	top: 10px;
	width: 20px;
	height: 1px;
	background-color: #ffffff;
}

.portfolio--zoom a:after {
	position: absolute;
	content: "";
	left: 10px;
	top: 0;
	width: 1px;
	height: 20px;
	background-color: #ffffff;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.portfolio-filter li a {
	margin-right: 0;
}

.portfolio-item .portfolio--img {
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #Features
\*------------------------------------*/
.feature-panel {
	position: relative;
	margin-bottom: 50px;
}

.feature-panel .feature--icon {
	margin-bottom: 23px;
}

.feature-panel .feature--content h3 {
	font-family: 'Montserrat', sans-serif;
	font-size: 28px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 19px;
	text-transform: capitalize;
}

.feature-panel .feature--content p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
}

.feature-left .feature-panel .feature--icon {
	position: absolute;
	left: 0;
	top: 0;
	margin-bottom: 0;
}

.feature-left .feature-panel .feature--content {
	padding-left: 88px;
}

.feature-left .feature-panel .feature--content h3 {
	font-size: 23px;
}

.feature-left .feature-panel .feature--content p {
	font-size: 14px;
	line-height: 23px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.feature-panel {
	margin-bottom: 40px;
}

.feature-panel .feature--icon {
	margin-bottom: 10px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.feature-panel {
	max-width: 390px;
	margin: 0 auto 40px auto;
}
}

/*------------------------------------*\
    #Call To Action
\*------------------------------------*/
.cta h3 {
	color: #ffffff;
	font-size: 44px;
	font-family: 'Great Vibes', cursive;
	font-weight: 400;
	margin-bottom: 30px;
}

/*------------------------------------*\
    #Counters
\*------------------------------------*/
.counter .count-box .counting {
	font-family: 'Montserrat', sans-serif;
	color: #ffffff;
	font-size: 70px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 16px;
	position: relative;
}

.counter .count-box .count-title {
	font-family: 'Montserrat', sans-serif;
	color: #f9f9f9;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
}

/* Counter #2 */
.counter-2 {
	padding: 130px 0;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.count-box {
	padding: 10px 0;
}

.counter .count-box .counting {
	font-size: 40px;
}

.counter .count-box .count-title {
	font-size: 14px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.counter .count-box .counting {
	font-size: 60px;
}
}

/*------------------------------------*\
    #PRICING-SECTION
\*------------------------------------*/
.pricing-panel {
	border: 1px solid #eeeeee;
	background-color: #ffffff;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	margin-top: 20px;
	padding: 30px;
}

.pricing .pricing--heading {
	background-color: #e7b238;
	padding: 70px 35px;
	position: relative;
}

.pricing .pricing--heading h5 {
	font-weight: 400;
	font-size: 20px;
	margin-bottom: 50px;
}

.pricing .pricing--heading p {
	font-family: 'Montserrat', sans-serif;
	color: #ffffff;
	font-size: 70px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 15px;
}

.pricing .pricing--heading span.currency {
	font-size: 28px;
}

.pricing .pricing--heading span.time {
	font-size: 13px;
	color: #ffffff;
}

.pricing .pricing--heading .pricing--desc {
	color: #f9f9f9;
	font-size: 13px;
	line-height: 21px;
}

.pricing--body {
	padding: 30px 0;
}

.pricing--body .list-group {
	margin: 0;
	border: 1px dashed #c69c6d;
}

.pricing--body .list-group li.list-group-item {
	font-size: 14px;
	font-weight: 400;
	line-height: 40px;
	border: none;
	background-color: transparent;
	padding: 0;
}

.pricing--body .list-group li.list-group-item:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.pricing-body .list-group li.list-group-item:first-child {
	padding-top: 0;
}

.pricing .panel-footer {
	background-color: #ffffff;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0;
}

.pricing .panel-footer .btn {
	margin-bottom: 0;
}

/* Pricing #2 */
.pricing-2 .pricing-panel {
	padding: 0;
	margin-bottom: 30px;
}

.pricing-2 .pricing--body {
	padding: 30px;
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.pricing .panel-body {
	padding-top: 50px;
	padding-bottom: 50px;
	line-height: 30px;
}

.pricing .panel-body h4 {
	font-size: 70px;
}

.pricing .list-group {
	padding: 25px;
}

.pricing .panel-footer {
	padding: 0 25px 25px 25px;
}

.pricing .panel-footer .btn {
	font-size: 11px;
	padding: 0 16px;
}

.pricing .panel-footer .btn i {
	margin-left: 5px;
}
}

/*------------------------------------*\
    #BLOG-SECTION
\*------------------------------------*/
.blog-entry {
	background-color: #ffffff;
	margin-bottom: 60px;
}

.blog-entry .entry--img {
	overflow: hidden;
	margin-bottom: 25px;
}

.blog-entry .entry--img img {
	max-width: 100%;
	height: auto;
}

.blog-entry .entry--content {
	position: relative;
}

.blog-entry .entry--meta {
	color: #616161;
	font-family: 'Montserrat', sans-serif;
	font-size: 11px;
	font-weight: 600;
	text-transform: capitalize;
	line-height: 1;
	margin-bottom: 5px;
}

.blog-entry .entry--meta span {
	padding: 0 5px;
}

.blog-entry .entry--meta span:first-of-type {
	padding-left: 0;
}

.blog-entry .entry--meta .entry--cat a {
	color: #e7b238;
}

.blog-entry .entry--title {
	margin-bottom: 20px;
}

.blog-entry .entry--title h4 {
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 0;
}

.blog-entry .entry--title h4 a {
	color: #222222;
}

.blog-entry .entry--title h4 a:hover {
	color: #e7b238;
}

.blog-entry .entry--bio {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
}

.blog-entry .entry--date {
	color: #686868;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 1;
}

/* Blog Standard */
.blog-standard .blog-entry .entry--content {
	position: relative;
}

.blog-standard .blog-entry .entry--meta {
	line-height: 1;
	margin-bottom: 10px;
}

.blog-standard .blog-entry .entry--bio {
	border-bottom: none;
	padding-bottom: 0;
}

/* Blog Carousel */
.blog-carousel .blog-entry {
	margin-bottom: 0px;
}

/* Blog Single */
.blog-single .col-md-10 {
	padding-right: 50px;
	padding-left: 50px;
}

.blog-single .blog-entry {
	margin-bottom: 40px;
}

.blog-single .blog-entry .entry--img img {
	-webkit-transform: none;
	-moz-transform: none;
	transform: none;
}

.blog-single .blog-entry .entry--content {
	position: relative;
}

.blog-single .blog-entry .entry--meta {
	line-height: 1;
	margin-bottom: 9px;
}

.blog-single .blog-entry .entry--title h4 {
	font-size: 20px;
	font-weight: 700;
	color: #333333;
}

.blog-single .blog-entry .entry--bio {
	padding-bottom: 0;
	border-bottom: 1px solid #d9d9d9;
}

.entry--share .share--title {
	color: #222222;
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	margin-bottom: 0;
	margin-right: 16px;
}

.entry--share a {
	color: #5d5d5d;
	font-size: 14px;
	font-weight: 400;
	margin-right: 22px;
	line-height: 1;
}

.entry--share a:last-child {
	margin-right: 0;
}

.entry--share a:hover {
	color: #e7b238;
}

.entry-prev-next {
	position: relative;
	-moz-box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
	-webkit-box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
	box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
	border-radius: 2px;
	background-color: #ffffff;
	padding: 40px;
	margin-bottom: 40px;
	min-height: 140px;
}

.entry-prev-next:after {
	position: absolute;
	width: 2px;
	height: 48px;
	content: "";
	background-color: #d9d9d9;
	left: 50%;
	top: 46px;
}

.entry-next,
.entry-prev {
	width: 50%;
	float: left;
}

.entry-prev-next .entry-prev {
	padding-right: 15px;
}

.entry-prev-next .entry-next {
	padding-left: 15px;
	text-align: right;
}

.entry-next-content,
.entry-prev-content {
	position: relative;
}

.entry-prev-next .entry-prev img {
	position: absolute;
	left: 0;
	top: 0;
}

.entry-prev-next .entry-next img {
	position: absolute;
	right: 0;
	top: 0;
}

.entry-prev-next .entry-prev .entry-desc {
	position: relative;
	margin-left: 65px;
}

.entry-prev-next .entry-next .entry-desc {
	position: relative;
	margin-right: 65px;
}

.entry-prev-next a {
	font-size: 14px;
	font-weight: 700;
	color: #333333;
	margin-top: 13px;
}

.entry-prev-next p {
	font-family: 'Montserrat', sans-serif;
	color: #686868;
	font-size: 11px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 10px;
}

.entry--share {
	padding: 30px 0;
	border-bottom: 2px solid #d9d9d9;
}

.entry-widget {
	background-color: #ffffff;
	margin-bottom: 40px;
	border-bottom: 2px solid #d9d9d9;
	padding-bottom: 30px;
}

.entry-widget .entry-widget-title {
	position: relative;
	margin-bottom: 30px;
}

.entry-widget .entry-widget-title:after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	width: 30px;
	height: 2px;
	background-color: #222222;
}

.entry-widget .entry-widget-title h4 {
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 0;
	padding-bottom: 10px;
}

.entry-widget .entry-widget-content {
	position: relative;
}

.entry-bio img {
	position: absolute;
	left: 0;
	top: 0;
}

.entry-bio .entry-bio-desc {
	padding-left: 120px;
}

.entry-bio .entry-bio-desc p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 8px;
}

.entry-bio .entry-bio-desc a {
	color: #5d5d5d;
	font-size: 15px;
	font-weight: 400;
	line-height: 1;
	margin-right: 25px;
}

.entry-bio .entry-bio-desc a:last-child {
	margin-right: 0;
}

.entry-bio .entry-bio-desc a:hover,
.entry-meta a:hover,
.entry-next:hover a,
.entry-prev:hover a,
.entry-related .entry:hover .entry-title h5,
.entry-share a:hover {
	color: #e7b238;
}

.entry-related .entry--img {
	margin-bottom: 20px;
}

.entry-related .entry--img img {
	max-width: 100%;
	height: auto;
}

.entry-related .entry-title {
	margin-left: 0;
}

.entry-related .entry--title h5 {
	font-size: 16px;
	font-weight: 600;
	line-height: 25px;
	margin-bottom: 10px;
}

.entry-related .entry--title a {
	color: #222222;
}

.entry-related .entry--title a:hover {
	color: #e7b238;
}

.entry-related .entry--meta {
	color: #616161;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
}

ul.comments-list {
	width: 100%;
	list-style: none;
	overflow: hidden;
	padding-left: 0;
	margin-bottom: 0;
}

ul.comments-list li {
	position: relative;
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 20px;
	margin-bottom: 30px;
}

ul.comments-list li:last-child {
	border-bottom: none;
	padding-bottom: 0;
	margin-bottom: 0;
}

ul.comments-list .comment-body .avatar {
	position: absolute;
	left: 0;
	top: 0;
}

ul.comments-list .comment-body .comment {
	padding-left: 90px;
}

ul.comments-list .comment-body .comment h6 {
	font-size: 14px;
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
	text-transform: capitalize;
	margin-bottom: 0;
	color: #333333;
	line-height: 27px;
}

ul.comments-list .comment-body .comment .date {
	color: #616161;
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 24px;
	text-transform: uppercase;
}

ul.comments-list .comment-body .comment p {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0;
}

ul.comments-list .comment-body .comment a.reply {
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.26px;
	color: #e7b238;
	line-height: 1;
	margin-top: 16px;
	display: inline-block;
}

ul.comments-list .comment a.reply:hover {
	color: #222222;
}

ul.comments-list ul.comment-children {
	list-style: none;
	margin: 20px 0 0 90px;
	padding-top: 30px;
	padding-left: 0;
	border-top: 2px solid #eeeeee;
}

ul.comments-list ul.comment-children .comment-body .comment {
	padding-left: 90px;
}

ul.comments-list ul.comment-children li:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

ul.comments-list ul.comment-children ul .comment-body .comment {
	padding-left: 90px;
}

.entry-widget.comments-form .entry-widget-title h4 {
	margin-bottom: 60px;
}

.comments-form .form-control {
	margin-bottom: 30px;
}

.entry-add-comment {
	padding-bottom: 0;
	border-bottom: none;
}

/* Blog Carousel */
.blog-carousel .carousel-dots .owl-controls {
	margin-top: 18px;
}

.blog-carousel .owl-item {
	padding: 0 15px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

ul.comments-list ul.comment-children {
	margin: 20px 0 0;
}

.blog-entry {
	padding: 0;
}

.blog-single .blog-entry .entry--title h4 {
	font-size: 17px;
	line-height: 1.2;
}

.entry--share .text-right {
	text-align: left;
}

.entry-next,
 .entry-prev {
	width: 100%;
	float: none;
	margin-bottom: 0;
}

.entry-next {
	margin-bottom: 0;
}

.entry-prev {
	margin-bottom: 30px;
}

.entry-prev-next {
	padding: 10px;
}

.entry-prev-next:after {
	display: none;
}

.entry-prev-next a {
	font-size: 13px;
}

.entry-prev-next .entry-prev {
	padding-right: 0;
}

.entry-widget {
	padding: 20px;
}

.entry-bio .entry-bio-desc p,
 .entry-bio img {
	position: relative;
	margin-bottom: 15px;
}

.entry-bio .entry-bio-desc {
	padding-left: 0;
}

.entry-related .entry {
	margin-bottom: 30px;
}

.entry-related .entry:last-of-type {
	margin-bottom: 0;
}

.blog-standard .blog-entry .entry--title h4 {
	font-size: 18px;
	line-height: 1.2;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.entry-related img {
	width: 100%;
}

.blog-standard .blog-entry .entry--title h4 {
	font-size: 20px;
	line-height: 1.2;
}
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.entry-related img {
	width: 100%;
}
}

/*------------------------------------*\
    #Shop
\*------------------------------------*/
/* Shop Cart */
.cart-table {
	margin-bottom: 50px;
}

.cart-table .table {
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	border-radius: 0;
	margin-bottom: 0;
	-webkit-box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
	-moz-box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
	box-shadow: 0 0 20px rgba(40, 40, 40, 0.12);
}

.cart-table thead {
	background-color: #f9f9f9;
}

.cart-table thead tr th {
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	text-transform: capitalize;
	color: #282828;
	line-height: 55px !important;
	border-bottom-width: 0 !important;
	border: 1px solid #eee;
	padding: 0;
}

.cart-table tbody tr td {
	padding: 20px;
	border-color: #eee;
}

.cart-table tbody tr td.cart-product-price,
.cart-table tbody tr td.cart-product-total {
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 600;
	line-height: 26px;
	color: #e7b238;
}

.cart-table tbody tr td.cart-product-quantity .product-quantity {
	line-height: 1px;
}

td.cart-product-quantity .product-quantity input {
	width: 30px;
	height: 25px;
	border: 1px solid #eee;
	border-radius: 2px;
	text-align: center;
	color: #222222;
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	margin-right: 5px;
}

td.cart-product-quantity .product-quantity i {
	width: 25px;
	height: 25px;
	line-height: 25px;
	border: 1px solid #eee;
	border-radius: 1px;
	color: #e7b238;
	margin-right: 5px;
	font-size: 10px;
}

.cart-table tbody tr td.cart-product-price,
.cart-table tbody tr td.cart-product-total,
td.cart-product-quantity {
	text-align: center;
}

.cart-table .cart-product-action td {
	padding: 30px 20px;
}

.cart-table .cart-product-action form {
	margin-bottom: 0;
}

.cart-table .cart-product-action .form-control {
	color: #9b9b9b;
	font-size: 12px;
	font-weight: 400;
	padding: 0 15px;
	width: 250px;
	height: 42px;
	line-height: 25px;
	margin-bottom: 0;
	background-color: transparent;
	border: 1px solid #eee;
	border-radius: 0;
	font-style: italic;
	text-transform: capitalize;
}

.cart-table .cart-product-action .btn {
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	margin-left: 10px;
	line-height: 39px;
	height: 42px;
	width: auto;
	text-align: center;
	padding-right: 16px;
	padding-left: 16px;
	letter-spacing: 0.26px;
}

.cart-product-img {
	float: left;
	padding-right: 20px;
}

.cart-product-name h6 {
	color: #282828;
	font-size: 14px;
	font-weight: 600;
	line-height: 27px;
	text-transform: capitalize;
}

.cart-product-remove {
	float: right;
	width: 19px;
	height: 19px;
	line-height: 19px;
	font-size: 7px;
	background-color: #000;
	border-radius: 1px;
	color: #ffffff;
	text-align: center;
	cursor: pointer;
}

.cart-product-remove:hover {
	background-color: #e7b238;
}

.cart-shiping,
.cart-total-amount {
	background-color: white;
	border: 1px solid #eeeeee;
	border-radius: 1px;
	padding: 30px 20px;
}

.cart-shiping h6,
.cart-total-amount h6 {
	color: #282828;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
	position: relative;
}

.cart-total-amount ul li {
	font-family: 'Montserrat', sans-serif;
	color: #e7b238;
	font-size: 13px;
	font-weight: 600;
	line-height: 36px;
	text-transform: capitalize;
}

.cart-total-amount ul li span {
	color: #959595;
	font-size: 13px;
	font-weight: 400;
	line-height: 36px;
}

.cart-shiping .form-control {
	background-color: transparent;
	border: 1px solid #eee;
	font-size: 14px;
	color: #959595;
	font-weight: 400;
	padding: 0 15px;
	height: 42px;
	line-height: 42px;
	margin-bottom: 20px;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	text-transform: capitalize;
}
.cart-shiping .form-control::-webkit-input-placeholder {
 color: #959595;
}
.cart-shiping .form-control:-moz-placeholder {
 color: #959595;
}
.cart-shiping .form-control::-moz-placeholder {
 color: #959595;
}
.cart-shiping .form-control:-ms-input-placeholder {
 color: #959595;
}

.cart-shiping .col-xs-12 {
	position: relative;
}

.cart-shiping i {
	position: absolute;
	font-size: 13px;
	right: 25px;
	top: 12px;
	z-index: 1;
	pointer-events: none;
	cursor: pointer;
	color: #b8b8b8;
}

.cart-shiping .btn {
	line-height: 39px;
	height: 42px;
	font-size: 13px;
	width: auto;
	padding: 0 17px;
	font-weight: 700;
	text-transform: uppercase;
}

.cart-shiping .btn:hover {
	color: #ffffff;
	background: #222222;
}

/* Shop Filter */
.shop-filter {
	text-align: center;
	margin-bottom: 36px;
}

.shop-filter li {
	padding-right: 0;
	padding-left: 0;
}

.shop-filter li a {
	color: #333333;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	margin-right: 25px;
	text-transform: capitalize;
	letter-spacing: 0.44px;
	padding-bottom: 10px;
	position: relative;
}

.shop-filter li a:after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: "";
	height: 2px;
	background-color: #e7b238;
	width: 0;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.shop-filter li:last-child a {
	margin-right: 0;
}

.shop-filter li a.active-filter,
.shop-filter li a:hover {
	color: #e7b238;
}

.shop-filter li a.active-filter::after,
.shop-filter li a:hover::after {
	width: 100%;
}

/* Product Item */
.product-item {
	background-color: #ffffff;
	margin-bottom: 20px;
	text-align: center;
}

.product-item .product--img {
	position: relative;
	margin-bottom: 0;
}

.product-item .product--img img {
	max-width: 100%;
	height: auto;
}

.product-item .product--img .product--hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(34, 34, 34, 0.95);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}

.product-item .product--img .product--hover .product--action {
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	padding: 0 40px;
	margin-top: -25px;
}

.product-item .product--img .product--hover .product--action a {
	width: 100%;
	height: 100%;
	background-color: #e7b238;
	text-align: center;
	color: #ffffff;
	border: 1px solid #e7b238;
	font-size: 13px;
	font-weight: 600;
	line-height: 51px;
	letter-spacing: 0.26px;
	text-transform: uppercase;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.35s .2s;
	-moz-transition: all 0.35s .2s;
	-o-transition: all 0.35s .2s;
	transition: all 0.35s .2s;
	display: block;
	border-radius: 0;
}

.product-item .product--img .product--hover .product--action a:hover {
	background-color: #ffffff;
	color: #e7b238;
	border-color: #ffffff;
}

.product-item .product--content {
	padding: 30px 0;
}

.product-item .product--content .product--cat {
	font-family: 'Great Vibes', cursive;
	font-size: 13px;
	font-style: italic;
	line-height: 1;
	margin-bottom: 12px;
}

.product-item .product--content .product--cat a {
	color: #3d3c3c;
}

.product-item .product--content .product--cat a:hover {
	color: #e7b238;
}

.product-item .product--content .product--type {
	font-size: 11px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: 0.22px;
	margin-bottom: 10px;
}

.product-item .product--content .product--title h3 {
	font-size: 17px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 17px;
}

.product--content .product--title a {
	color: #282828;
	margin-bottom: 10px;
	display: block;
}

.product--content .product--title a:hover {
	color: #e7b238;
}

.product--content .product--price {
	color: #e7b238;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	line-height: 1;
}

.product--img:hover .product--hover {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.product--img:hover .product--hover .product--action a {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* Shop Options */
.shop-options {
	padding: 20px;
	background-color: #ffffff;
	margin-bottom: 30px;
	height: 70px;
	border: 2px solid #eeeeee;
}

.shop-options .product-options2 span,
.shop-options .product-view-mode span {
	color: #222222;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	margin-right: 10px;
	text-transform: capitalize;
}

.shop-options .product-options2 .product-sort {
	position: relative;
	margin-right: 30px;
}

.shop-options .product-options2 .product-sort i {
	position: absolute;
	font-size: 14px;
	right: 10px;
	top: 10px;
	z-index: 1;
	pointer-events: none;
	cursor: pointer;
	color: #9b9b9b;
}

.shop-options .product-options2 select {
	height: 30px;
	line-height: 30px;
	width: 134px;
	border: 1px solid #eeeeee;
	background-color: rgba(246, 246, 247, 0);
	border-radius: 1px;
	color: #9b9b9b;
	font-size: 12px;
	font-weight: 400;
	padding-left: 10px;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.shop-options .product-view-mode {
	line-height: 30px;
}

.shop-options .product-view-mode a i {
	background-color: transparent;
	border: 1px solid #eee;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	color: #9b9b9b;
	font-size: 14px;
	font-weight: 400;
	margin-left: 10px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
}

.shop-options .product-view-mode a:first-of-type i {
	margin-left: 0;
}

.shop-options .product-view-mode a.active i,
.shop-options .product-view-mode a:hover i {
	color: #e7b238;
}

.product-list {
	width: 100%;
	text-align: left;
	min-height: 340px;
	margin-bottom: 0;
}

.product-list .product--img {
	float: left;
	width: 33%;
}

.product-list .product--content {
	padding-left: 300px;
}

/* Shop Single */
.shop-product .product-title h3 {
	font-size: 20px;
	font-weight: 600;
	line-height: 27px;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.shop-product .product-price {
	font-family: 'Montserrat', sans-serif;
	color: #e7b238;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 25px;
}

.shop-product .product-rating {
	margin-right: 30px;
}

.shop-product .product-rating i {
	color: #e7b238;
	font-size: 15px;
	font-weight: 400;
	line-height: 1;
}

.shop-product .product-review span {
	color: #9b9b9b;
	font-size: 13px;
	line-height: 1;
	font-weight: 400;
}

.shop-product .product-review span a {
	color: #9b9b9b;
}

.shop-product .product-review span a:hover {
	color: #e7b238;
}

.shop-product .product-desc p {
	color: #959595;
	font-size: 13px;
	line-height: 22px;
	margin-bottom: 25px;
}

.shop-product hr {
	opacity: 0.7;
	border-top: 1px solid rgba(229, 228, 228, 0.7);
	margin-top: 0;
	margin-bottom: 30px;
}

.shop-product .product-details h5 {
	color: #333333;
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 20px;
	position: relative;
}

.shop-product .product-details ul li {
	line-height: 26px;
	color: #e7b238;
	font-size: 13px;
	font-weight: 400;
	text-transform: capitalize;
}

.shop-product .product-details ul li span {
	color: #9b9b9b;
	font-size: 13px;
	font-weight: 400;
}

.shop-product .product-quantity {
	line-height: 49px;
}

.shop-product .product-quantity .qua {
	font-family: 'Montserrat', sans-serif;
	color: #333333;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	margin-right: 10px;
}

.shop-product .product-quantity a i {
	width: 25px;
	height: 25px;
	font-size: 10px;
	line-height: 25px;
	border: 1px solid #eee;
	border-radius: 1px;
	color: #e7b238;
	text-align: center;
}

.shop-product .product-quantity input {
	width: 30px;
	height: 25px;
	border: 1px solid #eee;
	border-radius: 1px;
	color: #222222;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	text-align: center;
	background-color: transparent;
}

.shop-product .product-cta {
	padding-bottom: 30px;
}

.shop-product .product-cta .btn {
	margin-left: 20px;
	width: 134px;
	height: 42px;
	line-height: 41px;
}

.shop-product .product-share {
	margin-bottom: 70px;
}

.shop-product .product-share h5 {
	color: #333333;
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 20px;
	position: relative;
	text-transform: capitalize;
}

.shop-product .product-share a {
	width: 34px;
	height: 34px;
	line-height: 34px;
	border-radius: 1px 2px 2px 1px;
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
	text-align: center;
	display: inline-block;
	background-color: #e7b238;
	margin-right: 3px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

.shop-product .product-share a {
	background-color: #e7b238;
}

.shop-product .product-share a.share-facebook:hover {
	background-color: #3B5998;
}

.shop-product .product-share a.share-twitter:hover {
	background-color: #00ACEE;
}

.shop-product .product-share a.share-google-plus:hover {
	background-color: #DD4B39;
}

.shop-product .product-share a.share-linkedin:hover {
	background-color: #0E76A8;
}

.product-tabs {
	background-color: #ffffff;
	border: 1px solid #eee;
	border-radius: 1px;
	color: #9b9b9b;
	font-size: 13px;
	font-weight: 500;
	line-height: 22px;
	font-style: italic;
	margin-bottom: 50px;
}

.product-tabs .nav-tabs {
	background-color: #f9f9f9;
}

.product-tabs .nav-tabs > li {
	margin-bottom: -1px;
}

.product-tabs .nav-tabs > li > a {
	color: #282828 !important;
	font-size: 15px;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	text-transform: capitalize;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 20px;
	border-right: 1px solid #eeeeee;
	margin-right: 0;
	line-height: 60px;
	height: 60px;
}

.product-tabs .nav-tabs > li:last-child > a {
	border-right: none;
}

.product-tabs .nav-tabs > li > a:focus,
.product-tabs .nav-tabs > li > a:hover {
	background-color: transparent;
	border-left-color: transparent;
	border-top-color: transparent;
	border-bottom-color: transparent;
	color: #e7b238 !important;
}

.product-tabs .nav-tabs > li.active > a,
.product-tabs .nav-tabs > li.active > a:focus,
.product-tabs .nav-tabs > li.active > a:hover {
	color: #e7b238 !important;
	border: none;
	border-right: 1px solid #eee;
	background-color: #ffffff;
}

.product-tabs .tab-content {
	padding: 20px;
}

.product-tabs .tab-content h5 {
	font-size: 13px;
}

.product-review li {
	background-color: #f9f9f9;
	padding: 20px;
	margin-bottom: 30px;
}

.product-review li h6 {
	margin-bottom: 0;
	text-transform: uppercase;
	font-size: 13px;
}

.product-review li .review-date {
	margin-bottom: 0;
}

.product-review li .product-rating {
	color: #e7b238;
	margin-bottom: 10px;
}

.product-review li .product-comment p {
	margin-bottom: 0;
}

.form-review .form-control {
	margin-bottom: 30px;
}

/* Product Related */
.shop-product .product-related-title h5 {
	color: #333333;
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	position: relative;
	text-transform: capitalize;
}

.product-num h2 {
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 700;
	line-height: 35px;
	text-transform: capitalize;
	color: #222222;
	margin-bottom: 0;
}

.product-options {
	margin-bottom: 50px;
}

.product-options .product-select {
	position: relative;
}

.product-options .product-select i {
	position: absolute;
	font-size: 14px;
	right: 25px;
	top: 12px;
	z-index: 1;
	pointer-events: none;
	cursor: pointer;
	color: #9b9b9b;
}

.product-options select {
	width: 230px;
	height: 35px;
	border: 1px solid #eeeeee;
	border-radius: 1px;
	color: #9b9b9b;
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 400;
	line-height: 35px;
	padding-left: 10px;
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

/* shop Item */
.shop-item .shop--img {
	position: relative;
}

.shop-item .shop--img img {
	max-width: 100%;
	height: auto;
}

.shop-item .shop--hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.shop-item .shop--hover .shop--action {
	width: 100%;
	height: 100%;
	background-color: rgba(40, 40, 40, 0.95);
	text-align: center;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
}

.shop-item:hover .shop--hover .shop--action {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.shop-item .shop--price {
	color: #9b9b9b;
	font-family: 'Montserrat', sans-serif;
	font-size: 22px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 30px;
}

.shop-item .shop--title h4 {
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 35px;
	text-transform: capitalize;
}

.shop-item .shop--title h4 a {
	color: #ffffff;
}

.shop-item .shop--title h4 a:hover {
	color: #e7b238;
}

.shop-item .shop--cat .btn:hover {
	background-color: #ffffff;
	color: #e7b238;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.shop-filter li {
	margin-bottom: 25px;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #CONTACT-PAGE
\*------------------------------------*/
.contact .contact--desc {
	margin-bottom: 40px;
}

.contact .contact--desc p {
	color: #a5a5a5;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
}

.contact--info h3 {
	font-size: 17px;
	font-weight: 600;
	line-height: 1;
	margin-bottom: 23px;
}

.contact--info p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
}

.contact--info p:last-of-type {
	margin-bottom: 6px;
}

.contact--info a.link--styled {
	color: #e7b238;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.contact--info a.link--styled:hover {
	color: #222222;
}

.contact--info .social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 14px;
	background-color: #1c1c1c;
	color: #ffffff;
	display: inline-block;
	margin-right: 10px;
}

.contact--info .social-icons a i {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.contact--info .social-icons a:hover {
	color: #ffffff;
	background-color: #e7b238;
}

.contact--info .social-icons a:hover i {
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 991px) {

.contact--info {
	margin-bottom: 20px;
}
}

/*------------------------------------*\
	#Widgets
\*------------------------------------*/
.sidebar {
	-moz-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
	box-shadow: 0 2px 3px rgba(40, 40, 40, 0.16);
	border-radius: 2px;
	background-color: #ffffff;
	padding: 50px;
}

.widget {
	margin-bottom: 60px;
}

.widget:last-of-type {
	margin-bottom: 0;
}

/* Widget */
.widget .widget--title {
	position: relative;
	margin-bottom: 20px;
}

.widget .widget--title h5 {
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	margin-bottom: 0;
	position: relative;
	padding-bottom: 10px;
}

.widget .widget--title:after {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	width: 30px;
	height: 2px;
	background-color: #222222;
}

/* Category Widget */
.widget-categories ul li {
	border-bottom: 1px solid #eeeeee;
}

.widget-categories ul li:last-child {
	border-bottom: none;
}

.widget-categories ul li a {
	color: #333333;
	font-size: 13px;
	font-weight: 600;
	line-height: 40px;
	position: relative;
	display: block;
}

.widget-categories ul li a:after {
	position: absolute;
	top: 0;
	right: 0;
	content: "\f178";
	color: #333333;
	font-family: fontawesome;
	font-size: 11px;
	font-weight: 400;
	line-height: 40px;
}

.widget-categories ul li a:hover,
.widget-categories ul li a:hover:after {
	color: #e7b238;
}

.widget-categories ul li a span {
	margin-left: 5px;
	color: #e7b238;
}

/* Tags Widget */
.widget-tags ul {
	margin-bottom: 0;
	margin-left: 0;
}

.widget-tags ul li {
	padding-left: 0;
	padding-right: 0;
}

.widget-tags a {
	display: inline-block;
	border: 1px solid #ececec;
	background-color: transparent;
	color: #a5a5a5;
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
	margin-right: 7px;
	margin-bottom: 10px;
	text-transform: capitalize;
	padding: 9px 10px;
}

.widget-tags a:hover {
	background-color: #e7b238;
	color: #ffffff;
	border-color: #e7b238;
}

/* Recent Widegt */
.widget-recent-posts .entry {
	position: relative;
	margin-bottom: 24px;
	min-height: 50px;
	border-bottom: 1px dashed #d8d8d8;
}

.widget-recent-posts .entry:last-of-type {
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
}

.widget-recent-posts img {
	position: absolute;
	left: 0;
	top: 0;
	width: 49px;
	height: 49px;
	text-align: center;
}

.widget-recent-posts .entry .entry-desc {
	position: relative;
	margin-left: 60px;
	padding-bottom: 10px;
}

.widget-recent-posts .entry:last-of-type .entry-desc {
	border-bottom: none;
	padding-bottom: 0;
}

.widget-recent-posts .entry .entry-title {
	margin-bottom: 5px;
}

.widget-recent-posts .entry .entry-title a {
	color: #333333;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: capitalize;
}

.widget-recent-posts .entry .entry-title a:hover {
	color: #e7b238;
}

.widget-recent-posts .entry .entry-desc .entry-meta {
	line-height: 1;
	margin-bottom: 12px;
}

.widget-recent-posts .entry .entry-desc .entry-meta a {
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 1;
	color: #686868;
	text-transform: uppercase;
	letter-spacing: 0.22px;
}

/* Search Widget */
.widget-search .form-search {
	border: 1px solid rgba(34, 34, 34, 0.07);
	background-color: rgba(255, 255, 255, 0);
}

.widget-search .form-search .form-control {
	border: none;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #9e9e9e;
	font-size: 13px;
	font-weight: 400;
	line-height: 49px;
	height: 49px;
	text-transform: capitalize;
	padding-left: 15px;
}

.widget-search .form-search .btn {
	background-color: transparent;
	color: #e7b238;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
	width: 35px;
	height: 49px;
	line-height: 29px;
	text-align: center;
	font-size: 14px;
	margin-left: 0;
	padding: 0;
}

/* Best Products */
.widget-best-product .best-product .product-img {
	width: 49px;
	height: 49px;
	text-align: center;
	padding: 4px;
}

.widget-best-product .best-product .product-bio {
	padding-top: 0;
}

.widget-best-product .best-product .product-bio .product-price {
	color: #9b9b9b;
	font-size: 14px;
	font-weight: 600;
	line-height: 23px;
}

.widget-best-product .best-product a {
	text-transform: uppercase;
}

/* fiter product */
.ui-slider {
	position: relative;
	text-align: left;
}

.ui-slider .ui-slider-handle {
	position: absolute;
	z-index: 2;
	width: 1.2em;
	height: 1.2em;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}

.ui-slider .ui-slider-range {
	position: absolute;
	z-index: 1;
	font-size: 0.7em;
	display: block;
	border: 0;
	background-position: 0 0;
}

/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
	filter: inherit;
}

.ui-slider-horizontal .ui-slider-handle {
	top: -0.2em;
	margin-left: -0.6em;
}

.ui-slider-horizontal .ui-slider-range {
	top: 0;
	height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
	left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
	right: 0;
}

.ui-slider-vertical {
	width: 0.8em;
	height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
	left: -0.3em;
	margin-left: 0;
	margin-bottom: -0.6em;
}

.ui-slider-vertical .ui-slider-range {
	left: 0;
	width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
	bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
	top: 0;
}

.widget-filter .ui-slider {
	height: 4px;
	background-color: rgba(34, 34, 34, 0.06);
	border-radius: 0;
	margin-bottom: 20px;
	margin-top: 5px;
}

.widget-filter .ui-slider-handle {
	/* Style for "Line" */
	width: 9px;
	height: 9px;
	margin-left: 0;
	background-color: #222222;
	border-radius: 0;
}

.widget-filter .ui-slider-range {
	height: 4px;
	background-color: #e7b238;
	margin-bottom: 20px;
}

.widget-filter p {
	font-size: 13px;
	font-weight: 400;
	line-height: 1;
	margin-bottom: 19px;
}

.widget-filter label {
	color: #9b9b9b;
	font-weight: 400;
}

.widget-filter input {
	color: #e7b238;
	border: none;
	background-color: transparent;
}

.widget-filter .btn {
	width: 108px;
	height: 42px;
	line-height: 40px;
	font-size: 13px;
	font-weight: 500;
}

/* ADS widget */
.ads-widget img {
	max-width: 100%;
	height: auto;
}

/* Recent Product Widegt */
.widget-recent-products .product {
	position: relative;
	margin-bottom: 24px;
	min-height: 50px;
	border-bottom: 1px dashed #dedede;
}

.widget-recent-products .product:last-of-type {
	margin-bottom: 0;
	border-bottom: none;
	padding-bottom: 0;
}

.widget-recent-products .product img {
	position: absolute;
	left: 0;
	top: 0;
}

.widget-recent-products .product .product-desc {
	position: relative;
	margin-left: 65px;
	padding-bottom: 18px;
}

.widget-recent-products .product:last-of-type .product-desc {
	padding-bottom: 0;
}

.widget-recent-products .product .product-title {
	margin-bottom: 5px;
}

.widget-recent-products .product .product-title a {
	color: #333333;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: capitalize;
}

.widget-recent-products .product .product-title a:hover {
	color: #e7b238;
}

.widget-recent-products .product .product-desc .product-meta {
	font-family: 'Montserrat', sans-serif;
	color: #e7b238;
	font-size: 13px;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 8px;
}

/* Info Widget */
.widget-info {
	background-color: #222222;
	padding: 50px 36px;
	text-align: center;
	position: relative;
}

.widget-info .info--title {
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 25px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 20px;
	position: relative;
}

.widget-info .info--content {
	color: #9b9b9b;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	font-style: italic;
	margin-bottom: 23px;
}

/* Widget Instagram */
.widget-instagram a {
	width: 61px;
	height: 61px;
	float: left;
	display: inline-block;
	margin: 2px;
	position: relative;
}

.widget-instagram a img {
	max-width: 100%;
	height: auto;
}

.widget-instagram .insta-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.widget-instagram .insta-hover i {
	display: block;
	border-radius: 1px;
	background-color: #e7b238;
	color: #ffffff;
	font-size: 10px;
	font-weight: 400;
	line-height: 61px;
	text-align: center;
}

.widget-instagram a:hover .insta-hover {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* Section Instagram */
.instasection {
	height: 168px;
	overflow: hidden;
	position: relative;
}

.instasection [class*="divider-"] {
	z-index: 3;
}

.instasection .divider--shape-dark {
	top: auto;
	bottom: 0;
}

.instasection a {
	width: 168px;
	height: 168px;
	float: left;
	display: inline-block;
	margin: 0;
	position: relative;
}

.instasection a img {
	max-width: 100%;
	height: auto;
}

.instasection .insta-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	padding: 30px;
}

.instasection .insta-hover i {
	display: block;
	border-radius: 1px;
	background-color: rgba(34, 34, 34, 0.8);
	color: #ffffff;
	font-size: 28px;
	font-weight: 400;
	line-height: 108px;
	text-align: center;
}

.instasection a:hover .insta-hover {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

/* Widget Twitter  */
.widget-tweets ul li:before {
	content: "\f099";
	font-family: fontawesome;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #e7b238;
	width: 14px;
	height: 12px;
	float: left;
	margin-right: 10px;
}

.widget-tweets ul li p.tweet {
	color: #9b9b9b;
	font-weight: 600;
	font-size: 13px;
	line-height: 22px;
	margin-bottom: 5px;
}

.widget-tweets ul li a {
	color: #e7b238;
	font-weight: 400;
}

.widget-tweets ul li a:hover {
	color: #e7b238;
}

.widget-tweets ul li .timePosted {
	color: #9b9b9b;
	font-size: 11px;
	font-weight: 400;
	line-height: 20px;
}

.widget-tweets ul li:last-child .timePosted {
	margin-bottom: 0;
}

.widget-tweets ul li .timePosted a {
	color: #9b9b9b;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.sidebar {
	margin-bottom: 30px;
	padding: 30px;
}

.widget {
	margin-bottom: 30px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.widget-recent .entry .entry-desc {
	margin-left: 70px;
}
}

/*------------------------------------*\
    #Footer
\*------------------------------------*/
footer {
	background: #222222;
	padding-top: 95px;
	padding-bottom: 0;
	position: relative;
}

footer .footer--widget-content h3 {
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 30px;
	text-transform: uppercase;
}

footer .footer--widget-content p {
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
	color: #e7b238;
}

footer .footer--widget-content a {
	font-size: 13px;
	font-weight: 700;
	line-height: 49px;
	text-transform: uppercase;
	color: #e7b238;
}

footer .footer--widget-content img.footer-logo {
	width: 180px;
	height: 28px;
}

footer .footer--widget-content a:hover {
	color: #ffffff;
}

/* Footer Social */
footer .footer--social {
	padding: 40px 0;
}

footer .footer--social-icons a {
	width: 42px;
	height: 42px;
	line-height: 42px;
	text-align: center;
	background-color: #1c1c1c;
	font-size: 14px;
	font-weight: 400;
	color: #ffffff;
	display: inline-block;
	margin-right: 10px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
}

footer .footer--social-icons a:last-of-type {
	margin-right: 0;
}

footer .footer--social-icons a:hover {
	background-color: #e7b238;
}

footer .footer--social-icons a:hover i {
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}

footer .footer--social-newsletter {
	position: relative;
	margin: 0 auto;
	width: 270px;
}

footer .footer--social-newsletter .form-control {
	box-shadow: none;
	border: none;
	background-color: #1c1c1c;
	color: #474747;
	font-style: italic;
	width: 270px;
	height: 42px;
	line-height: 42px;
	margin: 0;
}
footer .footer--social-newsletter .form-control::-webkit-input-placeholder {
 color: #474747;
}
footer .footer--social-newsletter .form-control::-moz-placeholder {
 color: #474747;
}
footer .footer--social-newsletter .form-control::-ms-input-placeholder {
 color: #474747;
}

footer .footer--social-newsletter button {
	background-color: transparent;
	border: none;
	line-height: 42px;
	color: #e7b238;
	position: absolute;
	right: 10px;
	top: 0;
	margin-bottom: 0;
	font-size: 13px;
	text-transform: uppercase;
}

/* Footer Copyrights */
footer .footer--copyright {
	background-color: #1b1b1b;
	padding: 42px 0;
	position: relative;
}

.footer--copyright span {
	font-size: 13px;
}

.footer--copyright a {
	color: #e7b238;
}

/* Footer Contact */
.footer--copyright .contact-info,
.footer--copyright .site-meta {
	margin-bottom: 10px;
}

.footer--copyright .contact-info span,
.footer--copyright .site-meta span {
	font-size: 13px;
	margin-right: 5px;
	margin-left: 5px;
}

footer .widget-instagram a {
	width: 60px;
	height: 60px;
}

footer .widget-instagram .insta-hover i {
	line-height: 60px;
}

.widget-reservations li {
	border-bottom: 1px dashed rgba(187, 147, 86, 0.7);
	line-height: 40px;
	font-size: 13px;
	text-transform: capitalize;
}

.widget-reservations li:last-child {
	border-bottom: none;
}

.widget-reservations li span {
	color: #686868;
}

/* Footer #1 */
.footer-1 .footer--widget {
	padding-bottom: 106px;
}

/* Footer #2 */
.footer-2 .footer--widget {
	padding-bottom: 70px;
}

.footer-2 .footer--copyright {
	background-color: transparent;
	padding: 0;
}

.footer-2 .footer--copyright .col-xs-12 {
	border-top: 1px dashed #3a3a3a;
	padding: 40px 0;
}

/* Footer #3 */
.footer-3 .footer--widget {
	padding-bottom: 76px;
}

.footer-3 .footer--social-icons a {
	margin-right: 7px;
}

/* Footer #4 */
.footer-4 .footer--widget {
	padding-bottom: 100px;
}

.footer-4 .footer--social-icons a {
	margin-right: 7px;
}

/* Footer #5 */
.footer-5 .footer--widget {
	padding-bottom: 20px;
}

.footer-5 .footer--social-icons a {
	margin-right: 7px;
}

.footer-5 .footer--copyright {
	padding: 46px 0;
	background-color: transparent;
}

/* Footer #6 */
.footer-6 {
	padding-top: 0;
}

.footer--copyright .footer--social-icons a {
	width: auto;
	height: auto;
	line-height: 1;
	background-color: transparent;
	font-size: 14px;
}

.footer--copyright .footer--social-icons a:hover {
	background-color: transparent;
	color: #e7b238;
}

/* Footer #8 */
.footer-8 {
	padding-top: 0;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

footer {
	padding-top: 65px;
}

.footer--widget-content {
	margin-bottom: 30px;
}

.footer-1 .footer--widget {
	padding-bottom: 75px;
}

footer .footer--copyright {
	padding: 22px 0;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

footer {
	padding-top: 65px;
}

footer .footer--social-icons a {
	width: 36px;
	height: 36px;
	line-height: 36px;
	margin-right: 5px;
}

footer .footer--social-newsletter,
 footer .footer--social-newsletter .form-control {
	width: 100%;
}

.footer-1 .footer--widget {
	padding-bottom: 75px;
}

footer .footer--copyright {
	padding: 22px 0;
}
}

/*------------------------------------*\
    #404
\*------------------------------------*/
.page-404 h3 {
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 250px;
	font-weight: 700;
	line-height: 1.1;
	margin-bottom: 0;
}

.page-404 p {
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 479px) {

.page-404 {
	padding-top: 150px !important;
	padding-bottom: 150px !important;
}

.page-404 h3 {
	font-size: 150px;
}

.page-404.fullscreen {
	height: auto !important;
}
}

@media only screen and (min-width: 480px) and (max-width: 1200px) {

.page-404 {
	padding-top: 150px !important;
	padding-bottom: 150px !important;
}

.page-404 h3 {
	font-size: 150px;
}

.page-404.fullscreen {
	height: 100vh !important;
}
}

/*------------------------------------*\
    #SOON
\*------------------------------------*/
.page-soon {
	padding-top: 120px;
	padding-bottom: 120px;
}

.page-soon h3 {
	font-family: 'Montserrat', sans-serif;
	color: #222222;
	font-size: 120px;
	font-weight: 700;
	line-height: 1.1;
	margin-bottom: 30px;
}

.page-soon p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 70px;
}

.count-down {
	padding-top: 110px;
	padding-bottom: 110px;
}

/* jQuery Countdown styles 2.0.0. */
.countdown-rtl {
	direction: rtl;
}

.countdown-section {
	display: block;
	float: left;
	text-align: center;
	position: relative;
	height: auto;
	margin-right: 26px;
	width: 18%;
	padding: 0 23px;
}

.countdown-section:last-of-type {
	margin-right: 0;
}

.countdown-amount {
	font-family: 'Montserrat', sans-serif;
	color: #ffffff;
	font-size: 70px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 20px;
}

.countdown-period {
	font-family: 'Montserrat', sans-serif;
	display: block;
	color: #f9f9f9;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	text-transform: capitalize;
	text-align: center;
	letter-spacing: 0.33px;
	margin-top: 10px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 480px) {

.countdown-section {
	width: 40%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.page-soon h3 {
	font-size: 45px;
	margin-top: 100px;
}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {

.countdown-section {
	width: 16%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.countdown-amount {
	font-size: 50px;
}

.page-soon h3 {
	font-size: 45px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.countdown-section {
	width: 17%;
}

.countdown-amount {
	font-size: 60px;
}

.page-soon h3 {
	font-size: 90px;
}
}

@media only screen and (max-width: 991px) {

.page-soon {
	padding-top: 100px !important;
	padding-bottom: 100px !important;
}
}

/*------------------------------------*\
    #MAINTENANCE
\*------------------------------------*/
.page-maintenance {
	padding-top: 150px;
	padding-bottom: 150px;
}

.page-maintenance h3 {
	font-family: 'Montserrat', sans-serif;
	color: #222222;
	font-size: 120px;
	font-weight: 700;
	line-height: 1.1;
	margin-bottom: 40px;
}

.page-maintenance p {
	color: #f9f9f9;
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 30px;
}

.maintenance-fullscreen.skills .progress-title .title {
	color: #ffffff;
}

.maintenance-fullscreen.skills .progress-bar {
	background-color: #f9f9f9;
}

.maintenance-fullscreen.skills .progress-title .value {
	color: #f9f9f9;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.page-maintenance {
	padding-top: 150px !important;
	padding-bottom: 100px !important;
	height: 100vh !important;
}

.page-maintenance h3 {
	font-size: 45px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.page-maintenance {
	padding-top: 200px !important;
	padding-bottom: 100px !important;
	height: 100vh !important;
}
}

/*------------------------------------*\
    #Client
\*------------------------------------*/
.client img {
	max-width: 100%;
	width: auto !important;
}

/* Client #1 */
.clients-1 .row-clients .client {
	position: relative;
	height: 135px;
	margin-bottom: 15px;
	line-height: 135px;
	padding: 20px;
}

.clients-1 .row-clients .client img {
	opacity: .8;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	-ms-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}

.clients-1 .row-clients .client:hover img {
	opacity: 1;
}

.clients-1 .row-clients .client:before {
	position: absolute;
	content: "";
	right: 0;
	top: 0;
	width: 1px;
	height: 121px;
	background-color: rgba(206, 206, 206, 0.5);
}

.clients-1 .row-clients .client:after {
	position: absolute;
	content: "";
	bottom: 0;
	left: 14px;
	width: 90%;
	height: 1px;
	background-color: rgba(206, 206, 206, 0.5);
}

.clients-1.bg-overlay bg-parallax .row-clients .client:before {
	background-color: rgba(206, 206, 206, 0.3);
}

.clients-1.bg-overlay bg-parallax .row-clients .client:after {
	background-color: rgba(206, 206, 206, 0.3);
}

.clients-carousel [class*="col-"] {
	max-height: 50px;
}

.clients-1 .row-clients .client:last-child:before {
	display: none;
}

.clients-1 .row-clients:last-child .client:after {
	display: none;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.client img {
	margin-bottom: 20px;
}
}

.clients-2 {
	padding: 40px 0;
}

/*------------------------------------*\
    #Skills
\*------------------------------------*/
.skills .progressbar {
	margin-bottom: 37px;
}

.skills .progressbar:last-of-type {
	margin-bottom: 0;
}

.skills .progress {
	height: 9px;
	background-color: #282828;
	border-radius: 2px;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 0;
	width: 100%;
	margin-bottom: 0;
}

.skills .progress-bar {
	height: 3px;
	margin: 3px 0 0 3px;
	background-color: #e7b238;
	border-radius: 0;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 0;
}

.skills .progress-title {
	display: block;
	position: relative;
	width: 100%;
	margin-bottom: 8px;
}

.skills .progress-title .title {
	color: #282828;
	font-size: 13px;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 10px;
	text-transform: uppercase;
}

.skills .progress-title .value {
	float: right;
	font-size: 12px;
	font-weight: 600;
	line-height: 28px;
	text-transform: uppercase;
	padding-top: 0;
}

/*------------------------------------*\
	#Video
\*------------------------------------*/
.video-button .heading--title {
	font-family: 'Great Vibes', cursive;
	font-size: 40px;
	font-weight: 400;
	line-height: 1;
	margin-top: 30px;
	margin-bottom: 0;
}

.video--content .video--player {
	width: 68px;
	height: 68px;
	line-height: 65px;
	color: #ffffff;
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
	display: inline-block;
	position: relative;
	background-color: #222222;
	border-radius: 100%;
}

.video--content .video--player:hover {
	background-color: #e7b238;
}

.video--content .video--player a {
	line-height: 68px;
	display: block;
}

.video--content .video--player i {
	font-size: 48px;
	color: #ffffff;
	line-height: 68px;
}

/* Video Button #1 */
.video-button-1 {
	padding: 210px 0;
}

/* Video Button #2 */
.video-button-2 .video--content {
	width: 100%;
	height: 383px;
}

.video-button-2 .video--content .video--player i {
	font-size: 32px;
}

.video-button-2 .video--content .video--player.video--player-bordered {
	font-size: 32px;
	border: 2px solid #eeeeee;
	background-color: transparent;
}

.video-button-2 .video--content .video--player.video--player-bordered i {
	line-height: 67px;
}

.video-button-2 .video--content .video--player:hover {
	background-color: #ffffff;
	color: #222222;
}

.video-button-2 .video--content .video--player:hover i {
	color: #222222;
}

/*------------------------------------*\
    #Carousel
\*------------------------------------*/
.carousel-dots .owl-controls {
	margin-top: 40px;
}

.carousel-dots .owl-controls .owl-dots .owl-dot span {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #333333;
	border: 2px solid #ffffff;
	margin: 0 4px;
}

.carousel-dots .owl-controls .owl-dots .owl-dot.active span {
	-moz-box-shadow: 0 0 0 2px #333333;
	-webkit-box-shadow: 0 0 0 2px #333333;
	box-shadow: 0 0 0 2px #333333;
	border-width: 3px;
}

.carousel-dots.carousel-white .owl-controls .owl-dots .owl-dot span {
	background-color: #fff;
	border-color: #fff;
}

.carousel-dots.carousel-white .owl-controls .owl-dots .owl-dot.active span {
	-moz-box-shadow: 0 0 0 2px #fff;
	-webkit-box-shadow: 0 0 0 2px #fff;
	box-shadow: 0 0 0 2px #fff;
	border-color: #333;
}

.carousel-navs .owl-controls .owl-nav {
	position: relative;
}

.carousel-navs .owl-controls .owl-nav [class*=owl-] {
	position: absolute;
	top: 50%;
	height: 60px;
	line-height: 60px;
	color: #222222;
	font-size: 0;
	font-weight: 400;
	text-align: center;
	background-color: transparent;
	padding: 0;
	border-radius: 0;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	margin-top: -250px;
}

.carousel-navs .owl-controls .owl-nav [class*=owl-]:hover {
	color: #e7b238;
	background-color: transparent;
}

.carousel-navs .owl-controls .owl-nav .owl-prev {
	left: 0;
}

.carousel-navs .owl-controls .owl-nav .owl-prev:before {
	font-family: 'Linearicons-Free';
	content: "\e875";
	font-size: 40px;
}

.carousel-navs .owl-controls .owl-nav .owl-next {
	right: 0;
}

.carousel-navs .owl-controls .owl-nav .owl-next:before {
	font-family: 'Linearicons-Free';
	content: "\e876";
	font-size: 40px;
}

.carousel-navs .owl-dots {
	position: absolute;
	bottom: 20px;
	width: 100%;
}

.carousel-navs .owl-dots .owl-dot span {
	background-color: transparent;
	border: 1px solid #fff;
}

.carousel-navs .owl-dots .owl-dot.active span,
.carousel-navs .owl-dots .owl-dot:hover span {
	background: #ffffff;
}

/**/
.slider-dots .owl-controls {
	margin-top: 0;
}

.slider-dots .owl-controls .owl-dots {
	position: absolute;
	bottom: 30px;
	width: 100%;
}

.slider-dots .owl-controls .owl-dots .owl-dot span {
	height: 11px;
	width: 11px;
	border-radius: 50%;
	background-color: #ffffff;
	border: 2px solid rgba(0, 0, 0, 0.6);
	margin: 0 4px;
}

.slider-dots .owl-controls .owl-dots .owl-dot.active span {
	-moz-box-shadow: 0 0 0 2px #3d3c3c;
	-webkit-box-shadow: 0 0 0 2px #3d3c3c;
	box-shadow: 0 0 0 2px #ffffff;
}

.slider-navs .owl-controls .owl-nav [class*=owl-] {
	position: absolute;
	top: 50%;
	height: 60px;
	width: 60px;
	line-height: 60px;
	margin-top: -30px;
	color: #ffffff;
	font-size: 0;
	font-weight: 400;
	background-color: rgba(27, 26, 26, 0.5);
	text-align: center;
	padding: 0;
	border-radius: 0;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.slider-navs .owl-controls .owl-nav [class*=owl-]:hover {
	color: #999999;
	background-color: #ffffff;
}

.slider-navs .owl-controls .owl-nav .owl-prev {
	left: 30px;
}

.slider-navs .owl-controls .owl-nav .owl-prev:before,
.slider-navs .owl-controls .owl-nav .owl-next:before {
	font-family: fontawesome;
	font-size: 25px;
}

.slider-navs .owl-controls .owl-nav .owl-prev:before {
	content: "\f104";
}

.slider-navs .owl-controls .owl-nav .owl-next {
	right: 30px;
}

.slider-navs .owl-controls .owl-nav .owl-next:before {
	content: "\f105";
}

.slider-navs .owl-dots {
	position: absolute;
	bottom: 20px;
	width: 100%;
}

.slider-navs .owl-dots .owl-dot span {
	background-color: transparent;
	border: 1px solid #fff;
}

.slider-navs .owl-dots .owl-dot.active span,
.slider-navs .owl-dots .owl-dot:hover span {
	background: #ffffff;
}

.slider-navs.slider-shop .owl-controls .owl-dots .owl-dot span {
	background-color: #3d3c3c;
	border: 2px solid #ffffff;
}

.slider-navs.slider-shop .owl-controls .owl-dots .owl-dot.active span {
	-moz-box-shadow: 0 0 0 2px #3d3c3c;
	-webkit-box-shadow: 0 0 0 2px #3d3c3c;
	box-shadow: 0 0 0 2px #3d3c3c;
}

.slider-theme.slider-navs .owl-controls .owl-nav [class*=owl-]:hover {
	color: #222222;
}

.slider-theme.slider-dots .owl-controls .owl-dots .owl-dot span {
	border-color: #e7b238;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.carousel-dots .owl-controls {
	margin-top: 20px;
}

.slider-navs .owl-controls .owl-nav .owl-prev {
	left: 0;
}

.slider-navs .owl-controls .owl-nav .owl-next {
	right: 0;
}

.slider-navs .owl-controls .owl-nav .owl-next:before,
 .slider-navs .owl-controls .owl-nav .owl-prev:before {
	font-size: 20px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.slider-navs .owl-controls .owl-nav .owl-next:before,
 .slider-navs .owl-controls .owl-nav .owl-prev:before {
	font-size: 30px;
}
}

/*------------------------------------*\
    #Divider
\*------------------------------------*/
.section-divider {
	padding-top: 297px;
	padding-bottom: 94px;
}

.section-divider2 {
	padding-top: 297px;
	padding-bottom: 275px;
}

.section-divider3 {
	padding-top: 200px;
	padding-bottom: 200px;
}

.divider--line {
	position: relative;
	height: 2px;
}

.divider--line:after {
	position: absolute;
	content: "";
	height: 2px;
	width: 40px;
	background-color: #e7b238;
	bottom: 0;
	left: 0;
	margin: 0 auto;
}

.divider--center:after {
	left: 50%;
	margin-left: -20px;
}

.divider--medium:after {
	width: 70px;
}

.divider--center.divider--medium:after {
	margin-left: -35px;
}

.divider--line.divider--white:after {
	background-color: #ffffff;
}

.divider--line.divider--gray:after {
	background-color: #9b9b9b;
}

.section-bordered {
	border-bottom: 1px solid #f0f0f0;
}

.divider--section {
	border-top: 1px solid #f0f0f0;
	margin-top: 0;
	margin-bottom: 0;
}

/* divider Shape #1 */
.divider--shape-1down {
	width: 124px;
	height: 13px;
	background: url(../images/background/shapes/arrow-down.png) center no-repeat;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -62px;
}

.divider--shape-1down.divider--shape-white {
	background: url(../images/background/shapes/arrow-down-white.png) center no-repeat;
}

.divider--shape-1up {
	width: 124px;
	height: 13px;
	background: url(../images/background/shapes/arrow-up.png) center no-repeat;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -62px;
}

/* divider Shape #2 */
.divider--shape-2up {
	width: 100%;
	height: 28px;
	background: url(../images/background/shapes/shape-2up.png) center repeat-x;
	position: absolute;
	left: 0;
	top: 0;
}

.divider--shape-2down {
	width: 100%;
	height: 28px;
	background: url(../images/background/shapes/shape-2down.png) center repeat-x;
	position: absolute;
	bottom: 0;
	left: 0;
}

/* divider Shape #3 */
.divider--shape-3up {
	width: 100%;
	height: 9px;
	background: url(../images/background/shapes/shape-3up.png) center repeat-x;
	position: absolute;
	top: 0;
	left: 0;
}

.divider--shape-3down {
	width: 100%;
	height: 9px;
	background: url(../images/background/shapes/shape-3down.png) center repeat-x;
	position: absolute;
	bottom: 0;
	left: 0;
}

/* divider Shape #4 */
.divider--shape-4up {
	width: 100%;
	height: 4px;
	background: url(../images/background/shapes/shape-4up.png) center repeat-x;
	position: absolute;
	top: 0;
	left: 0;
}

.divider--shape-4down {
	width: 100%;
	height: 4px;
	background: url(../images/background/shapes/shape-4down.png) center repeat-x;
	position: absolute;
	bottom: 0;
	left: 0;
}

/* divider Shape #5 */
.divider--shape-5up {
	width: 100%;
	height: 12px;
	background: url(../images/background/shapes/shape-5up.png) center repeat-x;
	position: absolute;
	top: 0;
	left: 0;
}

.divider--shape-5down {
	width: 100%;
	height: 12px;
	background: url(../images/background/shapes/shape-5down.png) center repeat-x;
	position: absolute;
	bottom: 0;
	left: 0;
}

/* divider Shape #6 */
.divider--shape-6up {
	width: 122px;
	height: 24px;
	background: url(../images/background/shapes/shape-6up.png) center repeat-x;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -61px;
}

.divider--shape-6down {
	width: 122px;
	height: 24px;
	background: url(../images/background/shapes/shape-6down.png) center repeat-x;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -61px;
}

/* divider Shape #10 */
.divider--shape-4 {
	width: 100%;
	height: 16px;
	background: url(../images/background/shapes/shape-4.png) center no-repeat;
	margin-bottom: 25px;
	margin-top: 22px;
}

.divider--shape-5 {
	width: 100%;
	height: 10px;
	background: url(../images/background/shapes/shape-5.png) center no-repeat;
	margin-bottom: 25px;
	margin-top: 20px;
}

/* divider Shape #11 */
.divider--shape-theme {
	width: 92px;
	height: 20px;
	background: url(../images/background/shapes/arrow-up-theme.png) center no-repeat;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -46px;
}

/* divider Shape #11*/
.divider--shape-11 {
	width: 100%;
	height: 16px;
	background: url(../images/background/shapes/shape-11.png) center no-repeat;
}

/* divider Shape #12*/
.divider--shape-12 {
	width: 100%;
	height: 16px;
	background: url(../images/background/shapes/shape-9.png) center no-repeat;
	margin-bottom: 25px;
	margin-top: 22px;
}

/**/
.divider--shape-dark {
	width: 124px;
	height: 13px;
	background: url(../images/background/shapes/arrow-down-dark.png) center no-repeat;
	position: absolute;
	top: -13px;
	left: 50%;
	margin-left: -62px;
}

.divider--shape-dark2 {
	width: 108px;
	height: 28px;
	background: url(../images/background/shapes/arrow-down-dark2.png) center no-repeat;
	position: absolute;
	top: -22px;
	left: 50%;
	margin-left: -54px;
}

/**/
.divider--shape-10up {
	width: 124px;
	height: 13px;
	background: url(../images/background/shapes/shape-10up.png) center no-repeat;
	position: absolute;
	top: -13px;
	left: 50%;
	margin-left: -62px;
}

.divider--shape-10down {
	width: 124px;
	height: 13px;
	background: url(../images/background/shapes/shape-10down.png) center no-repeat;
	position: absolute;
	bottom: -13px;
	left: 50%;
	margin-left: -62px;
}

.divider--shape-10uptheme {
	width: 92px;
	height: 20px;
	background: url(../images/background/shapes/arrow-up-theme.png) center no-repeat;
	position: absolute;
	top: -20px;
	left: 50%;
	margin-left: -46px;
}

/* divider Shape #13 */
.divider--shape-13 {
	width: 100%;
	height: 16px;
	background: url(../images/background/shapes/shape-13.png) center no-repeat;
	margin-bottom: 25px;
	margin-top: 20px;
}

/* divider Shape #14 */
.divider--shape-14up {
	width: 92px;
	height: 20px;
	background: url(../images/background/shapes/shape-14up.png) center no-repeat;
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -46px;
}

.divider--shape-14down {
	width: 92px;
	height: 20px;
	background: url(../images/background/shapes/shape-14down.png) center no-repeat;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -46px;
}

/* divider Shape #14 */
.divider--shape-left {
	width: 13px;
	height: 124px;
	background: url(../images/background/shapes/shape-left.png) center no-repeat;
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -62px;
}

.divider--shape-right {
	width: 13px;
	height: 124px;
	background: url(../images/background/shapes/shape-right.png) center no-repeat;
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -62px;
}

.divider--shape-header {
	width: 100%;
	height: 9px;
	background: url(../images/background/shapes/shape-header.png) center repeat-x;
	position: absolute;
	left: 0;
	bottom: -9px;
}

/*------------------------------------*\
    #Number Process
\*------------------------------------*/
.number-panel {
	position: relative;
}

.number-panel .number--img {
	margin-bottom: 30px;
}

.number-panel .number--img img {
	max-width: 100%;
	height: auto;
}

.number-panel .number--content h6 {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 13px;
	font-style: italic;
	line-height: 1;
	margin-bottom: 14px;
	position: relative;
	z-index: 2;
}

.number-panel .number--content h3 {
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	color: #282828;
	margin-bottom: 21px;
}

.number-panel .number--content p {
	color: #9b9b9b;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 0;
}

.number-panel .number--content a {
	color: #e7b238;
	font-size: 13px;
	font-weight: 600;
	line-height: 1;
	padding-bottom: 4px;
	text-transform: uppercase;
	position: relative;
	margin-top: 26px;
	display: inline-block;
}

.number-panel .number--content a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 22px;
	height: 2px;
	background-color: #e7b238;
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
}

.number-panel:hover .number--content a:after {
	width: 100%;
}

.number-panel .num {
	position: absolute;
	left: 0;
	top: -32px;
	color: #efefef;
	font-size: 65px;
	font-weight: 600;
	line-height: 1;
}

/* Number #1 */
.number-1 .number-panel .number--content h3 span {
	color: #e7b238;
	position: relative;
	padding-bottom: 3px;
}

.number-1 .number-panel .number--content h3 span:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: #dd543d;
}

/* Number #2 */
.number-2 .number-panel .number--content h3 {
	margin-bottom: 15px;
}

/* Number #3 */
.number-3 .number-panel .num {
	left: auto;
	right: 0;
	top: -5px;
}

/* Number White */
.number-white .number-panel {
	padding: 76px 50px 57px 50px;
}

.number-white .number-panel .number--content h6 {
	color: #f9f9f9;
}

.number-white .number-panel .number--content h3 {
	color: #ffffff;
	position: relative;
	padding-bottom: 16px;
}

.number-white .number-panel .number--content h3:before {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	width: 30px;
	height: 2px;
	background-color: #ffffff;
}

.number-white .number-panel .number--content p {
	color: #ffffff;
}

.number-white .number-panel .number--content a {
	color: #ffffff;
}

.number-white .number-panel .number--content a:after {
	background-color: #ffffff;
}

.number-white .number-panel .num {
	position: absolute;
	left: 49px;
	top: 31px;
	color: rgba(239, 239, 239, 0.05);
	font-size: 65px;
	font-weight: 700;
	line-height: 1;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.number-panel {
	margin-bottom: 30px;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #Side Nav
\*------------------------------------*/
/* Header Fixed Left */
.header-fixed-side {
	width: 270px;
	position: fixed;
	border-radius: 0;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #ffffff;
	webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	text-align: center;
}

.header-fixed-side .header--panel {
	width: 100%;
	height: 100%;
}

.header-fixed-side .navbar-header {
	float: none;
}

.header-fixed-side .header--panel-logo {
	width: 100%;
	display: block;
	text-align: center;
	padding: 30px 30px 30px 0;
	margin-bottom: 80px;
}

.header-fixed-side .header--panel .navbar-nav {
	margin-bottom: 0;
	padding: 30px;
	margin: 0;
	float: none;
}

.header-fixed-side .header--panel .navbar-nav > li {
	margin-right: 0;
	float: none;
	text-align: left;
	border-bottom: 1px solid rgba(51, 51, 51, 0.07);
}

.header-fixed-side .header--panel .navbar-nav > li:last-child {
	border-bottom: none;
}

.header-fixed-side .navbar-nav a {
	font-size: 13px;
	font-weight: 800;
	font-family: 'Montserrat', sans-serif;
	line-height: 39px;
	text-transform: uppercase;
	color: #333333;
	margin-bottom: 0;
	position: relative;
}

.header-fixed-side .header--panel .navbar-nav li:last-child a {
	margin-bottom: 0;
}

.header-fixed-side .header--panel .navbar-nav a:hover {
	color: #e7b238;
}

.header--panel-footer {
	position: absolute;
	bottom: 30px;
	width: 100%;
	padding-left: 45px;
}

.header-fixed-side .header--panel .header--panel-social {
	margin-bottom: 40px;
}

.header-fixed-side .header--panel .header--panel-social a {
	line-height: 24px;
	font-size: 14px;
	font-weight: 400;
	color: #333333;
	text-align: left;
	display: inline-block;
	margin-right: 24px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	float: left;
}

.header-fixed-side .header--panel .header--panel-social a:last-of-type {
	margin-right: 0;
}

.header-fixed-side .header--panel .header--panel-social a.share-facebook:hover {
	color: #3B5998;
}

.header-fixed-side .header--panel .header--panel-social a.share-twitter:hover {
	color: #00ACEE;
}

.header-fixed-side .header--panel .header--panel-social a.share-instagram:hover {
	color: #9b6954;
}

.header-fixed-side .header--panel .header--panel-social a.share-linkedin:hover {
	color: #0E76A8;
}

.header-fixed-side .header--panel .header--panel-copyright {
	text-align: left;
	font-size: 13px;
	font-weight: 400;
	color: #9b9b9b;
}

.side-header .wrapper {
	margin: 0 0 0 270px !important;
	width: auto !important;
}

.side-header .wrapper .container {
	width: auto !important;
}

/* Header Fixed White */
.header-fixed-white .navbar-nav a,
.header-fixed-white .header--panel .header--panel-social a,
.header-fixed-white .header--panel .header--panel-copyright {
	color: #ffffff;
}

.header-fixed-white .header--panel .navbar-nav > li {
	border-bottom-color: rgba(255, 255, 255, 0.15) !important;
}

.header-fixed-white .navbar-nav a:hover,
.header-fixed-white .header--panel .header--panel-social a:hover {
	color: #222222 !important;
}

.header-fixed-white.bg-dark .navbar-nav a:hover,
.header-fixed-white.bg-dark .header--panel .header--panel-social a:hover {
	color: #e7b238 !important;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 991px) {

.header-fixed-side {
	width: 100%;
	position: relative;
}

.side-header .wrapper {
	margin: 0 !important;
}

.header-fixed-side .header--panel-logo {
	padding: 0 0 0 15px;
	margin-bottom: 0;
	float: left;
	width: auto;
}

.header--panel-footer {
	display: none;
}

.header-fixed-side .container > .navbar-collapse {
	margin-left: 0;
	background-color: #ffffff;
	border-top: 1px solid #f7f7f7;
}

.header-fixed-side .header--panel .header--panel-social a {
	color: #646464;
}

.header-fixed-side .navbar-nav a {
	color: #333333 !important;
}

.header-fixed-side .header--panel .navbar-nav a:hover {
	color: #e7b238 !important;
}
}

/*------------------------------------*\
    #Landing Page / Promo Page
\*------------------------------------*/
.landing-hero {
	height: 100vh;
	padding-top: 200px;
}

.landing-hero h6 {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 45px;
	font-weight: 400;
	letter-spacing: 0.54px;
	margin-bottom: 5px;
}

.landing-hero h1 {
	font-family: 'Montserrat', sans-serif;
	font-size: 85px;
	font-weight: 700;
	line-height: 90px;
	font-style: italic;
	letter-spacing: 1.35px;
	margin-bottom: 50px;
}

.landing-hero p {
	font-family: 'Montserrat', sans-serif;
	color: #f9f9f9;
	font-size: 17px;
	font-weight: 700;
	line-height: 27px;
	padding: 0 30px;
}

.landing-demos .portfolio-filter li a {
	font-weight: 700;
	margin-right: 20px;
}

.landing-demos .portfolio-item {
	margin-bottom: 50px;
}

.landing-demos .portfolio-title h4 {
	font-family: 'Montserrat', sans-serif;
	color: #222222;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 0;
}

.landing-demos .portfolio-title:hover h4 {
	color: #e7b238;
}

.landing-demos .portfolio-img {
	-moz-box-shadow: 0 0 36px 4px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 0 36px 4px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 36px 4px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-o-transition: all 0.35s;
	transition: all 0.35s;
	height: 400px;
	overflow: hidden;
	margin: 0 auto;
	text-align: center;
}

.landing-demos .portfolio-img img {
	max-width: 100%;
	height: auto;
}

.landing-demos .portfolio-bio {
	padding-top: 23px;
}

.landing-banner .panel--logos li {
	width: 100px;
	height: 100px;
	box-shadow: 0 0 59px 1px rgba(0, 0, 0, 0.12);
	background-color: #ffffff;
	padding: 18px;
	margin-right: 30px;
}

.landing-banner .panel--logos li:last-child {
	margin-right: 0;
}

.landing-banner .panel--logos li img {
	max-width: 100%;
	height: auto;
}

/* Landing Action*/
.landing-action {
	padding-top: 190px;
	padding-bottom: 190px;
}

.landing-action img {
	margin-bottom: 50px;
}

.landing-action h2 {
	font-size: 85px;
	font-weight: 700;
	line-height: 95px;
	letter-spacing: 1.35px;
	font-family: 'Montserrat', sans-serif;
}

.landing-action p {
	color: #f9f9f9;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.landing-hero {
	padding-top: 100px;
}

.landing-hero h6 {
	font-size: 25px;
}

.landing-hero h1,
 .landing-action h2 {
	font-size: 35px;
	line-height: 40px;
}

.landing-demos .portfolio-img {
	height: auto;
}

.landing-action {
	padding: 90px 0;
}
}

/* Small Devices, Tablets */
/*------------------------------------*\
    #Daily menu
\*------------------------------------*/
.menu .dishes-wrapper {
	box-shadow: 0px 1px 73px 0px rgba(40, 40, 40, 0.1);
	overflow: hidden;
}

.menu .dish-panel {
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
}

.menu .dish-panel .dish--price {
	font-size: 15px;
	font-weight: 600;
	line-height: 29px;
	color: #e7b238;
}

.menu .dish-panel .dish--title {
	font-size: 16px;
	font-weight: 600;
	line-height: 29px;
	color: #333333;
	margin-bottom: 0;
}

.menu .dish-panel .dish--desc {
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
}

.menu .dish-panel .dish--tag {
	position: absolute;
	top: 20px;
	left: 20px;
	padding: 3px 6px;
	background-color: #e7b238;
	color: #ffffff;
	font-weight: 700;
	font-size: 13px;
	border-radius: 3px;
	text-transform: uppercase;
}

/* Menu Simple */
.menu-simple .dish-panel {
	margin-bottom: 50px;
	position: relative;
}

.menu-simple .dish-panel .dish--img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 100px;
	overflow: hidden;
}

.menu-simple .dish-panel .dish--img img {
	max-width: 100px;
}

.menu-simple .dish-panel .dish--content {
	padding-left: 130px;
}

.menu-simple .dish-panel .dish--content-bio {
	border-bottom: 1px dashed #d8d8d8;
	padding-bottom: 10px;
	margin-bottom: 14px;
}

.menu-simple .dish-panel .dish--price {
	font-family: 'Montserrat', sans-serif;
	font-size: 15px;
	font-weight: 600;
	line-height: 29px;
	color: #e7b238;
	text-align: right;
}

.menu-simple .dish-panel .dish--title {
	font-size: 17px;
	font-weight: 600;
	line-height: 29px;
	color: #333333;
	margin-bottom: 0;
	float: left;
}

.menu-simple .dish-panel .dish--desc {
	font-size: 13px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.menu-simple .dish-panel {
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
}

.menu-simple .dish-panel .dish--img {
	position: relative;
	margin: 0 auto 20px auto;
}

.menu-simple .dish-panel .dish--content {
	padding-left: 0;
}

.menu-simple .dish-panel .dish--price {
	text-align: center;
}

.menu-simple .dish-panel .dish--title {
	float: none;
	text-align: center;
}

.menu-simple .dish-panel .dish--desc {
	text-align: center;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.menu-simple .dish-panel .dish--title {
	float: none;
	font-size: 13px;
}

.menu-simple .dish-panel .dish--price {
	text-align: left;
}
}

/* Menu Classic */
.menu-classic .dish-panel {
	padding-bottom: 16px;
	border-bottom: 1px dashed #d8d8d8;
}

.menu-classic .dish-panel:last-child {
	padding-bottom: 0;
	border-bottom: none;
	margin-bottom: 0;
}

.menu-classic .dish-panel .dish--price {
	text-align: right;
}

.menu-classic .dish-panel .dish--title {
	float: left;
}

.menu-classic .dish-container .dish--img {
	margin-bottom: 32px;
}

.menu-classic .dish-container .dish--img img {
	max-width: 100%;
	height: auto;
}

/* Menu Gallery */
.menu-gallery .dish-panel {
	margin-bottom: 45px;
}

.menu-gallery .dish-panel .dish--img {
	margin-bottom: 20px;
	position: relative;
}

.menu-gallery .dish-panel .dish--content {
	position: relative;
}

.menu-gallery .dish-panel .dish--title {
	float: left;
}

.menu-gallery .dish-panel .dish--price {
	text-align: right;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.menu-gallery .dish-panel {
	max-width: 370px;
	margin-right: auto;
	margin-left: auto;
}
}

/* Small Devices, Tablets */
/* Menu Grid */
.menu-grid {
	position: relative;
}

.menu-grid .dishes-bg {
	content: "";
	position: absolute;
	top: 110px;
	left: 0;
	height: 950px;
	width: 100%;
	z-index: 0;
}

.menu-grid .dish-panel {
	background-color: #ffffff;
	overflow: hidden;
	text-align: center;
	margin-bottom: 0;
}

.menu-grid .dish-panel .dish--content {
	padding: 30px 15px;
	margin: 10px;
	border: 1px dashed #cdaf80;
	height: 272px;
}

.menu-grid .dish--img {
	height: 292px;
	margin-bottom: 0;
	overflow: hidden;
	position: relative;
}

.menu-grid .dish--img img {
	width: 100%;
	height: 100%;
}

.menu-grid .dish-panel .dish--title {
	font-size: 18px;
}

.menu-grid .dish-panel .dish--price {
	margin-bottom: 10px;
	font-size: 18px;
	position: relative;
}

.menu-grid .divider--shape-4 {
	margin-top: 15px;
	margin-bottom: 15px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.menu-grid .pl-0 {
	padding-left: 15px !important;
}

.menu-grid .pr-0 {
	padding-right: 15px !important;
}

.menu-grid .p-0 {
	padding-left: 15px !important;
	padding-right: 15px;
}

.menu-grid .dishes-bg {
	display: none;
}

.menu-grid .dish-panel .dish--content {
	padding: 40px;
	height: auto;
}

.menu-grid .dish-panel .dish--title {
	font-size: 19px;
}

.menu-grid .dish-panel .dish--desc {
	font-size: 13px;
}

.menu-grid .dish--img {
	height: auto;
}

.menu-grid .dishes-wrapper {
	box-shadow: none;
}

.menu-grid .dish--img img,
 .menu-grid .dish--img,
 .menu-grid .dish-panel .dish--content {
	max-width: 290px;
	margin-right: auto;
	margin-left: auto;
}
}

@media only screen and (min-width: 600px) and (max-width: 767px) {

.menu-grid .dishes-bg {
	display: none;
}

.menu-grid .dish-panel .dish--content {
	padding: 20px;
}

.menu-grid .dish-panel {
	min-height: 600px;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.menu-grid .dishes-bg {
	display: none;
}

.menu-grid .dish-panel .dish--content {
	height: auto;
}

.menu-grid .dish--img img,
 .menu-grid .dish--img,
 .menu-grid .dish-panel .dish--content {
	max-width: 290px;
	margin-right: auto;
	margin-left: auto;
}

.menu-grid .dish-panel {
	min-height: 600px;
}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

.menu-grid .dish-panel .dish--content {
	padding: 5px;
}
}

/* Menu #Board */
.menu-board .dish-panel > [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.menu-board .dish-panel {
	overflow: hidden;
	text-align: center;
	height: 390px;
	margin-bottom: 0;
}

.menu-board .dish-panel .dish--content {
	padding: 60px 25px;
	margin: 10px;
	border: 1px dashed #cdaf80;
	height: 370px;
	position: relative;
}

.menu-board .dish-panel .dish--img {
	height: 390px;
	margin-bottom: 0;
	overflow: hidden;
	position: relative;
}

.menu-board .dish-panel .dish--title {
	font-size: 20px;
	font-weight: 600;
}

.menu-board .dish-panel .dish--price {
	position: relative;
}

.menu-board .dish-panel .dish--price {
	font-size: 18px;
	font-weight: 600;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.menu-board .dish-panel,
 .menu-board .dish-panel .dish--content {
	height: auto;
}

.menu-board .dish-panel .dish--img,
 .menu-board .dish-panel .dish--content {
	max-width: 390px;
	margin-right: auto;
	margin-left: auto;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.menu-board .dish-panel .dish--content {
	padding: 40px 5px;
}

.menu-board .dish-panel .dish--content .divider--shape-4 {
	margin-bottom: 10px;
	margin-top: 10px;
}
}

/* Menu #3 */
.menu-3 .dish-panel {
	margin-bottom: 40px;
}

.menu-3 .dish--content {
	padding-left: 200px;
}

.menu-3 .dish--img {
	margin-bottom: 0;
	position: absolute;
	top: 0;
	left: 0;
}

.menu-3 .dish-panel .dish--title {
	margin-bottom: 6px;
}

.menu-3 .dish-panel .dish--desc {
	margin-bottom: 5px;
}

.menu-3 .dish-panel .dish--price {
	position: relative;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.menu-3 .dish--img {
	margin-bottom: 20px;
	position: relative;
	top: 0;
	left: 0;
}

.menu-3 .dish--content {
	padding-left: 0;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.menu-3 .dish--content {
	padding-left: 180px;
}
}

/* Menu #4 */
.menu-4 .dish-panel {
	margin-bottom: 0;
}

.menu-4 .dish-panel .dish--img {
	margin-bottom: 20px;
}

.menu-4 .dish-panel .dish--content {
	position: relative;
	margin-bottom: 32px;
}

/* Menu #8*/
.menu-8 .dish-panel {
	margin-bottom: 15px;
}

.menu-8 .dish-panel .dish--content {
	padding: 0;
}

.menu-8 .dish-panel .dish--price {
	position: relative;
}

.menu-8 .dish-panel .dish--title {
	font-family: 'Montserrat', sans-serif;
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 10px;
}

.menu-8 .dish-panel .dish--img {
	margin-bottom: 18px;
}

/* Menu #9*/
.menu-9 .row {
	margin-right: 0;
	margin-left: 0;
}

.menu-9 [class*="col-"] {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.menu-9 .dish-panel {
	margin-bottom: 0;
	position: relative;
}

.menu-9 .dish-panel .dish--content {
	position: absolute;
	bottom: 28px;
	left: 0;
	width: 100%;
	text-align: center;
}

.menu-9 .dish-panel .dish--title {
	color: #ffffff;
	font-size: 45px;
	font-weight: 400;
	font-family: 'Montserrat', sans-serif;
	position: relative;
	margin-bottom: 15px;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.menu-9 .dish-panel:hover .dish--title {
	margin-bottom: 30px;
}

.menu-9 .dish-panel .dish--price {
	font-size: 16px;
	position: relative;
}

.menu-9 .btn {
	width: 270px;
}

/*------------------------------------*\
    #Dishes
\*------------------------------------*/
.specials .dishes-wrapper {
	background-color: #ffffff;
	border-radius: 2px;
	-webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
	-moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
	-ms-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
	padding: 10px;
}

.specials .dishes-wrapper .dishes-bordered {
	border: 1px dashed #cdaf80;
	padding: 70px 70px 20px 70px;
}

/* Specials #2*/
.specials-2 .dish-panel .dish--img {
	border-radius: 50%;
}

/* Specials #3*/
.specials .dish-panel-right {
	text-align: right;
}

.specials .dish-panel-right .dish--img {
	right: 0;
	left: auto;
}

.specials .dish-panel-right .dish--content {
	padding-right: 130px;
	padding-left: 0;
}

.specials .dish-panel-right .dish--title {
	float: right;
}

.specials .dish-panel-right .dish--price {
	text-align: left;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.specials .dish-panel-right .dish--content {
	padding-right: 0;
}

.specials .dish-panel-right .dish--title {
	float: none;
}

.specials .dish-panel-right .dish--price {
	text-align: center;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.specials .dish-panel-right .dish--title {
	float: none;
}

.specials .dish-panel-right .dish--price {
	text-align: right;
}
}

/* Specials #5*/
.specials-5 .dish-panel .dish--content {
	padding-right: 0;
	padding-left: 0;
}

.specials-5 .specials--img {
	position: relative;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 1200px) {

.specials-5 img {
	margin: 0 auto 40px auto;
	display: block;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 992px) and (max-width: 1200px) {

.specials-5 img {
	max-width: 100%;
}
}

/* Dish Overlay */
.dish-panel .dish--img .dish--overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 5px 0;
	cursor: pointer;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.dish-panel .dish--img .dish--overlay:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	transform: scale(0);
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
}

.dish-panel .dish--img .dish--overlay:hover:before {
	transform: scale(1);
}

.dish-panel .dish--img .dish--overlay .dish-popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	transition: all .3s linear;
	transform: scale(0);
}

.dish-panel:hover .dish--img .dish--overlay .dish-popup {
	transform: scale(1);
}

.dish-popup i {
	color: #ffffff;
	text-align: center;
	font-size: 22px;
	position: absolute;
	top: 50%;
	transform: translate(0px, -50%);
	left: 0;
	right: 0;
}

#dishPopup .modal-body {
	padding: 0;
}

#dishPopup .modal-body .img-popup {
	position: relative;
}

#dishPopup .modal-body .img-popup img {
	max-height: 400px;
}

#dishPopup .modal-body .img-popup .img-popup-overlay {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 50px;
	text-align: center;
}

#dishPopup .modal-body .img-popup .img-popup-overlay .popup--price {
	font-family: 'Montserrat', sans-serif;
	font-size: 25px;
	font-weight: 400;
	line-height: 1;
	color: #e7b238;
	margin-bottom: 18px;
}

#dishPopup .modal-body .img-popup .img-popup-overlay h3 {
	font-family: 'Great Vibes', cursive;
	font-size: 34px;
	font-weight: 400;
	line-height: 1;
	color: #ffffff;
	margin-bottom: 0;
}

#dishPopup .modal-body .content-popup {
	padding: 50px 60px;
}

#dishPopup .modal-body .content-popup p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
	text-align: center;
}

/* PopUP Modal */
.modal-body {
	padding: 0;
}

.modal-body .popup--img {
	position: relative;
}

.modal-body .popup--img:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	opacity: 0.97;
	background-color: rgba(0, 0, 0, 0);
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000));
	background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), #000);
	background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0), #000);
	background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0), #000);
	background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0), #000);
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
 filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='rgba(0, 0, 0, 0)', endColorStr='#000');
}

.modal-body .popup--img img {
	max-height: 400px;
	width: 100%;
}

.modal-body .popup--img .img-popup-overlay {
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 50px;
	text-align: center;
	z-index: 2;
}

.modal-body .popup--img .img-popup-overlay .popup--price {
	font-family: 'Montserrat', sans-serif;
	font-size: 25px;
	font-weight: 400;
	line-height: 1;
	color: #e7b238;
	margin-bottom: 18px;
}

.modal-body .popup--img .img-popup-overlay h3 {
	font-size: 34px;
	font-weight: 400;
	line-height: 1;
	color: #ffffff;
	margin-bottom: 0;
}

.modal-body .content-popup {
	padding: 50px 60px;
}

.modal-body .content-popup p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
	text-align: center;
	color: #222222;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.specials .dishes-wrapper .dishes-bordered {
	padding: 10px;
}

.menu-classic .dish-panel {
	max-width: 450px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;
}

.menu-classic .dish-panel:last-child {
	padding-bottom: 16px;
	border-bottom: 1px dashed #d8d8d8;
	margin-bottom: 30px;
}

.menu-classic .dish-container {
	margin-bottom: 30px;
}

.menu-classic .dish-container:last-of-type {
	margin-bottom: 0;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.menu-classic .dish-panel:last-child {
	margin-bottom: 30px;
}

.menu-classic .dish-panel {
	max-width: 550px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 30px;
}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

.specials .dishes-wrapper .dishes-bordered {
	padding: 70px 40px 20px 40px;
}
}

/*------------------------------------*\
    #Banners
\*------------------------------------*/
.banner .banner-panel .banner--img {
	margin-bottom: 28px;
	position: relative;
}

.banner .banner-panel .banner--content {
	padding: 0 10px;
}

.banner .banner-panel .banner--content h3 {
	color: #e7b238;
	font-size: 30px;
	font-family: 'Great Vibes', cursive;
	font-weight: 400;
	margin-bottom: 15px;
}

.banner .banner-panel .banner--content h4 {
	font-family: 'Montserrat', sans-serif;
	font-size: 25px;
	font-weight: 700;
	margin-bottom: 20px;
}

.banner .banner-panel .banner--content p {
	font-size: 13px;
}

.banner .btn--link {
	font-size: 20px;
	font-weight: 400;
	color: #e7b238;
	font-family: 'Montserrat', sans-serif;
	text-transform: none;
	margin-right: 30px;
}

/* Banner #2 */
.banner-2 .banner-img,
.banner-1 .banner--img {
	position: relative;
}

.banner-2 .banner-img img,
.banner-1 .banner--img img {
	max-width: 100%;
	height: auto;
	position: relative;
}

.banner-2 .banner-img:after,
.banner-1 .banner--img:after {
	position: absolute;
	content: "";
	top: 10px;
	left: 10px;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	border: 1px dashed #e7b238;
	opacity: 0.45;
	z-index: 1;
}

/* Banner #5 */
.banner-5 .banner--desc {
	color: #333333;
	font-family: 'Great Vibes', cursive;
	font-size: 40px;
	font-weight: 400;
	line-height: 42px;
	padding: 0 15px;
	margin-bottom: 55px;
}

.history-panel h6 {
	color: #e7b238;
	font-family: 'Great Vibes', cursive;
	font-size: 35px;
	font-weight: 400;
	line-height: 29px;
	margin-bottom: 15px;
}

.history-panel h3 {
	font-size: 28px;
	font-weight: 400;
	line-height: 29px;
	margin-bottom: 0;
}

.history-panel .divider--shape-13 {
	margin-top: 15px;
	margin-bottom: 15px;
}

.history-panel p {
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
}

/* Banner #6 */
.banner-6 .panel--logos li {
	padding-right: 15px;
	padding-left: 15px;
}

.banner-6 .panel--heading {
	font-size: 47px;
	font-family: 'Great Vibes', cursive;
	color: #333333;
	font-weight: 400;
	line-height: 40px;
	margin-bottom: 39px;
}

.banner-6 .panel--details {
	padding: 0;
	margin-bottom: 0;
}

.banner-6 .panel--details li {
	font-size: 13px;
	line-height: 30px;
	list-style: none;
	position: relative;
	padding-left: 20px;
	color: #9b9b9b;
}

.banner-6 .panel--details li::before {
	content: "•";
	color: #222222;
	font-size: 27px;
	position: absolute;
	left: 0;
}

.banner-6 .panel--details.text-right li {
	padding-right: 20px;
	padding-left: 0;
}

.banner-6 .panel--details.text-right li::before {
	right: 0;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {

.banner .banner-img img {
	max-width: 100%;
}

.banner .banner-panel .banner--content h4 {
	font-size: 22px;
	line-height: 30px;
}

.banner-6 .panel--heading {
	font-size: 36px;
}

.banner-6 .panel--logos li {
	margin-bottom: 20px;
}

.banner-1 .banner-panel .banner--img {
	max-width: 370px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}

.banner-2 .banner-img {
	margin-top: 30px;
}

.banner-2 .banner-img {
	max-width: 270px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}

.banner .banner-panel .banner--content {
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {

.banner-6 .panel--details li {
	font-size: 12px;
}

.banner-2 .banner-img {
	max-width: 270px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 30px;
}

.banner .banner-panel .banner--content {
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
}

.banner .banner-img img {
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {

.banner-6 .panel--details li {
	font-size: 11px;
}
}

/*------------------------------------*\
    #BOXED LAYOUT
\*------------------------------------*/
.body-boxed {
	background: url(../images/background/patterns/bg-body.jpg);
	background-attachment: fixed;
}

@media only screen and (min-width: 1200px) {

.body-boxed .wrapper {
	width: 1170px;
	margin: auto;
}
}

@media only screen and (max-width: 1200px) {

.body-boxed .wrapper {
	width: auto;
	margin: auto;
	overflow-x: hidden;
}
}

.body-boxed .header-fixed .affix {
	background-color: transparent;
}

.body-boxed .header-fixed .affix .container {
	background-color: #ffffff;
}

.body-boxed .col-content {
	padding: 90px;
}

.body-boxed .header .navbar.navbar-fixed-top .container {
	padding-right: 50px;
	padding-left: 50px;
}

.body-boxed .divider--slider-right {
	right: 0;
	margin-top: 0;
}

.body-boxed .divider--slider-left {
	left: 0;
	margin-top: 0;
}

.body-boxed .rev_slider_wrapper,
.body-boxed .slider .rev_slider {
	height: 600px !important;
}

/* To active Boxed Layout */
/*------------------------------------*\
    #Layout Bordered
\*------------------------------------*/
.body-bordered .bordered--line {
	position: fixed;
	display: block;
	background: #ffffff;
	z-index: 9999;
}

.body-bordered .bordered--line-top,
.body-bordered .bordered--line-bottom {
	height: 20px;
	left: 0;
	right: 0;
}

.body-bordered .bordered--line-right,
.body-bordered .bordered--line-left {
	width: 20px;
	top: 0;
	bottom: 0;
}

.body-bordered .bordered--line-top {
	top: 0;
}

.body-bordered .bordered--line-bottom {
	bottom: 0;
}

.body-bordered .bordered--line-right {
	right: 0;
}

.body-bordered .bordered--line-left {
	left: 0;
}

.body-bordered .navbar-fixed-top {
	top: 20px;
}

.body-bordered .navbar-fixed-top.affix {
	top: 0;
}

/*------------------------------------*\
    #Layout Wide
\*------------------------------------*/
.body-wide .wrapper {
	padding: 0;
}

.body-wide .header .navbar {
	margin: 0;
}

.body-wide .header .navbar.navbar-fixed-top {
	top: 0;
}

.body-wide .divider--slider-right {
	right: 2px;
}

.body-wide .slider .tparrows.tp-rightarrow:before {
	background-image: url(../images/sliders/arrows/right-arrow-wide.png);
	width: 22px;
	right: 5px;
}

.body-wide .divider--slider-left {
	left: 2px;
}

.body-wide .slider .tparrows.tp-leftarrow:before {
	background-image: url(../images/sliders/arrows/left-arrow-wide.png);
	width: 22px;
	left: 8px;
}

/* To active bordered Version */
/*------------------------------------*\
    #Layout Split
\*------------------------------------*/
.nav-split {
	position: fixed;
	top: 50%;
	right: 30px;
	padding: 10px;
	z-index: 99;
	margin-bottom: 0;
	list-style: none;
	background-color: #e7b238;
}

.nav-split li a {
	width: 9px;
	height: 9px;
	border: 2px solid #ffffff;
	background-color: transparent;
	font-size: 0;
	border-radius: 50%;
	display: block;
	margin-bottom: 13px;
}

.nav-split li a:hover,
.nav-split li.active a {
	background-color: #ffffff;
	border-color: #ffffff;
}

.nav-split li:last-child a {
	margin-bottom: 0;
}

/* Custom Cheffeurs */
a.download-button {
	margin: 0 10px;
}
@media only screen and (max-width: 767px) {
	a.download-button {
		display: block;
		margin-bottom: 10px;
	}
}

span.tagline {
	margin: 0 0 0 20px;
	position: relative;
	top: 5px;
	color: #e7b238;
}
@media only screen and (max-width: 992px) {
	span.tagline {
		display: none;
	}
}

.social-newsletter {
	position: relative;
	margin: 0 auto;
	width: 400px;
}
@media only screen and (max-width: 767px) {
	.social-newsletter {
		width: 300px;
	}
}
.social-newsletter h4 {
	font-weight: 700;
	color: #bcc6d8;
	text-align: center;
	text-transform: uppercase;
	font-size: 18px;
	margin: 0 0 10px 0;
}
.social-newsletter .form-control {
	box-shadow: none;
	border: none;
	background-color: #1c1c1c;
	color: #474747;
	font-family: "Montserrat";
	font-weight: 700;
	width: 400px;
	height: 42px;
	line-height: 42px;
	margin: 0;
}
@media only screen and (max-width: 767px) {
	.social-newsletter .form-control {
		width: 300px;
	}
}
.social-newsletter .form-control::-webkit-input-placeholder {
 color: #888;
}
.social-newsletter .form-control::-moz-placeholder {
 color: #888;
}
.social-newsletter .form-control::-ms-input-placeholder {
 color: #888;
}

.social-newsletter button {
	background-color: transparent;
	border: none;
	line-height: 42px;
	color: #e7b238;
	position: absolute;
	right: 10px;
	top: 26px;
	margin-bottom: 0;
	font-size: 13px;
	text-transform: uppercase;
}

.push {margin-bottom: 20px !important;}
.push-t {margin-top: 20px !important;}
.push-40 {margin-bottom: 40px !important;}
.push-t-40 {margin-top: 40px !important;}

.main-content {
	background: #222222;
  padding: 95px 0;
  position: relative;
}

.intro-boxes {
	text-align: center;
}

.intro-boxes .info-image {
	height: 250px;
}
.intro-boxes .info-image .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.intro-boxes .info-image img {
	width: 150px;
	height: auto;
	vertical-align: middle;
}

.intro-boxes .info-description {
	margin: 20px 0 0 0;
	font-size: 16px;
}

.intro-boxes .info-title {
	margin: 20px 0 0 0;
	font-size: 32px;
	font-family: "Great Vibes", cursive;
	color: #e7b238;
}

h1.caps, h2.caps, h3.caps, h4.caps, h5.caps {
	text-transform: uppercase;
}

.slide-overlay {
	background: rgba(29, 29, 29, 0.7);
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	padding: 100px 20px 0 20px;
	top: 0px;
}

.slide-overlay.slim {
	padding: 120px 20px 0 20px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.slide-overlay {
		padding: 140px 20px 0 20px;
	}
}

img.header-image {
	width: 100%;
	height: auto;
	position: relative;
}
@media only screen and (max-width: 767px) {
	img.header-image {
		width: auto;
		height: 100%;
		left: -25%;
		right: -25%;
		position: absolute;
	}
}

.hidden-until-load {
	display: none;
}

.module.module-reservation a.active {
	color: #e7b238 !important;
	background: transparent;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

hr {
	opacity: 0.7;
	border-top: 1px solid rgba(229, 228, 228, 0.7);
	margin-top: 0;
	margin-bottom: 30px;
}

/* React notifications component */
.notification-container-top-center {
  position: fixed;
  z-index: 10001;
  transform: translateX(-50%);
  top: 20px;
  left: 50%;
}

.notification-container-bottom-center {
  position: fixed;
  z-index: 10001;
  transform: translateX(-50%);
  margin-bottom: -20px;
  bottom: 20px;
  left: 50%;
}

.notification-container-top-left {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 10001;
}

.notification-container-top-right {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10001;
}

.notification-container-bottom-left {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 10001;
  margin-bottom: -20px;
}

.notification-container-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10001;
  margin-bottom: -20px;
}

.notification-container-mobile-top {
  z-index: 8000;
  position: fixed;
  right: 20px;
  left: 20px;
  top: 20px;
}

.notification-container-mobile-bottom {
  z-index: 8000;
  position: fixed;
  right: 20px;
  left: 20px;
  bottom: 5px;
}
.notification-default {
  background-color: #e7b238 !important;
  border-left: 8px solid darken(#e7b238, 15%) !important;
}

.notification-success {
  background-color: #5b6a26 !important;
  border-left: 8px solid darken(#5b6a26, 15%) !important;
}

.notification-danger {
  background-color: #ef7653 !important;
  border-left: 8px solid darken(#ef7653, 15%) !important;
}

.notification-info {
  background-color: #1e4b50 !important;
  border-left: 8px solid darken(#1e4b50, 15%) !important;
}

.notification-warning {
  background-color: #eab000;
  border-left: 8px solid darken(#eab000, 15%);
}

.notification-title,
.notification-message {
  color: #fff;
}

.notification-close span {
  color: #fff;
}
.notification-item-root,
.notification-title,
.notification-message,
.notification-item {
  font-family: Arial, Helvetica, sans-serif;
}

.notification-item {
  position: relative;
}

.notification-close {
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 0;
}

.nc-center {
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 8000;
  pointer-events: all;
}

.nc-box {
  left: -50%;
  position: relative;
  transform: translateY(-50%);
}

.notification-container-mobile-top .notification-item,
.notification-container-mobile-bottom .notification-item,
.notification-container-mobile-top .notification-item-root,
.notification-container-mobile-bottom .notification-item-root {
  max-width: 100%;
  width: 100%;
}

.notification-item {
  display: flex;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
}

.notification-container-top-right .notification-item-root,
.notification-container-bottom-right .notification-item-root {
  margin-left: auto;
}

.notification-container-top-left .notification-item-root,
.notification-container-bottom-left .notification-item-root {
  margin-right: auto;
}

.notification-container-mobile-top .notification-item-root,
.notification-container-mobile-bottom .notification-item-root {
  margin-left: auto;
  margin-right: auto;
}

.notification-item-root {
  width: 275px;
  margin-bottom: 15px;
}

.notification-title {
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notification-message {
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}

.notification-invisible {
  visibility: hidden;
  max-width: 375px;
}

.notification-visible {
  visibility: visible;
}

.notification-content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}


.form-row {
	margin: 0 0 20px 0;
	overflow: hidden;
}
label {
	text-transform: uppercase;
}
.form-row .radio {
	padding: 0px 10px;
}

.contact-form {
	margin-bottom: 20px;
}

.block-content {
	margin: 20px 0;
}

.modal-overlay {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,0.8);
	position: fixed;
	z-index: 10000;
}
.modal-overlay--after-open {
	transition: opacity 200ms ease-in-out !important;
}
.modal-overlay--before-close {
	transition: opacity 200ms ease-in-out !important;
}

.modal-content {
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
}

.ReactModal__Content {
  opacity: 0;
}

.ReactModal__Content--after-open {
  opacity: 1;
  transition: opacity 200ms ease-in-out !important;
}

.ReactModal__Content--before-close {
  opacity: 0;
	transition: opacity 200ms ease-in-out !important;
}


.video-wrapper {
	width: 75%;
	margin: -220px auto 40px auto;
	position: relative;
	z-index: 2;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	.video-wrapper {
		width: 100%;
		margin: 40px auto 20px auto;
	}
}


.plyr__control--overlaid {
	background-color: #5b6a27;
}
.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background-color: #5b6a27;
}
.plyr--full-ui input[type=range] {
	color: #5b6a27;
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
	background-color: #5b6a27;
}

/*.ReactModalPortal > div {
    opacity: 0 !important;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out !important;
  background: rgba(0, 0, 0, 0.15) !important;
  &--after-open {
    opacity: 1 !important;
  }
  &--before-close {
      opacity: 0 !important;
  }
}*/
